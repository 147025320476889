import { factory } from "@smartdevis/utils/src/map"
import { Domain, Utils } from "../domain"
import { IdLite } from "@smartdevis/utils/src/id"
import { toSafeNumber } from "@smartdevis/utils/src/numbers"

export const keyFixture = (n = 0) => `${`${n}`.padStart(2, "0")}a1a947-eb6a-4b1c-aede-10edf9642890`

export const deepFactory =
    <T>(def: T) =>
    (v: Partial<T> = {}): T =>
        JSON.parse(JSON.stringify({ ...def, ...v }))

// FIXTURE CONVENTION:
// type Test = { a: string, b: number, c: Bar }
// type Bar = { d: string }
// export const barFixture = factory<Bar>({d: "d"})
// export const testFixture = factory<Test>({ a: "a", b: 1, c: barFixture()})
export const idFixture = (n = 0) => `${5881239649 + n}PQDnFRThFuwafeoVFzYhR0`

export const idLiteFixture = (ms: number) => `${ms}`.padStart(16, "0") as IdLite

export const contractorFixture = factory<Domain.Contractor>({
    email: "fool@email.com",
    contractorId: idFixture(),
    name: "name",
    createdTs: 1,
    surname: "surname",
    phoneNumber: "123 123 123",
    companyName: "companyName",
    street: "street",
    city: "city",
    postalCode: "postalCode",
    workCategory: "wc",
    number: "n"
})

export const contractorUser = factory<Domain.User>({
    userId: idFixture(1),
    email: "foo@bar.com",
    phoneNumber: "1234567",
    name: "name",
    surname: "surname",
    companyName: "company name",
    taxNumber: "CHE-123.123.123",
    street: "street",
    postalCode: "postal code",
    city: "city",
    agreedOn: "1",
    type: "contractor"
})

export const deductionFixture = factory<Domain.Deduction>({
    devisId: "devisId",
    sectionId: "sectionId",
    deductionId: "deductionId",
    name: "name",
    sign: "positive",
    valueType: "number",
    value: toSafeNumber(1),
    createdTs: 1,
    order: 0
})

export const sectionFixture = deepFactory<Domain.Section>({
    name: "name",
    sectionId: "sectionId",
    number: "100",
    updatedTs: 1,
    refId: "refId",
    createdTs: 0,
    order: 0,
    type: "deduction"
})

export const positionFixture = deepFactory<Required<Domain.Position>>({
    positionId: idFixture(1),
    sectionId: idFixture(2),
    devisId: idFixture(3),
    name: "name",
    number: "100",
    order: 0,
    type: "basic",
    description: "description",
    amount: toSafeNumber(1),
    unit: "m",
    createdTs: 1,
    catalogRef: null,
    updatedTs: 1
})

export const partnerFixture = factory<Domain.Partner>({
    companyName: "companyName",
    companyName2: "companyName 2",
    categories: ["category"],
    city: "city",
    createdTs: 1,
    email: "email@email.com",
    groups: ["group"],
    order: 0,
    logoUrl: "logoUrl",
    name: "name",
    partnerId: idFixture(1),
    phoneNumber: "phoineNumber",
    postalCode: "postalCode",
    products: {},
    street: "street",
    surname: "surname",
    updatedTs: 1
})

export const userFixture = factory<Domain.User>({
    name: "name",
    email: "user@smartdevis.ch",
    phoneNumber: "phoneNumber",
    userId: "userId",
    city: "city",
    companyName: "companyName",
    street: "street",
    postalCode: "postalCode",
    surname: "surname",
    taxNumber: "CHE-123.123.123",
    agreedOn: "agreedOn",
    type: "architect"
})

export const addressInfoEmptyFixture = factory<Domain.AddressInfo>({
    street: "",
    companyName: "",
    name: "",
    surname: "",
    postalCode: "",
    city: "",
    phoneNumber: "",
    email: "",
    createdTs: 1
})

export const addressInfoFixture = factory<Domain.AddressInfo>({
    street: "street",
    companyName: "company name",
    name: "name",
    surname: "surname",
    postalCode: "postal code",
    city: "city",
    phoneNumber: "123 123 123",
    email: "em@ail.com",
    createdTs: 1
})

export const projectDetailsEmptyFixture = factory<Domain.ProjectDetails>({
    projectId: "1",
    name: "",
    city: "",
    street: "",
    postalCode: "",
    createdTs: 1,
    projectType: "new",
    abbreviation: "",
    number: "",
    client: addressInfoEmptyFixture(),
    billing: addressInfoEmptyFixture(),
    planner: addressInfoEmptyFixture(),
    version: 1
})

export const projectDetailsFixture = factory<Domain.ProjectDetails>({
    projectId: "1",
    name: "Test Name",
    city: "NYC",
    street: "Dev St. 123",
    postalCode: "123 123",
    createdTs: 123456,
    projectType: "new",
    abbreviation: "1",
    number: "1",
    client: addressInfoFixture(),
    billing: addressInfoFixture(),
    planner: addressInfoFixture(),
    version: 1
})
export const devisFixture = factory<Domain.Devis>({
    createdTs: 1,
    devisId: "devisId",
    projectId: "projectId",
    number: "number",
    workCategory: "wc",
    entryTs: 1,
    workStartTs: 1,
    workEndTs: 1,
    costEstimateOriginal: 1,
    costEstimateRevised: 2,
    includeVat: true,
    remarks: "asdf",
    contractorIds: [idFixture(1)]
})

export const architectOfferRequestFixture = factory<Domain.ArchitectOfferRequest>({
    createdTs: 1,
    devisId: "devisId",
    offerId: "offerId",
    projectId: "projectId",
    state: { type: "requested" },
    statesHistory: [{ state: { type: "requested" }, createdTs: 0 }],
    submitterId: "submitterId",
    roundOfferAttachments: {},
    offerAttachments: {}
})

export const conditionFixture = factory<Domain.Condition>({
    conditionId: "1",
    createdTs: 123456,
    name: "Test name",
    description: "Test description",
    devisId: "d1",
    order: 0
})

export const roundFixture = factory<Domain.Round>({
    roundId: "r1",
    createdTs: 123456,
    devisId: "d1",
    offersIds: ["o1", "o2"]
})

export const deltaFixture = factory<Domain.RoundDelta>({
    roundId: "r1",
    deltaId: "del1",
    refId: "d1",
    type: "add",
    parent: "sections",
    value: sectionFixture({ sectionId: "s1", name: "foo" })
})

export const attachmentDataFixture = factory<Domain.AttachmentData>({
    attachmentId: "a1",
    contentType: "",
    createdTs: 0,
    path: "" as Utils.FilePath,
    refId: "d1",
    name: "Attachment",
    size: 1
})

export const attachmentFixture = factory<Domain.Attachment>({
    attachmentId: "a1",
    contentType: "",
    createdTs: 0,
    path: "" as Utils.FilePath,
    refId: "d1",
    type: "devis",
    name: "Attachment",
    size: 1
})
