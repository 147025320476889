import * as React from "react"
import styled from "styled-components"
import { validateUserWithBusiness } from "@smartdevis/server/src/models/user"
import { DropdownMenuOption, Dropdown, mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { Badge } from "@smartdevis/ui/src/Badge"
import { H4, H2, H6, NavP, P } from "@smartdevis/ui/src/Typography"
import { Link } from ".."
import { contractorPaths, appPaths, adminPaths, Path, PathParams, devisPaths, projectPaths } from "../../paths"
import { asyncConnect, AsyncConnectResults } from "../../resolvers"
import { i18n } from "../../services/translations"
import { Domain } from "@smartdevis/server/src/domain"
import { themeColor, themeConfig } from "@smartdevis/ui/src/utils/theme"
import { rem } from "@smartdevis/ui/src/utils"
import { FlexRow, Margin, HorizontalSpace, FlexColumn } from "@smartdevis/ui/src/utils/common"
import { StatusTag } from "../StatusTag"
import { Asset } from "@smartdevis/ui/src/Asset"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { useUserEdit } from "../../views/EditUserProvider"
import { mkDevis } from "../../utils/devisHelpers"
import { Popover } from "@smartdevis/ui/src/Popover"
import { isFetched } from "@smartdevis/utils/src/async"
import { identity } from "@smartdevis/utils/src/misc"
import { isOk } from "@smartdevis/utils/src/result"
import { F0 } from "@smartdevis/utils/src/types"
import { isProd } from "@smartdevis/client/src/utils/envHelpers"
import { useAuth } from "../../views/AuthProvider"

type PageHeaderProps = {
    title?: string
    noBottomMargin?: boolean
    noTopMargin?: boolean
    subTitle?: string
    actionButtons?: (React.ReactElement | null)[]
}
export const PageHeader: React.FC<PageHeaderProps> = p => (
    <Margin values={getPageHeaderMargin(p)}>
        <FlexRow alignCenter spaceBetween>
            {p.title && <H2>{p.title}</H2>}
            <HorizontalSpace base="16px" />
            <FlexRow alignCenter justifyEnd>
                <Join items={p.actionButtons || []} renderJoining={() => <HorizontalSpace base="16px" />}>
                    {identity}
                </Join>
            </FlexRow>
        </FlexRow>
        {p.subTitle ? (
            <Margin values="24px 0 32px">
                <P small color="grey70">
                    {p.subTitle}
                </P>
            </Margin>
        ) : null}
    </Margin>
)

const getPageHeaderMargin = (p: PageHeaderProps) => {
    const top = p.noTopMargin ? "0" : "24px"
    const bottom = p.noBottomMargin || p.subTitle ? "24px" : "48px"
    return `${top} 0 ${bottom}`
}

const DevisPicker = asyncConnect({
    stateResolvers: ["projectDetails", "allDevis","latestConditionsShelf", "latestPositionsShelf"],
    actions: ["navigate"]
})<{ devisId: string }>(p => (
    <Dropdown
        onOptionClick={id =>
            p.navigate(devisPaths.conditions, {
                projectId: p.projectDetails.projectId,
                devisId: id
            })
        }
        options={mkDevis(p).map(d => mkDropdownOption(d.workCategory, d.devisId))}>
        <DevisPickerBtn>
            {p.allDevis[p.devisId].workCategory}
            <HorizontalSpace base="2px" />
            <Asset name="ArrowDownSmall" size="icon" color="blueGrey" />
        </DevisPickerBtn>
    </Dropdown>
))

const DevisPickerBtn = styled.div`
    display: flex;
    align-items: center;
    border: none;
    height: ${rem(24)};
    border-radius: ${rem(100)};
    padding: 2px 6px 2px 12px;
    border: 1px solid ${themeColor("grey40")};
    background-color: ${themeColor("white")};
    &:hover {
        border: 1px solid ${themeColor("blueGrey")};
    }
`

export const AppHeader = asyncConnect({
    stateResolvers: ["auth", "asyncUser"],
    actions: ["navigate"]
})<{
    path: string
    title?: string
    preTitle?: string
    subTitle?: string
    backTo: "projects" | "admin" | "logo" | "project" | "offers" | "none"
    devisId?: string
    projectId?: string
}>(p => {
    const { openEditUserModal } = useUserEdit()
    const isContractor =
        p.auth.type === "Authenticated" && (p.auth.value.type === "CONTRACTOR" || p.auth.value.type === "contractor")
    const { projectId } = p
    const renderLogo = () => {
        switch (p.backTo) {
            case "admin":
                return <LinkButton text={i18n("Admin Dashboard")} to={adminPaths.adminDashboard} />
            case "projects":
                return <LinkButton text={i18n("All projects")} to={appPaths.home} />
            case "project":
                return (
                    projectId && (
                        <LinkButton text={i18n("Project details")} to={projectPaths.overview} params={{ projectId }} />
                    )
                )
            case "offers":
                return <LinkButton text={i18n("All offers")} to={contractorPaths.contractorOffers} />
            case "none":
                return (
                    <FlexRow>
                        {isProd() ? (
                            <Logo />
                        ) : (
                            <Badge value="dev" space={10}>
                                <Logo />
                            </Badge>
                        )}
                    </FlexRow>
                )
            case "logo":
                return (
                    <FlexRow>
                        <Link
                            to={isContractor ? contractorPaths.contractorOffers : appPaths.home}
                            style={{ position: "relative", display: "flex", alignItems: "center" }}
                            data-cy="Logo">
                            {isProd() ? (
                                <Logo />
                            ) : (
                                <Badge value="dev" space={10}>
                                    <Logo />
                                </Badge>
                            )}
                        </Link>
                        {!isContractor && <DashboardButtons path={p.path} />}
                    </FlexRow>
                )
        }
    }

    const renderTitle = () => {
        if (p.preTitle)
            return p.subTitle ? (
                <FlexColumn alignCenter>
                    <FlexRow alignCenter justifyCenter>
                        <StatusTag color="black">{p.preTitle}</StatusTag>
                        <HorizontalSpace base="8px" />
                        <SubTitle>{p.title || ""}</SubTitle>
                    </FlexRow>
                    <H4>{p.subTitle}</H4>
                </FlexColumn>
            ) : (
                <FlexRow alignCenter justifyCenter>
                    <StatusTag color="black">{p.preTitle}</StatusTag>
                    <HorizontalSpace base="8px" />
                    <H4>{p.title || ""}</H4>
                </FlexRow>
            )
        return p.subTitle ? (
            <FlexColumn alignCenter>
                <FlexRow alignCenter>
                    <NavP>{p.title || ""}</NavP>
                    <HorizontalSpace base="24px" />
                    {p.devisId && <DevisPicker devisId={p.devisId} />}
                </FlexRow>
            </FlexColumn>
        ) : (
            <NavP>{p.title || ""}</NavP>
        )
    }
    return (
        <HeaderContainer whiteBackground={!p.projectId}>
            {renderLogo()}
            {renderTitle()}
            <FlexRow alignCenter>
                <HeaderMenu {...p} onEditAccount={openEditUserModal} />
                <Spacer />
                <LanguageSwitcher />
            </FlexRow>
        </HeaderContainer>
    )
})

export const LanguageSwitcher = asyncConnect({ stateResolvers: ["language"], actions: ["setLanguage"] })(p => (
    <Dropdown onOptionClick={p.setLanguage} options={[mkDropdownOption("EN", "en"), mkDropdownOption("DE", "de")]}>
        <FlexRow alignCenter>
            <P small>{p.language.toUpperCase()}</P>
            <HorizontalSpace base="4px" />
            <Asset name="ArrowDown" size="small-icon" color="blueGrey" />
        </FlexRow>
    </Dropdown>
))

const HeaderMenu: React.FC<AsyncConnectResults<"asyncUser" | "auth", "navigate"> & { onEditAccount: F0 }> = p => {
    const { logout } = useAuth()
    if (p.auth.type !== "Authenticated") return null
    const user = isFetched(p.asyncUser) ? p.asyncUser.value : null
    const userBusinessValidated = isFetched(p.asyncUser) && isOk(validateUserWithBusiness(p.asyncUser.value))
    const isAdmin = p.auth.type === "Authenticated" && (p.auth.value.type === "ADMIN" || p.auth.value.type === "admin")

    const options: DropdownMenuOption[] = []
    if (user !== null && isAdmin)
        options.push(mkDropdownOption(i18n("Admin Panel"), "admin", () => p.navigate(adminPaths.adminDashboard)))
    if (user !== null) {
        options.push(mkDropdownOption(i18n("Edit account"), "edit", p.onEditAccount))
    }
    options.push(mkDropdownOption(i18n("Logout"), "logout", logout))
    return (
        <Dropdown options={options} xDirection="left">
            <AlertWrapper showAlert={!userBusinessValidated}>
                <FlexRow alignCenter>
                    {!userBusinessValidated && <Asset color="black" name="Exclamation" size="icon" />}
                    <Margin values="0 0 0 4px">
                        <P small>{getDisplayName(user)}</P>
                    </Margin>
                </FlexRow>
            </AlertWrapper>
        </Dropdown>
    )
}

const getDisplayName = (user: Domain.User | null) =>
    user ? (user.name && user.surname ? `${user.name} ${user.surname}` : user.email) : ""

const AlertWrapper: React.FC<{ showAlert: boolean }> = p =>
    p.showAlert ? (
        <Popover content={i18n("Company Details are missing. Please fill profile info")} direction="left">
            <div>{p.children}</div>
        </Popover>
    ) : (
        <>{p.children}</>
    )

export const Logo: React.FC<{ prefix?: string; bigSize?: boolean }> = p => (
    <LogoContent bigSize={p.bigSize}>
        {p.prefix && !p.bigSize ? <span>{p.prefix}</span> : null}
        {p.children}
    </LogoContent>
)

const HeaderContainer = styled.div<{ whiteBackground?: boolean }>`
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    background-color: ${p => (p.whiteBackground ? themeColor("white") : themeColor("navBackground"))};
    z-index: ${themeConfig("zIndexHeader")};
    border-bottom: 1px solid ${themeColor("grey50")};
    top: 0;
    width: 100%;
    height: ${themeConfig("headerHeight")};
`

const Spacer = styled.div`
    background-color: ${themeColor("blueGrey")};
    width: 1px;
    height: 1.2rem;
    margin: 0 8px;
`

const LogoContent = styled.div<{ bigSize?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    color: ${themeColor("navText")};
    width: ${p => (p.bigSize ? 220 : 160)}px;
    height: ${p => (p.bigSize ? 36 : 18)}px;
    background: url("/logo.svg") no-repeat top left;
    background-size: 80%;
    span {
        position: absolute;
        left: 131px;
        font-size: ${rem(20)};
        font-weight: 400 !important;
    }
`

const SubTitle = styled(H6)`
    line-height: 0.8rem;
`

const AssetWrapper = styled.div`
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 3%);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`

const LinkButtonWrapper = styled.div<{ isActive?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${p => (p.isActive ? p.theme.colors.action : p.theme.colors.navText)};
`

const LinkButton: React.FC<{ text: string; to: Path; params?: Partial<PathParams> }> = asyncConnect({
    actions: ["navigate"]
})(({ text, to, params, navigate }) => (
    <LinkButtonWrapper onClick={() => navigate(to, params)}>
        <AssetWrapper>
            <Asset name="ArrowLeft" size="small-icon" />
        </AssetWrapper>
        <NavP>{text}</NavP>
    </LinkButtonWrapper>
))

const DashboardButton = (p: { onClick: F0; isActive: boolean }) => (
    <LinkButtonWrapper onClick={p.onClick}>
        <Asset name={p.isActive ? "DashboardRed" : "Dashboard"} size="small-icon" />
    </LinkButtonWrapper>
)

const SeparationLine = styled.div`
    height: 31px;
    width: 1px;
    background-color: ${p => p.theme.colors.grey50};
`

const DashboardButtons = asyncConnect({ stateResolvers: ["auth"], actions: "navigate" })<{ path: string }>(p => {
    const isArchitectOrCDModerator =
        p.auth.type === "Authenticated" &&
        (p.auth.value.type === "ARCHITECT" || p.auth.value.type === "architect" || p.auth.value.type === "CD_MODERATOR")
    let items = [
        <SeparationLine key="line" />,
        <DashboardButton key="home" onClick={() => p.navigate(appPaths.home)} isActive={p.path === "/"} />,
        <LinkButtonWrapper
            key="projects"
            onClick={() => p.navigate(appPaths.projects)}
            isActive={p.path.startsWith("/projects")}>
            {i18n("Projects")}
        </LinkButtonWrapper>,
        <LinkButtonWrapper
            key="devis"
            onClick={() => p.navigate(appPaths.templates)}
            isActive={p.path.startsWith("/templates")}>
            {i18n("Devis Templates")}
        </LinkButtonWrapper>,
        <LinkButtonWrapper
            key="contacts"
            onClick={() => p.navigate(appPaths.contacts)}
            isActive={p.path.startsWith("/contacts")}>
            {i18n("Contacts")}
        </LinkButtonWrapper>,
        <LinkButtonWrapper
            key="partners"
            onClick={() => p.navigate(appPaths.partners)}
            isActive={p.path.startsWith("/partners")}>
            {i18n("Partners")}
        </LinkButtonWrapper>
    ]
    if (isArchitectOrCDModerator)
        items = [
            ...items,
            <LinkButtonWrapper
                key="directory"
                onClick={() => p.navigate(appPaths.directory)}
                isActive={p.path.startsWith("/directory")}>
                {i18n("Contractors directory")}
            </LinkButtonWrapper>
        ]
    return (
        <FlexRow>
            <HorizontalSpace base="32px" />
            <Join items={items} renderJoining={() => <HorizontalSpace base="32px" />}>
                {identity}
            </Join>
        </FlexRow>
    )
})
