import { Store, compose, createStore, applyMiddleware, PreloadedState } from "redux"
import { install as installReduxLoop } from "redux-loop"
import { connectRouter, routerMiddleware } from "connected-react-router"
import { createBrowserHistory } from "history"
import { ContextReducer, combineContextReducers } from "./contextReducers"
import * as data from "./dataState"
import * as auth from "./authState"
import * as meta from "./metaState"
import * as catalog from "./catalogState"
import * as attachments from "./attachmentsState"
import * as cloud from "./cloudState"
import { analyticsMiddleware } from "./analyticsMiddleware"
import { FMapped, TypedOmit, Casted } from "@smartdevis/utils/src/types"
import { createAction } from "@smartdevis/utils/src/actions"

export { actions as authActions, AuthState } from "./authState"
export { actions as metaActions } from "./metaState"
export { actions as appActions, DataState } from "./dataState"

import * as api from "./apiState"
export { actions as apiActions, ApiState } from "./apiState"

export { getMapDispatch, getMapState, MapState } from "../utils/store"
export { connect } from "react-redux"

export const pushHistory = (path: string) => getHistory().push(path)
export const replaceHistory = (path: string) => getHistory().replace(path)
export const goBackHistory = () => getHistory().goBack()
export const redirect = (path: string) => window.location.assign(path)

export const clientActions = {
    ...data.actions,
    ...auth.actions,
    ...cloud.actions,
    ...meta.actions,
    ...attachments.actions,
    ...api.actions,
    ...catalog.actions,
    init: () => createAction("init")
}

export type ClientActionsMap = typeof clientActions
export type ClientAction<T extends keyof ClientActionsMap> = ReturnType<ClientActionsMap[T]>
export type ClientActionDispatcher<T extends keyof ClientActionsMap> = FMapped<ClientActionsMap[T], void>

export type ClientActions = ClientAction<keyof ClientActionsMap>

export type RootState = {
    data: data.DataState
    auth: auth.AuthState
    meta: meta.MetaState
    cloud: cloud.CloudState
    attachments: attachments.AttachmentsState
    router: any,
    catalog: catalog.CatalogsState
    api: api.ApiState
}

let _history: ReturnType<typeof createBrowserHistory>
export const getHistory = () => {
    if (!_history) _history = createBrowserHistory()
    return _history
}

const initStore = (): Store<RootState> => {
    const initialState: TypedOmit<RootState, "router"> = {
        data: data.initialState,
        auth: auth.initialState,
        meta: meta.initialState,
        cloud: cloud.initialState,
        attachments: attachments.initialState,
        catalog: catalog.initialState,
        api: api.initialState
    }

    const reducers: Casted<RootState, ContextReducer<auth.AuthState, any, any>> = {
        data: data.reducer,
        auth: auth.reducer,
        meta: meta.reducer,
        cloud: cloud.reducer,
        catalog: catalog.reducer,
        attachments: attachments.reducer,
        router: connectRouter(getHistory()),
        api: api.reducer
    }

    const { __REDUX_DEVTOOLS_EXTENSION__: installDevTools = () => (f: any) => f } = window as any

    return createStore(
        combineContextReducers(reducers, (s: RootState) => s.auth) as any,
        initialState as PreloadedState<TypedOmit<RootState, "router">>,
        compose(
            installReduxLoop({ DONT_LOG_ERRORS_ON_HANDLED_FAILURES: process.env.NODE_ENV === "production" }),
            applyMiddleware(routerMiddleware(getHistory()), analyticsMiddleware),
            installDevTools()
        )
    )
}
let _store: Store<RootState>
export const getStore = () => {
    if (!_store) {
        _store = initStore()
        _store.dispatch(clientActions.init())
        // NOTE: it is intented to dispatch `init` once and reduce it in many reducers
        _store.dispatch(meta.actions.initLanguage())
    }
    return _store
}
