import * as React from "react"

import { useDebounce } from "@smartdevis/ui/src/hooks/useDebounce"
import { useNullableState } from "../../../hooks/utilityHooks"
import { asyncConnect } from "../../../resolvers"
import { i18n } from "../../../services/translations"
import { CatalogPickerMode } from "./devis-catalogs"
import { IconButton, Button } from "@smartdevis/ui/src/Button"
import { H4, P, H5 } from "@smartdevis/ui/src/Typography"
import { FlexItem, VerticalSpace, FlexColumn, FlexRow, HorizontalSpace } from "@smartdevis/ui/src/utils/common"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { CatalogCollapsableItem } from "./devis-catalogs-styles"
import { Input } from "@smartdevis/ui/src/Inputs"
import { Collapsable } from "@smartdevis/ui/src/Collapsable"
import { Empty } from "@smartdevis/ui/src/Empty"
import { CatalogRef } from "@smartdevis/server/src/domain"
import { values } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { F1, F0, F2 } from "@smartdevis/utils/src/types"
import { IdLite } from "@smartdevis/utils/src/id"
import { CWorkTitle, ShelfType, Version } from "@smartdevis/server/src/domainCatalog"

type CatalogSearchProps = {
    shelfType: ShelfType
    onBrowseCatalog: F1<IdLite>
    onBrowseShelf: F0
    pickerMode: CatalogPickerMode
    setPickerMode: F1<CatalogPickerMode>
    onSelect: F2<IdLite, CWorkTitle>
    shelfVersion: Version
    selectedCatalogRef?: CatalogRef | null
}

export const CatalogSearch = asyncConnect({
    actions: ["searchInCatalogs", "resetCatalogMatches"]
})<CatalogSearchProps>(p => {
    const [query, setQuery] = useNullableState<string>()
    const search = useDebounce(
        (text: string) =>
            isDefined(text)
                ? p.searchInCatalogs({ shelfType: p.shelfType, shelfVersion: p.shelfVersion, text })
                : p.resetCatalogMatches(),
        500
    )

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const q = e.target.value
        setQuery(q)
        search.run(q)
    }

    const renderBrowser = () => {
        const showChildren = !query || p.pickerMode.force
        if (!showChildren) return <CatalogSearchResults startedSearch={query !== null} {...p} />

        switch (p.pickerMode.type) {
            case "browseShelf":
                return (
                    <ShelfBrowser
                        shelfType={p.shelfType}
                        shelfVersion={p.shelfVersion}
                        onCatalogSelect={catalogId => {
                            p.setPickerMode({ type: "browseCatalog", catalogId })
                        }}
                    />
                )
            case "browseCatalog":
                return (
                    <CatalogBrowser
                        shelfType={p.shelfType}
                        shelfVersion={p.shelfVersion}
                        onSelect={p.onSelect}
                        selectedCatalogRef={p.selectedCatalogRef}
                        onBack={() => p.setPickerMode({ type: "browseShelf" })}
                        catalogId={p.pickerMode.catalogId}
                    />
                )
        }
    }
    return (
        <FlexItem overflow="hidden" direction="column">
            <Input
                placeholder={i18n("Search in catalogs, positions and attributes")}
                data-cy="positions-search-catalog-input"
                value={query || ""}
                onChange={handleChange}
            />
            <VerticalSpace base="16px" />
            <FlexItem overflow="auto" direction="column" yAlign="flex-start">
                {renderBrowser()}
            </FlexItem>
        </FlexItem>
    )
})

type CatalogSearchResultsProps = CatalogSearchProps & { startedSearch: boolean }
const CatalogSearchResults = asyncConnect({ stateResolvers: "catalogMatches" })<CatalogSearchResultsProps>(p => {
    const matches = values(p.catalogMatches)
    if (!matches.length)
        return (
            <Empty
                type="node"
                value={
                    <FlexColumn alignCenter>
                        {p.startedSearch ? <H4>{i18n("No results")}</H4> : <H4>{i18n("Catalogs")}</H4>}
                        <P>
                            {i18n("Search for construction work descriptions and add them easily to the devi's.")}
                        </P>
                        <VerticalSpace base="16px" />
                        <IconButton
                            icon="Search"
                            onClick={p.onBrowseShelf}
                            data-cy="positions-search-catalog-browse-all">
                            {i18n("Browse all catalogs")}
                        </IconButton>
                    </FlexColumn>
                }
            />
        )
    return (
        <>
            {matches.map(v => (
                <Collapsable title={v.title} sideText={values(v.positions).length} key={v.catalogId}>
                    <Join items={values(v.positions)} renderJoining={() => <VerticalSpace base="8px" />}>
                        {vp => (
                            <CatalogCollapsableItem
                                isActive={vp.key === p.selectedCatalogRef?.workTitleKey}
                                onClick={() => p.onSelect(v.catalogId, vp)}
                                key={vp.key}>
                                {vp.title}
                            </CatalogCollapsableItem>
                        )}
                    </Join>
                    <VerticalSpace base="8px" />
                    <Button
                        btnType="link"
                        onClick={() => p.onBrowseCatalog(v.catalogId)}
                        data-cy="positions-search-catalog-browse-all">
                        {i18n("Browse catalog")}
                    </Button>
                </Collapsable>
            ))}
        </>
    )
})

type CatalogBrowserProps = Pick<CatalogSearchProps, "selectedCatalogRef" | "onSelect" | "shelfType"> & {
    catalogId: IdLite
    shelfVersion: Version
    onBack: F0
}
export const CatalogBrowser = asyncConnect({ stateResolvers: "catalog" })<CatalogBrowserProps>(p => (
    <FlexItem direction="column" overflow="hidden">
        <FlexRow alignCenter>
            <IconButton icon="ArrowLeft" onClick={p.onBack} />
            <HorizontalSpace base="16px" />
            <H5>{p.catalog.title}</H5>
        </FlexRow>
        <VerticalSpace base="16px" />
        <FlexItem overflow="scroll" direction="column" yAlign="flex-start">
            {(p.catalog.children || []).map(division =>
                (division.children || []).map(position => (
                    <Collapsable title={position.title} sideText={division.title} key={position.key}>
                        <Join items={position.children || []} renderJoining={() => <VerticalSpace base="8px" />}>
                            {vwt => (
                                <CatalogCollapsableItem
                                    isActive={vwt.key === p.selectedCatalogRef?.workTitleKey}
                                    onClick={() => p.onSelect(p.catalogId, vwt)}
                                    key={vwt.key}>
                                    {vwt.title}
                                </CatalogCollapsableItem>
                            )}
                        </Join>
                    </Collapsable>
                ))
            )}
        </FlexItem>
    </FlexItem>
))

type ShelfBrowserProps = Pick<CatalogSearchProps, "shelfType"> & {
    onCatalogSelect: F1<IdLite>
    shelfVersion: Version
}
export const ShelfBrowser = asyncConnect({ stateResolvers: "currentShelf" })<ShelfBrowserProps>(p => (
    <Join items={values(p.currentShelf.catalogs)} renderJoining={() => <VerticalSpace base="16px" />}>
        {c => (
            <CatalogCollapsableItem onClick={() => p.onCatalogSelect(c.id)} key={c.id}>
                {c.name}
            </CatalogCollapsableItem>
        )}
    </Join>
))
