import * as React from "react"
import { mkErr, Result } from "@smartdevis/utils/src/result"
import { F1, TypedOmit } from "@smartdevis/utils/src/types"
import { ClientActionDispatcher } from "../store"
import { CatalogsState, PublishCatalogPayload } from "../store/catalogState"
import { AsyncEvent } from "@smartdevis/utils/src/actions"
import { genTemporaryId } from "@smartdevis/utils/src/id"

export const usePublishCatalogAction = (
    sendAction: ClientActionDispatcher<"publishCatalog">,
    results: CatalogsState["publishResults"],
    onDone?: F1<Result<string>>
) => {
    const [processingActionId, setProcessingActionId] = React.useState("")
    const [, setActionId] = React.useState(genTemporaryId())

    React.useEffect(() => {
        if (processingActionId && results[processingActionId]) onDone?.(results[processingActionId])
    }, [results[processingActionId || ""], processingActionId])

    const getActionState = (): AsyncEvent<Result<string>> => {
        if (!processingActionId) return { type: "NotStarted" }
        const res = results[processingActionId]
        if (!res) return { type: "Processing" }
        if (res.type === "Err") return mkErr(res?.obj?.value || res.value)
        return { type: "Done", value: results[processingActionId] }
    }

    const publish = (p: TypedOmit<PublishCatalogPayload, "actionId">) => {
        const actionId = genTemporaryId()
        setActionId(actionId)
        setProcessingActionId(actionId)
        sendAction({ actionId, ...p })
    }

    return { publish, actionState: getActionState() }
}
