import * as React from "react"
import { IconButton } from "@smartdevis/ui/src/Button"
import { FlexRow, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Domain } from "@smartdevis/server/src/domain"
import { calculateOrder, sortSortables } from "@smartdevis/utils/src/comparable"
import { SMap, values } from "@smartdevis/utils/src/map"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { isEmpty, validString } from "@smartdevis/utils/src/validators"
import { MutationCreators } from "@smartdevis/client/src/utils/mutations"
import { mkFormSchema, mkTextareaSchema } from "../../../components/forms/formSchemas"
import { mkCell } from "../../../components/table/TableCell"
import { EditableRow } from "../../../components/table/TableRow"
import { useItemsMap } from "../../../hooks/utilityHooks"
import { i18n } from "@smartdevis/client/src/services/translations"
import { useUnsavedStatusAsChild } from "../../UnsavedStatusProvider"
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd"
import { useState } from "react"

type GeneralInformationListProps = {
    isReadonly: boolean
    items: SMap<Domain.GeneralInformation>

    mkItem: (order: number) => Domain.GeneralInformation
} & MutationCreators<Domain.GeneralInformation>

export const GeneralInformationList = (p: GeneralInformationListProps) => {
    const {
        items: newItems,
        setItemByKey: setNewItem,
        removeItem: removeNewItem
    } = useItemsMap<Domain.GeneralInformation>({}, "informationId")

    const allItems = { ...p.items, ...newItems }

    const [sortedKeys, setSortedKeys] = useState(sortSortables(values(allItems)).map(v => v.informationId))

    const { setUnsaved } = useUnsavedStatusAsChild("GeneralInformationList")
    React.useEffect(() => {
        setUnsaved(!isEmpty(newItems))
    }, [isEmpty(newItems)])

    const onDrag = (res: DropResult) => {
        if (!res.destination) return

        const reorderedKeys = Array.from(sortedKeys)
        const [removed] = reorderedKeys.splice(res.source.index, 1)
        reorderedKeys.splice(res.destination.index, 0, removed)

        setSortedKeys(reorderedKeys)

        const id = res.draggableId;
        const sortedItems = sortSortables(values(allItems))
        const order = calculateOrder(sortedItems, sortedItems.indexOf(allItems[id]), res.destination.index)
        return p.update(allItems[id])({ order })
    }

    return (
        <DragDropContext onDragEnd={onDrag}>
            <Droppable droppableId="droppable">
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {sortedKeys.map((k, index) => (
                            <Draggable key={k} draggableId={k} index={index}>
                                {provided => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <GeneralInfoRow
                                            key={k}
                                            readonly={p.isReadonly}
                                            rowId={k}
                                            value={allItems[k].value}
                                            onSubmit={value => {
                                                if (newItems[k]) {
                                                    p.create({ ...newItems[k], value })
                                                    removeNewItem(k)
                                                } else p.update(allItems[k])({ value })
                                            }}
                                            onDelete={() => (newItems[k] ? removeNewItem(k) : p.remove(allItems[k])())}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <VerticalSpace base="8px" />
            {!p.isReadonly && (
                <FlexRow>
                    <IconButton icon="CrossWhite" onClick={() => setNewItem(p.mkItem(values(allItems).length))}>
                        {i18n("Text paragraph")}
                    </IconButton>
                </FlexRow>
            )}
        </DragDropContext>
    )
}

const mkSchema = () => mkFormSchema<{ value: string }>({ value: validString }, { value: mkTextareaSchema("Text") })

const GeneralInfoRow: React.FC<{
    rowId: string
    value: string
    onSubmit: F1<string>
    onDelete: F0
    readonly: boolean
}> = p => (
    <EditableRow
        isEdited={!p.value}
        formSchema={mkSchema()}
        rowId={p.rowId}
        cells={[mkCell<{ value: string }>(p.value, ["noPadding"], { editMode: "formless", field: "value" })]}
        readonly={p.readonly}
        visuals={["noBorder", "bottomMargin"]}
        formValue={{ value: p.value }}
        actionOnEnter="submit"
        actionOnBlur="submit"
        onSubmit={v => p.onSubmit(v.value)}
        onDelete={p.onDelete}
    />
)
