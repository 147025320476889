import { Version, ShelfType, Catalog, CatalogMatch, Shelf, CWorkTitle } from "@smartdevis/server/src/domainCatalog"
import { HttpEventSpec } from "./events/utils"
import { UserSettings } from "./services/graphDomain"
import { Brand, State } from "@smartdevis/utils/src/types"
import { mkMapValidator, validateMemberOf } from "@smartdevis/utils/src/validators"
import { writable } from "@smartdevis/utils/src/misc"
import { SMap } from "@smartdevis/utils/src/map"
import { createAction, PayloadAction } from "@smartdevis/utils/src/actions"
import { IdLite } from "@smartdevis/utils/src/id"
import { Domain } from "@smartdevis/server/src/domain"
import { AuthRequest } from "@smartdevis/server/src/services/http/httpAuth"

export const actionCreatorPath = "/store/:actionId"
export const catalogUploadPath = "/catalogs"

const mkHttpAction =
    <A extends string, P>(type: A, payload: P) =>
    <M extends HttpActionMethod>(meta: HttpActionMeta<M>) =>
        createAction<A, P, HttpActionMeta<M>>(type, payload, meta)

const mkReadAction =
    <N extends string>(name: N) =>
    <T>(payload: T) =>
        mkHttpAction(name, payload)({ method: "read" })
const mkWriteAction =
    <N extends string>(name: N) =>
    <T>(payload: T) =>
        mkHttpAction(name, payload)({ method: "write" })
const mkUploadAction =
    <N extends string>(name: N) =>
    <T>(payload: T) =>
        mkHttpAction(name, payload)({ method: "upload" })

export type ShelfTypePayload = { shelfType?: ShelfType }
export type ShelfPayload = { shelfVersion: Version }
export type WorkTitlePayload = ShelfTypePayload & ShelfPayload & { workTitleKey: string; catalogId: IdLite }

export type CatalogVersionPayload = State<
    "catalogVersion",
    ShelfTypePayload & {
        catalogId: IdLite
        catalogVersion?: Version
    }
>
export type CatalogShelfPayload = State<
    "catalogShelf",
    ShelfTypePayload & {
        catalogId: IdLite
        shelfVersion: Version
    }
>

export type CatalogPayload = CatalogVersionPayload | CatalogShelfPayload

export const httpActionMethods = ["read", "write", "upload"] as const
export type HttpActionMethod = typeof httpActionMethods[number]
export type HttpActionMeta<M extends HttpActionMethod = HttpActionMethod> = { method: M }

export const validateHttpActionMeta = mkMapValidator<HttpActionMeta>({
    method: [validateMemberOf(writable(httpActionMethods))]
})

export type HttpEventMeta = HttpActionMeta<HttpActionMethod> & { actionId: string } & AuthRequest & {userId: string} & {userType: string}

export type CatalogCloudActionName = keyof CatalogCloudActions
export type CatalogCloudAction<N extends CatalogCloudActionName = CatalogCloudActionName> = PayloadAction<
    N,
    CatalogCloudEventPayload<N>,
    HttpActionMeta<HttpActionMethod>
>

export type CatalogCloudEvent<N extends CatalogCloudActionName = CatalogCloudActionName> = PayloadAction<
    N,
    CatalogCloudEventPayload<N>,
    HttpEventMeta
>

type DefaultResponse = { message: string }

type HttpEventIO<M extends HttpActionMethod, P, R = DefaultResponse> = { method: M; payload: P; result: R }
export type CatalogCloudEventPayload<E extends keyof CatalogCloudEvents> = CatalogCloudEvents[E]["payload"]
export type CatalogCloudEventResult<E extends keyof CatalogCloudEvents> = CatalogCloudEvents[E] extends HttpEventIO<
    any,
    any,
    infer R
>
    ? R
    : never

export type SerializedHttpEvent = Brand<string, "HttpEvent">
export const serializeHttpEvent = (e: CatalogCloudEvent) => JSON.stringify(e) as SerializedHttpEvent

export type CatalogCloudEventSpecs = { [P in CatalogCloudActionName]: HttpEventSpec<P, CatalogCloudEventResult<P>> }

export type CatalogEvents = {
    shelf: HttpEventIO<"read", ShelfTypePayload & Partial<ShelfPayload>, Shelf>
    shelfQueryText: HttpEventIO<"read", ShelfTypePayload & ShelfPayload & { text: string }, SMap<CatalogMatch>>
    shelfQueryKeys: HttpEventIO<"read", ShelfTypePayload & ShelfPayload & { keys: string[] }, SMap<CatalogMatch>>
    workTitle: HttpEventIO<"read", WorkTitlePayload, CWorkTitle>
    catalog: HttpEventIO<"read", CatalogPayload, Catalog>
    catalogLite: HttpEventIO<"read", CatalogPayload, Catalog>
    updateCatalog: HttpEventIO<"write", ShelfTypePayload & { catalogId: IdLite; blobName: string }>
    uploadCatalog: HttpEventIO<"upload", { data: string }, { name: string }>
}

export type RegisterUserPayload = Pick<Domain.User, "email" | "name" | "surname" | "agreedOn" | "phoneNumber"> & {
    password: string
    isContractor: boolean
    userSettings: UserSettings
}

export type CatalogCloudEvents = CatalogEvents

export type CatalogCloudActionCreator<T extends keyof CatalogCloudEvents> = (
    payload: CatalogCloudEventPayload<T>
) => CatalogCloudAction<T>
type CatalogCloudActions = { [K in keyof CatalogCloudEvents]: CatalogCloudActionCreator<K> }

export const catalogCloudActions: CatalogCloudActions = {
    shelf: mkReadAction("shelf"),
    shelfQueryText: mkReadAction("shelfQueryText"),
    shelfQueryKeys: mkReadAction("shelfQueryKeys"),
    catalog: mkReadAction("catalog"),
    catalogLite: mkReadAction("catalogLite"),
    workTitle: mkReadAction("workTitle"),
    updateCatalog: mkWriteAction("updateCatalog"),
    uploadCatalog: mkUploadAction("uploadCatalog")
}
