import { mkEmptyShelf, Shelf } from "@smartdevis/server/src/domainCatalog"
import { mkRequest } from "./http"
import { actionCreatorPath, CatalogCloudAction, CatalogCloudActionName,catalogCloudActions,CatalogCloudEventResult, CatalogCloudEvents, catalogUploadPath} from "../../../catalog-service/src/catalogCloudActions" 
import { validateShelf, validateCatalogMatch } from "@smartdevis/server/src/models/catalogs"
import { Async, isFetchError, mkFetched, mkFetchError, isFetched } from "@smartdevis/utils/src/async"
import { mkOk } from "@smartdevis/utils/src/result"
import { identity } from "@smartdevis/utils/src/misc"
import { errors, mkMapValidator, validateMap, Validators, validString } from "@smartdevis/utils/src/validators"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { CatalogApiPayload, PublishCatalogPayload } from "../store/catalogState"

const token1 = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI4NUdXdTdDOW10aS1wVC1sTWdnUWE0dXdDdGJWd29oMXFlbUdhd0gwN0NZIn0.eyJleHAiOjE3MTYzMDc1MDcsImlhdCI6MTcxNjI3ODcwNywiYXV0aF90aW1lIjoxNzE2Mjc1MTA3LCJqdGkiOiI1NjI1MjVhNi1jZGI5LTQ5NzAtYWUxNi05MjY2ZjQ3MGExNzkiLCJpc3MiOiJodHRwczovL2tleWNsb2FrLWRldi0xNS1yaWpldzRrdG5hLW9hLmEucnVuLmFwcC9hdXRoL3JlYWxtcy9zbWFydGRldmlzLXJlYWxtIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6IjY0ZDljMWJhLWRiMjAtNDAzZi1iNzBhLTdlYTgwNjE3NzlmYyIsInR5cCI6IkJlYXJlciIsImF6cCI6InNtYXJ0ZGV2aXMtZnJvbnRlbmQiLCJub25jZSI6IjUzOTI0ZmYyLWViNjUtNDI1MC04MTRhLWYxZmFjNTNiMzE1YiIsInNlc3Npb25fc3RhdGUiOiI5MDllYjIzMC05YjFmLTQ2OGItYTdmNS1hNTIyMTkwMDgwMWQiLCJhY3IiOiIwIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRlZmF1bHQtcm9sZXMtc21hcnRkZXZpcy1yZWFsbSIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iLCJBUkNISVRFQ1QiXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwic2lkIjoiOTA5ZWIyMzAtOWIxZi00NjhiLWE3ZjUtYTUyMjE5MDA4MDFkIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJBcmNoaXRlY3QgTG9naW4iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJtYW5kYWxAa2VubndlcnRlLmNoIiwidHlwZSI6IkFSQ0hJVEVDVCIsImdpdmVuX25hbWUiOiJBcmNoaXRlY3QgTG9naW4iLCJlbWFpbCI6Im1hbmRhbEBrZW5ud2VydGUuY2gifQ.drccCAxiXD7zdJ7P1josqyrVIjWR9cGjo9vZIRpBkzMx7RMfm59s5QijyflYtVutkFNVp0N-OUl1YXXxvXdWYhdM-oNbLwu3RatnVqVw8D4h5b8tEWdm_HkK3bA4PeWFAx41mEkuN4nQbK5KNVMjzsweoiqmV-B4huCxI2omElTanjcGw76se7m3ZQZYrL094XfJLFh2O0n33tNxmvHJOmCJvCIBHIRTHQK_DGztupwWvd-Ht_v9Xmy4ijMmDYGDG6QhOILtLxZFwNtC7KmNiOzyvEx3i1RNj3cB7kyrKAmsp8nKiC6Nt6M0iWUvbv9CeDIMbzGaO0bhYx1I7J5UPQ";
export const defaultizeAsyncShelf = (shelf: Async<Shelf>): Async<Shelf> => {
    if (isFetchError(shelf) && shelf.value === "not found") return mkFetched(mkEmptyShelf())
    return shelf as Async<Shelf>
}

const callApiAction =
    <T extends CatalogCloudActionName>(name: T) =>
    <R extends CatalogCloudEventResult<T>>(cb: (result: Async<R>) => Async<R>) =>
    async ({ actionId = genTemporaryId(), ...payload }: CatalogApiPayload<T> & { actionId?: string }): Promise<Async<R>> => {
        const token = token1;//await getFirebase().webAuth.getUserToken()
        if (!token) return mkFetchError("No token")
        const res = await catalogCloudActionRequest(actionId, token, (catalogCloudActions[name] as any)(payload))
        return cb(res as any)
    }

export const getShelf = callApiAction("shelf")(identity)
export const searchShelf = callApiAction("shelfQueryText")(identity)
export const getWorkTitle = callApiAction("workTitle")(workTitle =>
    isFetched(workTitle) && !workTitle.value ? mkFetchError(errors.notFound) : workTitle
)
export const getCatalog = callApiAction("catalogLite")(identity)
export const getFullCatalog = callApiAction("catalog")(identity)

export const publishCatalog = async ({ blob, ...p }: PublishCatalogPayload) => {
    const token = token1;//await getFirebase().webAuth.getUserToken()
    if (!token) return mkFetchError("No token")

    const formData = new FormData()
    formData.append("file", blob)
    const fileMetadata = await uploadFile(token, formData)
    if (!isFetched(fileMetadata)) return fileMetadata
    return callApiAction("updateCatalog")(identity)({ blobName: fileMetadata.value.name, ...p })
}

const catalogCloudActionRequest = <T extends CatalogCloudAction>(actionId: string, token: string, data: T) => {
    type R = CatalogCloudEventResult<T["type"]>
    return mkRequest<R>(
        {
            method: "POST",
            path: actionCreatorPath,
            basePath: "catalog_api_url",//"catalog_api_url",
            token,
            validators: catalogCloudResultValidators[data.type] as Validators<R>,
            urlParams: { actionId }
        },
        { data }
    )
}

const uploadFile = <T extends FormData>(token: string, data: T) =>
    mkRequest<CatalogCloudEventResult<"uploadCatalog">>(
        {
            method: "POST",
            path: catalogUploadPath,
            token,
            validators: [mkOk as any],
            basePath: "catalog_api_url"
        },
        { data, headers: { "Content-Type": "multipart/form-data" } }
    )

const catalogCloudResultValidators: {
    [P in keyof CatalogCloudEvents]: Validators<CatalogCloudEventResult<P>>
} = {
    shelf: [validateShelf],
    shelfQueryText: [validateMap([validateCatalogMatch])],
    shelfQueryKeys: [validateMap([validateCatalogMatch])],
    workTitle: [mkOk],
    catalog: [mkOk],
    catalogLite: [mkOk],
    updateCatalog: [mkMapValidator<{ message: string }>({ message: validString })],
    uploadCatalog: [mkMapValidator<{ name: string }>({ name: validString })]
}
