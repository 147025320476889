import * as React from "react"
import { RouteParams } from "../../../paths"
import { i18n } from "@smartdevis/client/src/services/translations"
import { mkNewSection } from "../DevisSection"
import { asyncConnect } from "../../../resolvers"
import { mkIncompletePosition } from "./DevisPosition.helpers"
import { getStepExitWarningText, useMutationManagement } from "@smartdevis/client/src/utils/devisHelpers"
import {
    mkDeltasByRef,
    mkPositions,
    mkPositionSections,
    mkPositionSubsections,
    mkSublevels
} from "@smartdevis/server/src/models/shared"
import { SubContent } from "../../../components/layouts/Content"
import { PageHeader } from "../../../components/layouts/Header"
import { ExportPositions } from "../../../components/export/ExportButtons"
import { useNotifications } from "../../NotificationsProvider"
import { toMap, keys, values } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { PositionsList } from "../lists/PositionsList"
import { useAttachmentsManagement } from "../../../components/Attachments"
import { Button } from "@smartdevis/ui/src/Button"
import { BaseModalProps, ConfirmationModal, Modal } from "@smartdevis/ui/src/Modal"
import { FlexRow } from "@smartdevis/ui/src/utils/common"
import { Domain } from "@smartdevis/server/src/domain"
import { useUnsavedStatusAsParent } from "../../UnsavedStatusProvider"
import { usleep } from "@smartdevis/utils/src/async"

export const DevisPositionsModal = asyncConnect({
    stateResolvers: [
        "projectDetails",
        "devisCollections",
        "devis",
        "user",
        "isDevisReadonly",
        "projectAttachments",
        "uploadingFiles"
    ],
    actions: ["mutate","resetCatalogMatches", "submitRoundDelta", "revertRoundDelta", "uploadFile", "removeAttachment"],
    renderLoading: () => <>null</>
})<BaseModalProps & RouteParams>(p => {
    const readonly = p.isDevisReadonly
    const devisId = p.devis.devisId

    const { pushNotification } = useNotifications()

    const deltas = mkDeltasByRef(p.devisCollections)
    const sectionsArray = mkPositionSections(p.devisCollections, deltas)
    const sections = toMap(sectionsArray, s => s.sectionId, identity)
    const subsections = toMap(mkPositionSubsections(p.devisCollections, deltas), s => s.sectionId, identity)

    const positions = mkPositions(p.devisCollections, deltas)

    const sm = useMutationManagement("sections", sections, p)
    const subsm = useMutationManagement("sections", subsections, p)
    const pm = useMutationManagement("positions", positions, p)

    const attachments = useAttachmentsManagement("position", p)

    const sortedSections = mkPositionSections({ sections: sm.allItems }, deltas)
    const sortedSublevels = mkSublevels(
        { sections: { ...sm.allItems, ...subsm.allItems }, positions: pm.allItems },
        deltas
    )

    const hasEmptySections = !keys(sm.allItems).every(id => sortedSublevels[id]?.length > 0)

    const onAddPosition =
        (sectionId: string, delta: Partial<Domain.Position> = {}) =>
        () =>
            pm.setUnsavedItem({
                ...mkIncompletePosition(sectionId, devisId, sortedSublevels[sectionId]?.length || 0),
                ...delta
            } as Domain.Position)

    const onAddSection =
        (delta: Partial<Domain.Section> = {}) =>
        () =>
            sm.onItemSubmit({ ...mkNewSection(devisId, "position", values(sm.allItems).length) })(delta)

    const onAddSubsection =
        (delta: Partial<Domain.Section> = {}) =>
        () =>
            subsm.onItemSubmit({ ...mkNewSection(devisId, "position", values(subsm.allItems).length) })(delta)

    const [confirmCloseModalOpen, setConfirmCloseModalOpen] = React.useState(false)

    const unsaved = useUnsavedStatusAsParent()

    const close = async () => {
        await usleep(300)
        if (unsaved.check()) setConfirmCloseModalOpen(true)
        else p.onClose?.()
    }

    return (
        <Modal
            visible
            size="xl"
            height="95vh"
            footer={
                <FlexRow justifyEnd>
                    <Button onClick={close}>{i18n("Save and close")}</Button>
                </FlexRow>
            }>
            <SubContent>
                <PageHeader
                    title={i18n("Positions")}
                    subTitle="Erstellen Sie das Leistungsverzeichnis aus Freitext- oder Katalogpositionen. Sie können jede Domain.Position mit einem Anhang versehen. Die Einheitspreise werden vom Unternehmer ausgefüllt, nachdem Sie ihn eingeladen haben."
                    actionButtons={[
                        <ExportPositions
                            key="export"
                            projectId={p.projectDetails.projectId}
                            devisId={p.devis.devisId}
                            confirm={
                                hasEmptySections
                                    ? i18n("Some sections contain no positions; they'll be omitted.")
                                    : undefined
                            }
                        />
                    ]}
                />

                <PositionsList
                    readonly={readonly}
                    onAddPosition={onAddPosition}
                    onAddSubsection={onAddSubsection}
                    onAddSection={onAddSection}
                    sortedSections={sortedSections}
                    sortedSublevels={sortedSublevels}
                    devis={p.devis}
                    sm={sm}
                    subsm={subsm}
                    pm={pm}
                    pushNotification={pushNotification}
                    deltas={deltas}
                    attachments={attachments}
                />
                <ConfirmationModal
                    cancelText={i18n("Cancel")}
                    submitText={i18n("Proceed")}
                    header={i18n("Warning")}
                    onClose={() => setConfirmCloseModalOpen(false)}
                    onSubmit={() => {
                        setConfirmCloseModalOpen(false)
                        p.onClose?.()
                    }}
                    visible={confirmCloseModalOpen}
                    contentText={getStepExitWarningText()}
                />
            </SubContent>
        </Modal>
    )
})
