import * as React from "react"
import {
    AttributeSchema,
    filterUnusedAttributes,
    fromAttributesMap,
    mkAttributeSchema,
    mkAttributesFormSchema,
    mkDecodedKeyTitle,
    toAttributesMap,
    mkEncodedKey
} from "../../../utils/catalogs"
import { FlexColumn, VerticalSpace, FlexItem, FlexRow } from "@smartdevis/ui/src/utils/common"
import { renderMaps } from "../../../components/forms/formRenderers"
import { mkMultiselectSchema, mkStyledCustom } from "../../../components/forms/formSchemas"
import { i18n } from "../../../services/translations"
import { useItemsMap } from "../../../hooks/utilityHooks"
import { StyledForm } from "../../../components/forms"
import { IconButton } from "@smartdevis/ui/src/Button"
import { useDeepEffect, useRenderedEffect } from "@smartdevis/ui/src/hooks/common"
import { useFormHook, StyledFormSchema } from "@smartdevis/forms/src"
import { isArray, validateArray, validString } from "@smartdevis/utils/src/validators"
import { filterObject, SMap, remap, keys } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { isErr, isOk } from "@smartdevis/utils/src/result"
import { EmptyObject, F1, F0 } from "@smartdevis/utils/src/types"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { Popconfirm } from "@smartdevis/ui/src/Popconfirm"
import { CWorkTitle } from "@smartdevis/server/src/domainCatalog"

const filterFalsy = (v: any) => {
    if (v === undefined) return false
    if (isArray(v) && v.includes(undefined)) return false
    return true
}

const filterInvalidFields = <T extends EmptyObject>(o: T) => filterObject(o, (_, v) => filterFalsy(v)) as Partial<T>

type Attributes = SMap<string[]>

type AttributeFields = Pick<AttributeSchema, "title" | "value">
const CustomAttributeForm: React.FC<{
    attribute: AttributeFields
    onChange: F1<Partial<AttributeSchema>>
    onRemove: F0
}> = p => {
    const { formViewProps, result } = useFormHook<{ title: string; value: string[] }>({
        schema: {
            title: { type: "text", validators: validString, name: i18n("Attribute title") },
            value: mkMultiselectSchema(i18n("Attribute content"), [], {
                validators: [validateArray<string>(validString)],
                placeholder: i18n("Type to add options"),
                creatable: true
            })
        },
        initialValue: {
            title: mkDecodedKeyTitle(p.attribute.title),
            value: p.attribute.value?.map(mkDecodedKeyTitle)
        }
    })

    useDeepEffect(result, () => {
        if (isErr(result)) return
        const { title, value } = result.value
        const valueAsKeys = value?.map(t => mkEncodedKey({ title: t }))
        p.onChange({ title, value: valueAsKeys, options: valueAsKeys })
    })

    return (
        <StyledForm
            styledSchema={
                ["title", { type: "Row", value: ["value", mkStyledCustom(null)] }] as StyledFormSchema<AttributeFields>
            }
            styledInputsRenderMap={{
                Custom: () => (
                    <FlexItem direction="column" yAlign="center" flex="0">
                        <Popconfirm
                            direction="left"
                            title={i18n("Are you sure you want to delete this item?")}
                            onConfirm={p.onRemove}>
                            <IconButton icon="Delete" />
                        </Popconfirm>
                    </FlexItem>
                )
            }}
            elementsRenderMap={renderMaps.elementsRenderMap}
            {...formViewProps}
        />
    )
}

type AttributesFormProps = {
    attrs?: AttributeSchema[]
    initialValues: Attributes
    onChange: F1<Attributes>
    selectedWorkTitle: CWorkTitle
}
export const PresetAttributesForm: React.FC<AttributesFormProps> = p => {
    const { formViewProps, result } = useFormHook({
        schema: mkAttributesFormSchema(p.attrs || []),
        initialValue: p.initialValues
    })
    useDeepEffect(result, () => {
        const catalogBasedAttributes = filterUnusedAttributes(p.selectedWorkTitle, isOk(result) ? result.value : {})
        p.onChange(filterInvalidFields(catalogBasedAttributes) as Attributes)
    })
    const styledSchema = [...(p.attrs || []).map(mkEncodedKey)]

    return (
        <FlexColumn>
            <StyledForm styledSchema={styledSchema} {...renderMaps} {...formViewProps} />
            <VerticalSpace base="16px" />
        </FlexColumn>
    )
}

export const CustomAttributesForm: React.FC<{
    initialAttributes: SMap<AttributeSchema>
    onChange: F1<Attributes>
}> = p => {
    const {
        items: customAttributes,
        setItem: setCustomAttribute,
        removeItem: removeCustomAttribute
    } = useItemsMap<AttributeSchema>(remap(p.initialAttributes, () => genTemporaryId(), identity))

    useDeepEffect(customAttributes, () => {
        p.onChange(filterInvalidFields(toAttributesMap(customAttributes)) as Attributes)
    })

    return (
        <FlexColumn>
            {keys(customAttributes).map(key => (
                <CustomAttributeForm
                    key={key}
                    attribute={customAttributes[key]}
                    onRemove={() => removeCustomAttribute(key)}
                    onChange={delta => setCustomAttribute({ ...customAttributes[key], ...delta }, key)}
                />
            ))}
            <FlexRow alignCenter>
                <IconButton
                    onClick={() => {
                        const id = genTemporaryId()
                        setCustomAttribute(mkAttributeSchema(id), id)
                    }}
                    icon="CrossWhite">
                    {i18n("New attribute")}
                </IconButton>
            </FlexRow>
            <VerticalSpace base="16px" />
        </FlexColumn>
    )
}

export const useAttributesUpdates = (p: AttributesFormProps) => {
    const initialCustomAttributes = fromAttributesMap(
        filterObject(p.initialValues, t => !p.attrs?.find(a => mkEncodedKey(a) === t))
    )
    const initialPresetAttributes = filterObject(p.initialValues, k => !initialCustomAttributes[k])

    const [customAttributes, setCustomAttributes] = React.useState<Attributes>(toAttributesMap(initialCustomAttributes))
    const [presetAttributes, setPresetAttributes] = React.useState<Attributes>(initialPresetAttributes)

    useRenderedEffect(() => {
        p.onChange?.({ ...customAttributes, ...presetAttributes })
    }, [customAttributes, presetAttributes, p.onChange])

    return { initialCustomAttributes, setCustomAttributes, setPresetAttributes }
}
