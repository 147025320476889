import { PathParams } from "../paths"
import { mkFetching, isNotFetched, mkFetched, isFetched } from "@smartdevis/utils/src/async"
import { filterObject } from "@smartdevis/utils/src/map"
import { resolveFromMap, combineResolversWithProps, resolvePathParam, mkResolver } from "./resolverUtils"
import { clientActions } from "../store"

type ProjectProps = Partial<Pick<PathParams, "projectId">>
type DevisTemplateProps = Partial<Pick<PathParams, "templateId">>

export const resolveProjectDetails = combineResolversWithProps<ProjectProps>()(
    resolvePathParam("projectId"),
    projectId =>
        mkResolver(
            s =>
                isFetched(s.data.projectsDetails)
                    ? resolveFromMap(s.data.projectsDetails.value, projectId)
                    : mkFetching(),
            s => (isNotFetched(s.data.projectsDetails) ? [clientActions.fetchProjectsDetails()] : [])
        )
)

export const resolveTempProjectDetails = mkResolver(
    s => s.data.tempProjectsDetails,
    s => (isNotFetched(s.data.tempProjectsDetails) ? [clientActions.fetchTempProjectsDetails()] : [])
)

export const resolveProjectsDetails = mkResolver(
    s =>
        isFetched(s.data.projectsDetails)
            ? mkFetched(filterObject(s.data.projectsDetails.value, (_, v) => !v.isTemplate))
            : s.data.projectsDetails,
    s => (isNotFetched(s.data.projectsDetails) ? [clientActions.fetchProjectsDetails()] : [])
)

export const resolveProjectTemplatesDetails = mkResolver(
    s =>
        isFetched(s.data.projectsDetails)
            ? mkFetched(filterObject(s.data.projectsDetails.value, (_, v) => !!v.isTemplate))
            : s.data.projectsDetails,
    s => (isNotFetched(s.data.projectsDetails) ? [clientActions.fetchProjectsDetails()] : [])
)

export const resolveDevisTemplates = mkResolver(
    s => s.data.devisTemplates,
    s => (isNotFetched(s.data.devisTemplates) ? [clientActions.fetchDevisTemplates()] : [])
)

export const resolveUserBasedWorkDescriptionTemplates = mkResolver(
    s => s.data.userBasedWorkDescriptionTemplates,
    s =>
        isNotFetched(s.data.userBasedWorkDescriptionTemplates) &&
        s.auth.auth.type === "Authenticated" &&
        s.auth.auth.value.type !== "WD_MODERATOR"
            ? [clientActions.fetchUserBasedWorkDescriptionTemplates()]
            : [clientActions.fetchDummyUserBasedWorkDescriptionTemplates()]
)

export const resolvePredefinedWorkDescriptionTemplates = mkResolver(
    s => s.data.predefinedWorkDescriptionTemplates,
    s =>
        isNotFetched(s.data.predefinedWorkDescriptionTemplates)
            ? [clientActions.fetchPredefinedWorkDescriptionTemplates()]
            : []
)

export const resolveDevisTemplate = combineResolversWithProps<DevisTemplateProps>()(
    resolveDevisTemplates,
    resolvePathParam("templateId"),
    (templates, templateId) => mkResolver(() => resolveFromMap(templates, templateId, mkFetching))
)

export const resolveDevisTemplateCollections = combineResolversWithProps<DevisTemplateProps>()(
    resolvePathParam("templateId"),
    templateId =>
        mkResolver(
            s => resolveFromMap(s.data.devisTemplateCollections, templateId, mkFetching),
            s =>
                isNotFetched(s.data.devisTemplateCollections[templateId])
                    ? [clientActions.fetchDevisTemplateCollections(templateId)]
                    : []
        )
)

export const resolveProjectPredefinedCollections = combineResolversWithProps<ProjectProps>()(
    resolvePathParam("projectId"),
    projectId =>
        mkResolver(
            s => resolveFromMap(s.data.projectPredefinedCollections, projectId, mkFetching),
            s =>
                isNotFetched(s.data.projectPredefinedCollections[projectId])
                    ? [clientActions.fetchProjectPredefinedCollections(projectId)]
                    : []
        )
)

export const resolveProjectAttachments = combineResolversWithProps<ProjectProps>()(
    resolvePathParam("projectId"),
    projectId =>
        mkResolver(
            s => resolveFromMap(s.data.projectAttachments, projectId, mkFetching),
            s =>
                isNotFetched(s.data.projectAttachments[projectId])
                    ? [clientActions.fetchProjectAttachments(projectId)]
                    : []
        )
)

export const resolveUserDevisOverview = mkResolver(
    s => s.data.userDevisOverview,
    s => (isNotFetched(s.data.userDevisOverview) ? [clientActions.fetchUserDevisOverview()] : [])
)
