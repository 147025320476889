import * as React from "react"
import { BaseModalProps, ConfirmationModal, Modal } from "@smartdevis/ui/src/Modal"

import { asyncConnect } from "../../resolvers"
import { useAttachmentsManagement } from "../../components/Attachments"
import { getStepExitWarningText, useProjectLevelMutationManagement } from "@smartdevis/client/src/utils/devisHelpers"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { i18n } from "@smartdevis/client/src/services/translations"
import { H4 } from "@smartdevis/ui/src/Typography"
import { Button } from "@smartdevis/ui/src/Button"
import { Spacer, FlexRow, Margin } from "@smartdevis/ui/src/utils/common"
import { Left70, Right30 } from "./DevisConditions"
import { AdditionalInformationList } from "./lists/AdditionalInformationList"
import { GeneralInformationList } from "./lists/GeneralInformationList"
import { ConditionsList } from "./lists/ConditionsList"
import { AttachmentsList } from "./lists/AttachmentsList"
import { PageHeader } from "../../components/layouts/Header"
import { useUnsavedStatusAsParent } from "../UnsavedStatusProvider"
import { usleep } from "@smartdevis/utils/src/async"

export const ProjectConditionsModal = asyncConnect({
    stateResolvers: ["projectAttachments", "user","latestConditionsShelf", "projectPredefinedCollections", "projectDetails", "uploadingFiles"],
    actions: ["mutate", "uploadFile", "resetCatalogMatches", "removeAttachment"],
    renderError: () => <>{null}</>,
    renderLoading: () => <>{null}</>
})<BaseModalProps>(p => {
    const { conditions, additionalInformation } = p.projectPredefinedCollections
    const [confirmCloseModalOpen, setConfirmCloseModalOpen] = React.useState(false)

    const conditionAttachments = useAttachmentsManagement("condition", p)
    const conditionMutations = useProjectLevelMutationManagement("conditions", conditions, p)

    const additionalInformationMutations = useProjectLevelMutationManagement(
        "additionalInformation",
        additionalInformation,
        p
    )
    const generalMutations = prepareMutation("generalInformation", p.mutate, {
        type: "project",
        projectId: p.projectDetails.projectId
    })
    const generalAttachments = useAttachmentsManagement("general", p)

    const unsaved = useUnsavedStatusAsParent([p.visible])

    const close = async () => {
        await usleep(300)
        if (unsaved.check()) setConfirmCloseModalOpen(true)
        else p.onClose?.()
    }

    return (
        <Modal
            visible={p.visible}
            onClose={close}
            size="xl"
            height="95vh"
            headless
            footer={
                <FlexRow justifyEnd>
                    <Button onClick={close} key="save">
                        {i18n("Save and close")}
                    </Button>
                </FlexRow>
            }>
            <PageHeader
                noTopMargin
                title={i18n("Conditions")}
                subTitle="Erfassen Sie allgemeine und spezifische Bedingungen, die der Unternehmer bestätigen muss. Ausserdem können Sie hier Anhänge (z.B. Pläne) hochladen."
            />
            <Spacer color="grey50" type="horizontal" />
            <FlexRow>
                <Left70>
                    <Margin values="16px 0">
                        <H4>{i18n("Prefix")}</H4>
                    </Margin>
                    <GeneralInformationList
                        {...generalMutations}
                        isReadonly={false}
                        items={p.projectPredefinedCollections.generalInformation}
                        mkItem={order => ({
                            informationId: genTemporaryId(),
                            projectId: p.projectDetails.projectId,
                            value: "",
                            createdTs: getTs(),
                            order
                        })}
                    />
                    <Margin values="48px 0 16px">
                        <H4>{i18n("Special provisions")}</H4>
                    </Margin>
                    <ConditionsList
                        isReadonly={false}
                        conditions={conditions}
                        mutations={conditionMutations}
                        shelfConditionsVersion={
                            p.projectDetails.shelfConditionsVersion || p.latestConditionsShelf.meta.version
                        }
                        resetCatalogs={p.resetCatalogMatches}
                        mkItem={order => ({
                            conditionId: genTemporaryId(),
                            createdTs: getTs(),
                            projectId: p.projectDetails.projectId,
                            description: "",
                            order,
                            name: ""
                        })}
                        attachments={conditionAttachments}
                    />
                    <Margin values="48px 0 16px">
                        <H4>{i18n("Additional information")}</H4>
                    </Margin>
                    <AdditionalInformationList
                        isReadonly={false}
                        items={additionalInformation}
                        mutations={additionalInformationMutations}
                        mkItem={order => ({
                            informationId: genTemporaryId(),
                            createdTs: getTs(),
                            projectId: p.projectDetails.projectId,
                            description: "",
                            order,
                            title: "",
                            value: ""
                        })}
                    />
                </Left70>
                <Spacer color="grey50" type="vertical" />
                <Right30>
                    <Margin values="16px 0">
                        <H4>{i18n("Documents upload")}</H4>
                    </Margin>
                    <AttachmentsList
                        attachments={generalAttachments}
                        isReadonly={false}
                        refId={p.projectDetails.projectId}
                    />
                </Right30>
            </FlexRow>

            <ConfirmationModal
                cancelText={i18n("Cancel")}
                submitText={i18n("Proceed")}
                header={i18n("Warning")}
                onClose={() => setConfirmCloseModalOpen(false)}
                onSubmit={() => {
                    setConfirmCloseModalOpen(false)
                    p.onClose?.()
                }}
                visible={confirmCloseModalOpen}
                contentText={getStepExitWarningText()}
            />
        </Modal>
    )
})
