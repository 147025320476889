import * as React from "react"
import { positionValidation } from "@smartdevis/server/src/models/position"
import { getUnitTranslation, i18n } from "@smartdevis/client/src/services/translations"
import {
    mkFormSchema,
    mkTextareaSchema,
    mkFormattedNumberSchema,
    mkSelectSchema,
    mkHiddenSchema,
    mkTextSchema,
    mkStyledCustom
} from "../../../components/forms/formSchemas"
import {
    Cell,
    CellEditMode,
    mkAssetPlaceholderCell,
    mkCell,
    mkDragHandleCell
} from "../../../components/table/TableCell"
import { Row } from "../../../components/table/Table"
import { getRowVisualFromDelta } from "@smartdevis/client/src/utils/devisHelpers"
import { getDevisReadonlyRowExplanationText } from "../DevisSection"
import { Orderable } from "@smartdevis/utils/src/comparable"
import { pickObject, keys, values } from "@smartdevis/utils/src/map"
import { TypedOmit, Optionalize, F0, F1 } from "@smartdevis/utils/src/types"
import { getTs, genTemporaryId, IdLite } from "@smartdevis/utils/src/id"
import { formatSwiss, isStringNumber } from "@smartdevis/utils/src/numbers"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { identity, isDefined } from "@smartdevis/utils/src/misc"
import { BaseModalProps } from "@smartdevis/ui/src/Modal"
import { Domain } from "@smartdevis/server/src/domain"
import { units } from "@smartdevis/server/src/constants"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { StyledFormSchema } from "@smartdevis/forms"
import { CatalogFormAttributeType } from "../devis-catalogs/devis-catalogs"
import { DisplayAttributes } from "../../../components/DisplayAttributes"
import { CellFiller } from "../../../components/table/TableCell.styles"
import { FlexColumn, GridContainer, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { P } from "@smartdevis/ui/src/Typography"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { CWorkTitle } from "@smartdevis/server/src/domainCatalog"
import { mkCatalogRef } from "../../../utils/catalogs"

export type PositionPayload = TypedOmit<
    Domain.Position,
    "sectionId" | "createdTs" | keyof Orderable | "updatedTs" | "devisId"
>

export type PositionEditableFields = Pick<Domain.Position, "name" | "description" | "amount" | "unit">
export interface IncompletePosition extends Optionalize<Domain.Position, keyof PositionEditableFields> {}
export const mkIncompletePosition = (
    sectionId: string,
    devisId: string,
    order: number,
    posId?: string
): IncompletePosition => ({
    createdTs: getTs(),
    positionId: posId ?? genTemporaryId(),
    devisId,
    order,
    sectionId
})
export const attachCatalogToPosition = (
    position: IncompletePosition,
    catalogId: IdLite,
    workTitle: CWorkTitle
): IncompletePosition => ({
    ...position,
    name: workTitle.title,
    catalogRef: mkCatalogRef(catalogId, workTitle),
    description: ""
})

export const mkCatalogPositionStyledSchema = (): StyledFormSchema<PositionPayload, CatalogFormAttributeType> => [
    { type: "Title", value: i18n("Details") },
    "name",
    { type: "Title", value: i18n("Attributes") },
    mkStyledCustom("preset" as const), // This tells formless to use custom renderer (customRenderer func.)
    { type: "Title", value: i18n("") },
    "amount",
    "unit",
    { type: "Title", value: i18n("Custom Attributes") },
    mkStyledCustom("custom" as const) // This tells formless to use custom renderer (customRenderer func.)
]
export const mkPositionSchema = (type?: "weak" | "full") =>
    mkFormSchema<PositionPayload>(
        type === "weak" ? (pickObject(positionValidation, ["name"]) as any) : { ...positionValidation },
        {
            number: mkTextSchema("{Number}No"),
            name: mkTextareaSchema("Name"),
            description: mkTextareaSchema("Description"),
            amount: mkFormattedNumberSchema("Amount"),
            unit: mkSelectSchema<string>(
                i18n("Unit"),
                keys(units).map(k => [getUnitTranslation(k), k]),
                { placeholder: i18n("Enter unit") }
            ),
            positionId: mkHiddenSchema("PositionId")
        }
    )

const positionsGrid = [2, 5, 9, 3.5, 2.5, 2]

export const mkPostionHeaderRow = (readonly: boolean): Row<"header", PositionPayload> => ({
    mode: "static",
    type: "header",
    grid: positionsGrid,
    rowId: "header",
    visuals: ["header"],
    noDrag: true,
    cells: [
        readonly ? mkCell(i18n("{Number}No")) : mkAssetPlaceholderCell(i18n("{Number}No")),
        mkCell(i18n("Name")),
        mkCell(i18n("Description")),
        mkCell(i18n("Amount")),
        mkCell(i18n("Unit")),
        mkCell(i18n("Attach.{attachments on positions view}"))
    ]
})
const Trigger: React.FC<{ content: React.ReactNode; onClick: F0 }> = p => (
    <CellFiller onClick={p.onClick}>{p.content}</CellFiller>
)
export const mkPositionRowBuilder =
    (
        attachmentsCell: F1<Domain.Position, Cell<PositionPayload>>,
        options: { readonly: boolean; predefinedReadonly?: boolean; isUnsaved: F1<string, boolean> },
        actions: {
            setCatalogPosition: F1<Domain.Position>
            onPositionSubmit: F1<Domain.Position, F1<Partial<Domain.Position>>>
            onPositionRemove: F1<Domain.Position, F0>
            onDeltaRevert?: F1<string | undefined, F0 | undefined>
        }
    ) =>
    (position: Domain.Position, delta?: Domain.RoundDelta): Row<"sublevel", PositionPayload> => {
        const { positionId, catalogRef } = position

        const formSchema = mkPositionSchema()

        const mkEditMode = (flag: boolean, field: keyof PositionPayload): CellEditMode<PositionPayload> =>
            flag ? { editMode: "static" } : { editMode: "formless", field }

        const openCatalog = () => {
            if (!catalogRef || options.readonly) return
            actions.setCatalogPosition(position)
        }
        const cells: Cell<PositionPayload>[] = [
            options.readonly
                ? mkCell(position.number ?? "", [], mkEditMode(options.readonly, "number"))
                : mkDragHandleCell(position.number ?? "", [], mkEditMode(options.readonly, "number")),
            // mkCell(position.name, [], mkEditMode(Boolean(options.readonly), "name")),
            // mkCell(position.description, [], mkEditMode(options.readonly, "description")),
            mkCell(
                <Trigger content={position.name} onClick={openCatalog} />,
                catalogRef ? ["openIndicator"] : [],
                mkEditMode(Boolean(options.readonly || catalogRef), "name")
            ),
            catalogRef
                ? mkCell(
                      <DisplayAttributes
                          onClick={openCatalog}
                          attributes={catalogRef.attributes}
                          order={catalogRef.order}
                      />,
                      ["openIndicator"]
                  )
                : mkCell(position.description, [], mkEditMode(options.readonly, "description")),
            position.type === "per"
                ? mkCell(i18n("PER position"), ["italic"])
                : mkCell(formatSwiss(position.amount), [], mkEditMode(options.readonly, "amount")),
            mkCell(getUnitTranslation(position.unit), [], mkEditMode(options.readonly, "unit")),
            options.isUnsaved(positionId)
                ? mkCell("")
                : isStringNumber(position.positionId) //its for the fake ids (genTemporaryId)
                ? attachmentsCell(position)
                : mkCell(<Spinner />)
        ]
        return {
            mode: "editable",
            type: "sublevel",
            formSchema,
            formValue: position,
            rowId: positionId,
            visuals: getRowVisualFromDelta(delta).concat(catalogRef ? ["clickable", "noBorder"] : "noBorder"),
            actionOnEnter: "submit",
            actionOnBlur: "submit",
            resetOnUpdateInitial: true,
            getRowOptions: pos =>
                [
                    position.type && position.type !== "basic"
                        ? mkDropdownOption(i18n("Convert to basic position"), "basic", () =>
                              actions.onPositionSubmit(position)({ ...pos, type: "basic" })
                          )
                        : null,
                    position.type !== "per"
                        ? mkDropdownOption(i18n("Convert to PER position"), "per", () =>
                              actions.onPositionSubmit(position)({ ...pos, type: "per", amount: 0 })
                          )
                        : null
                ].filter(isDefined),
            grid: positionsGrid,
            readonlyClickMessage: getDevisReadonlyRowExplanationText(options?.predefinedReadonly),
            cells,
            onRevert: actions.onDeltaRevert?.(delta?.deltaId),
            isEdited: options.isUnsaved(positionId),
            onDelete: options.readonly ? undefined : actions.onPositionRemove(position),
            onSubmit: v => actions.onPositionSubmit(position)(v),
            readonly: options.readonly
        }
    }
export const renderCatalogPosition = (pos: IncompletePosition) => (
    <FlexColumn style={{ width: "100%" }}>
        <GridContainer columnsGrid={[3, 1, 0.5]} gap="8px" width="100%">
            <P small>
                <b>{pos.name || ""}</b>
            </P>
            <P small style={{ textAlign: "right" }}>
                {!isEmpty(pos.amount) ? formatSwiss(pos?.amount ?? 0) : ""}
            </P>
            <P small style={{ textAlign: "right" }}>
                {pos.unit || ""}
            </P>
        </GridContainer>
        <VerticalSpace base="8px" />
        {pos.catalogRef ? <DisplayAttributes {...pos.catalogRef} /> : <P small>{pos.description}</P>}
    </FlexColumn>
)

export const renderCatalogPositionHeader = () => (
    <GridContainer columnsGrid={[3, 1, 0.5]} gap="8px" width="100%">
        <P small color="grey70">
            {i18n("Name")}
        </P>
        <P small color="grey70" style={{ textAlign: "right" }}>
            {i18n("Amount")}
        </P>
        <P small color="grey70" style={{ textAlign: "right" }}>
            {i18n("Unit")}
        </P>
    </GridContainer>
)
export const usePositionComments = (p: Pick<Domain.ArchitectOfferRequest, "positionComments">) => {
    const [visible, setVisible] = React.useState(false)
    const [attachmentModalPositionId, setPositionId] = React.useState<string | null>(null)
    const openAttachmentModal = (positionId: string) => {
        setPositionId(positionId)
        setVisible(true)
    }
    const positionModalProps: BaseModalProps & { positionId: string | null } = {
        visible,
        onClose: () => setVisible(false),
        positionId: attachmentModalPositionId
    }
    const getPositionComments = (positionId: string) => values((p.positionComments || {})[positionId]).filter(identity)

    return {
        openAttachmentModal,
        getPositionComments,
        positionModalProps
    }
}
