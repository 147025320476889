import * as React from "react"
import { Center, FlexItem, FlexRow, Padding, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { CatalogItem } from "./devis-catalogs"
import { H3, H4, P } from "@smartdevis/ui/src/Typography"
import { i18n } from "../../../services/translations"
import { CatalogItemPreview, CATALOG_SELECTED_ITEM_ID } from "./devis-catalogs-styles"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { Button } from "@smartdevis/ui/src/Button"
import { useDeepEffect } from "@smartdevis/ui/src/hooks/common"
import { SMap, keys } from "@smartdevis/utils/src/map"
import { isDefined, _noop } from "@smartdevis/utils/src/misc"
import { F1, F0 } from "@smartdevis/utils/src/types"
import { isEmpty } from "@smartdevis/utils/src/validators"

const Placeholder = () => (
    <Center style={{ padding: "20px" }}>
        <H3>{i18n("Items preview")}</H3>
        <P textAlign="center">{i18n("A place for the where you will see the preview of the list")}</P>
    </Center>
)

export const CatalogItemsPreview = <Item extends CatalogItem>(p: {
    onRemove: F1<Item>
    onViewItem: F1<Item>
    onClose: F0
    onAddNewItem: F0
    noChanges: boolean
    renderItem: F1<Item, React.ReactNode>
    renderHeader: F0<React.ReactNode>
    currentItem: Item
    getItemId: (i: Item) => string
    items: SMap<Item>
    isEditing: boolean
    title: string
}) => {
    useDeepEffect(p.currentItem, () => document.getElementById(CATALOG_SELECTED_ITEM_ID)?.scrollIntoView())
    if (isEmpty(p.items)) return <Placeholder />
    const allItems = p.isEditing ? { ...p.items, [p.getItemId(p.currentItem)]: p.currentItem } : p.items
    return (
        <FlexItem direction="column" xAlign="center" overflow="hidden">
            <H4>{p.title}</H4>
            <VerticalSpace base="8px" />
            <Padding values="0 31px 0 4px" style={{ width: "100%" }}>
                {p.renderHeader()}
            </Padding>
            <VerticalSpace base="8px" />
            <FlexItem overflow="scroll" direction="column" yAlign="flex-start" style={{ width: "100%" }}>
                <Join items={keys(allItems)} renderJoining={() => <VerticalSpace base="8px" />}>
                    {id => {
                        const selected = p.getItemId(p.currentItem) === id
                        const item = allItems[id]
                        return (
                            <CatalogItemPreview
                                key={id}
                                selected={selected}
                                clickable={isDefined(item.catalogRef)}
                                confirmText={i18n("Are you sure you want to remove this item?")}
                                // tag={!p.noChanges && selected ? i18n("Not saved") : undefined}
                                onButtonClick={() => p.onRemove(item)}
                                onClick={() => (!selected ? p.onViewItem(item) : null)}>
                                {p.renderItem(item)}
                            </CatalogItemPreview>
                        )
                    }}
                </Join>
                {!p.isEditing && isDefined(p.currentItem.catalogRef) && (
                    <>
                        <VerticalSpace base="8px" />
                        <CatalogItemPreview
                            selected
                            clickable
                            confirmText={i18n("Are you sure you want to remove this item?")}
                            onClick={_noop}
                            onButtonClick={p.onAddNewItem}>
                            {p.renderItem(p.currentItem)}
                        </CatalogItemPreview>
                    </>
                )}
            </FlexItem>
            <VerticalSpace base="8px" />
            <FlexRow alignCenter justifyEnd style={{ width: "100%" }}>
                <Button onClick={p.onClose}>{i18n("Finish")}</Button>
            </FlexRow>
        </FlexItem>
    )
}
