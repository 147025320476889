import dayjs from "dayjs"
import { Domain } from "@smartdevis/server/src/domain"
import { getFullName } from "@smartdevis/server/src/models/user"
import { h2, alignRight, lineSeparator, img, h1, row, col, bold, p, Content, small } from "../../utils/pdf"
import { i18n, getProjectTypeTranslation } from "../translations"
import { dateTimeFormat } from "@smartdevis/server/src/constants"
import { prepareAttributesToDisplay } from "../../utils/catalogs"

export const mkDocHeader =
    (
        title: string,
        details: Pick<Domain.ProjectDetails, "number" | "name" | "abbreviation">,
        devis: Pick<Domain.Devis, "number" | "workCategory">
    ) =>
    (): Content =>
        [
            {
                margin: [40, 15, 40, 0],
                columns: [
                    h2(title),
                    alignRight(
                        [
                            `${details.number} ${details.abbreviation} | ${details.name}`,
                            `${devis.number} | ${devis.workCategory}`
                        ].join("\n")
                    )
                ]
            },
            lineSeparator(2, [40, 0, 40, 0])
        ]

export const mkDocFooter =
    (withLogo: boolean) =>
    (currentPage: number, pageCount: number): Content =>
        [
            lineSeparator(2, [40, 0, 40, 0]),
            {
                margin: [40, 10, 40, 0],
                columns: [
                    {
                        text: i18n("Printed on $1{date}", dayjs().format(dateTimeFormat)),
                        alignment: "left",
                        fontSize: 8
                    },
                    withLogo ? img("logo", { fit: [90, 32], alignment: "center" }) : "",
                    {
                        text: i18n("Page $1", currentPage) + " " + i18n("{page x} of $1", pageCount),
                        alignment: "right",
                        fontSize: 8
                    }
                ]
            }
        ]
export const mkDocTitle = (title: string): Content => [
    {
        ...h1(title),
        margin: [0, 12, 0, 20]
    }
]

export const mkDocProjectDetails = (
    details: Pick<
        Domain.ProjectDetails,
        "name" | "number" | "abbreviation" | "projectType" | "street" | "postalCode" | "city"
    >
): Content =>
    row(
        [
            col(bold(p(i18n("Construction object"))), { width: "25%" }),
            col(
                [
                    details.name,
                    `${details.number} ${details.abbreviation}`,
                    getProjectTypeTranslation(details.projectType)
                ],
                { width: "50%" }
            ),
            col([details.street, `${details.postalCode} ${details.city}`], { width: "25%" })
        ],
        { margin: [0, 6, 0, 6] }
    )

export const mkDocDevisDetails = (devis: Pick<Domain.Devis, "number" | "workCategory">): Content =>
    row(
        [
            col(bold(p(i18n("Work"))), { width: "25%" }),
            col([bold(devis.number), bold(devis.workCategory)], { width: "75%" })
        ],
        {
            margin: [0, 6, 0, 3]
        }
    )

export const mkDocCompanyNames = (
    data?: Pick<Domain.AddressInfo, "companyName" | "companyName2" | "name" | "surname">
) =>
    data?.companyName
        ? data.companyName2
            ? [data.companyName, data.companyName2]
            : [data.companyName]
        : [getFullName(data)]

export const mkDocAddressInfoDetails = (
    title: Content,
    data:
        | Pick<
              Domain.AddressInfo,
              | "companyName"
              | "companyName2"
              | "street"
              | "postalCode"
              | "phoneNumber"
              | "email"
              | "city"
              | "name"
              | "surname"
          >
        | undefined
        | null
): Content => {
    if (!data) return [row([col(title, { width: "25%" })], { margin: [0, 6, 0, 3] }), lineSeparator(1)]
    return [
        row(
            [
                col(title, { width: "25%" }),
                col([...mkDocCompanyNames(data), data.street, `${data.postalCode} ${data.city}`], { width: "25%" }),
                col([data.companyName ? getFullName(data) : ""], { width: "25%" }),
                col([data.phoneNumber, data.email], { width: "25%" })
            ],
            { margin: [0, 6, 0, 3] }
        ),
        lineSeparator(1)
    ]
}

export const mkContractType = (meta?: Partial<Domain.OfferMeta>): Content => {
    const createCheckbox = (selected: boolean, text: string) =>
        selected ? bold(small(`[x] ${text}`)) : small(`[ ] ${text}`)
    const unitPrice = createCheckbox(meta?.contractType === "unit-prices", i18n("At unit prices"))
    const flatRate = createCheckbox(meta?.contractType !== "unit-prices", i18n("Flat rate"))
    return row([col(unitPrice, { width: "20%" }), col(flatRate, { width: "*" })])
}

export const mkDocCompanyDetails = (
    data: Pick<Partial<Domain.User>, "companyName" | "street" | "postalCode" | "city">
): Content => [
    data.companyName ?? "",
    data.street ?? "",
    data.postalCode ? (data.city ? `${data.postalCode} ${data.city}` : data.postalCode) : data.city ?? ""
]

export const mkDocContractorDetails = (data: Domain.Contractor): Content => {
    return [mkDocCompanyDetails(data), getFullName(data) || "", data.phoneNumber || "", data.email]
}

export const mkDocAttributesOrDescription = ({ catalogRef, description }: Domain.Position | Domain.Condition): Content[] =>
    catalogRef ? prepareAttributesToDisplay(catalogRef).map(([k, vs]) => p({ text: [k, ` - ${vs}`] })) : [description]

export const getDocPhaseDescription = (phase?: Domain.OfferStateChange) => {
    if (!phase) return i18n("No offer")
    const date = dayjs(phase.createdTs).format(dateTimeFormat)
    switch (phase.state.type) {
        case "negotiation":
            return i18n("Negotiation from $1", date)
        case "round-submitted":
        case "submitted":
            return i18n("Offer from $1", date)
        default:
            return i18n("No offer")
    }
}
