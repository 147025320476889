import * as React from "react"
import { ValueState } from "@smartdevis/utils/src/types"
import styled from "styled-components"
import { Asset } from "./Asset"
import { H6 } from "./Typography"
import { Center, VerticalSpace } from "./utils/common"

export const Empty: React.FC<ValueState<"text", string> | ValueState<"node", React.ReactNode>> = p => (
    <Container>
        <Asset name="Empty" size="auto" />
        <VerticalSpace base="16px" />
        {p.type === "text" ? <H6>{p.value}</H6> : p.value}
    </Container>
)

const Container = styled(Center)`
    width: 100%;
    min-height: 400px;
`
