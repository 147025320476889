import {
    validateMap,
    validNumber,
    validString,
    optionalString,
    mkMapValidator,
    ValidationMap
} from "@smartdevis/utils/src/validators"
import { pickObject } from "@smartdevis/utils/src/map"
import { CatalogMatch, CatalogMeta, Shelf, validVersion } from "../domainCatalog"
import { validIdLite } from "@smartdevis/utils/src/id"

const catalogMetaValidation: ValidationMap<CatalogMeta> = {
    name: validString,
    author: optionalString,
    id: validIdLite,
    ts: validNumber,
    version: validVersion
}

export const validateCatalogMeta = mkMapValidator(catalogMetaValidation)

const shelfValidation: ValidationMap<Shelf> = {
    catalogs: [validateMap([validateCatalogMeta])],
    meta: [mkMapValidator(pickObject(catalogMetaValidation, ["ts", "version"]))]
}

export const validateShelf = mkMapValidator(shelfValidation)

const catalogMatchValidation: ValidationMap<CatalogMatch> = {
    catalogId: validIdLite,
    catalogKey: validString,
    title: validString,
    positions: null
}

export const validateCatalogMatch = mkMapValidator(catalogMatchValidation)
