import React from "react"
import styled from "styled-components"
import { FlexColumn } from "@smartdevis/ui/src/utils/common"
import { prepareAttributesToDisplay } from "../utils/catalogs"
import { CatalogRef } from "@smartdevis/server/src/domain"
import { F0 } from "@smartdevis/utils/src/types"

const Attr = styled.p`
    margin: 0 0 0.1rem;

    &:last-child {
        margin: 0;
    }
`

export const DisplayAttributes: React.FC<Pick<CatalogRef, "attributes" | "order"> & { onClick?: F0 }> = p => {
    const attrs = prepareAttributesToDisplay(p)
    return (
        <FlexColumn onClick={p.onClick}>
            {attrs.map(([k, vs]) => (
                <Attr key={k}>
                    {k}: {vs}
                </Attr>
            ))}
        </FlexColumn>
    )
}
