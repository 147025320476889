import * as React from "react"
import styled, { css } from "styled-components"
import { Asset } from "./Asset"
import { P } from "./Typography"
import { FlexRow, HorizontalSpace, Padding, styleIfProp } from "./utils/common"
import { themeColor } from "./utils/theme"

export const Collapsable: React.FC<{ title: string; sideText?: React.ReactText }> = p => {
    const [collapsed, setCollapsed] = React.useState(false)
    return (
        <Container collapsed={collapsed}>
            <HeaderRow alignCenter spaceBetween onClick={() => setCollapsed(c => !c)}>
                <FlexRow alignCenter>
                    <Asset name={collapsed ? "DashFilledWhite" : "CrossFilledDark"} size="icon" />
                    <HorizontalSpace base="8px" />
                    <P small>{p.title}</P>
                </FlexRow>
                <HorizontalSpace base="8px" />
                {p.sideText ? <SideText small>{p.sideText}</SideText> : null}
            </HeaderRow>
            {collapsed ? <Padding values="16px 0 16px 36px">{p.children}</Padding> : null}
        </Container>
    )
}

const HeaderRow = styled(FlexRow)`
    cursor: pointer;
    width: 100%;
    padding: 8px;
`

const Container = styled.div<{ collapsed?: boolean }>`
    border-radius: 3px;
    width: calc(100% - 4px);
    margin: 2px;
    background-color: ${p => (p.collapsed ? themeColor("white") : themeColor("grey40"))};
    ${styleIfProp(
        "collapsed",
        css`
            border: 1px solid ${themeColor("grey50")};
        `
    )}
`

const SideText = styled(P)`
    color: ${themeColor("grey70")};
    text-align: right;
`
