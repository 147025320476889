import { Catalog } from "@smartdevis/server/src/domainCatalog"
import { mockedCatalog } from "./mocked"

const tmpname = "c2"
export const get = (): Catalog => {
    try {
        return JSON.parse(localStorage.getItem(tmpname) || JSON.stringify(mockedCatalog))
    } catch (err) {
        return mockedCatalog
    }
}

export const set = (v: Catalog) => {
    try {
        localStorage.setItem(tmpname, JSON.stringify(v))
        return true
    } catch (err) {
        return false
    }
}

export type ExportState = ReturnType<typeof toJSON>
export const toJSON = (v: Catalog) => ({
    download: new Date().toISOString() + ".json",
    href: "data:application/json;charset=utf-8," + encodeURIComponent(JSON.stringify(v))
})

export const fromJSON = (f: File) =>
    new Promise<Catalog>((resolve, reject) => {
        const fr = new FileReader()
        fr.onload = e => {
            const lines = e.target?.result
            if (!lines) {
                reject()
                return
            }
            // TODO: validation
            resolve(JSON.parse(lines.toString()))
        }
        fr.onerror = reject
        fr.readAsText(f)
    })
