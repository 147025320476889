import { creatableValidation } from "./common"
import {
    validString,
    validateMemberOf,
    validNumber,
    mkMapValidator,
    nullable,
    optional,
    optionalBoolean,
    optionalNumber,
    optionalString,
    ValidationMap,
    validEmail,
    validId,
    optionalIds,
    validStreet,
    validateMaxLength,
    validateString
} from "@smartdevis/utils/src/validators"
import { Domain } from "../domain"
import { mkVersion, validVersion } from "../domainCatalog"

export const displayCompanyName = (address?: Pick<Domain.AddressInfo, "companyName" | "companyName2">) => {
    if (!address) return ""
    return address?.companyName2
        ? `${address.companyName || ""} ${address.companyName2}`
        : `${address.companyName || ""}`
}

export const projectPersonas: Domain.ProjectPersona[] = [
    "billing",
    "client",
    "clientsRepresentative",
    "constructionManagement",
    "other",
    "owner",
    "ownersRepresentative",
    "planner",
    "specialistPlanner",
    "specialistPlanner2",
    "specialistPlanner3",
    "specialistPlanner4",
    "specialistPlanner5"
]

export const addressInfoValidation: ValidationMap<Domain.AddressInfo> = {
    companyName: optionalString,
    companyName2: optionalString,
    name: validString,
    surname: validString,
    email: validEmail,
    phoneNumber: validString,
    street: [...validStreet, validateMaxLength(250)],
    streetNumber: [optional("")(validateMaxLength(50))],
    postalCode: validString,
    city: validString,
    createdTs: optionalNumber
}

export const addressInfoServerValidation: ValidationMap<Domain.AddressInfo> = {
    companyName: optionalString,
    companyName2: optionalString,
    name: validString,
    surname: validString,
    email: validEmail,
    phoneNumber: validString,
    street: validString,
    streetNumber: [optional("")(validateString)],
    postalCode: validString,
    city: validString,
    createdTs: optionalNumber
}

export const validateAddressInfo = mkMapValidator(addressInfoValidation)
export const validateAddressInfoServer = mkMapValidator(addressInfoServerValidation)

const emptyAddressInfo: Domain.AddressInfo = {
    companyName: "",
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    street: "",
    streetNumber: "",
    postalCode: "",
    city: "",
    createdTs: 0
}

export const projectDetailsValidation: ValidationMap<Domain.ProjectDetails> = {
    projectId: validId,
    number: validString,
    abbreviation: validString,
    name: validString,
    street: [...validStreet, validateMaxLength(250)],
    streetNumber: [nullable(validateMaxLength(50))],
    postalCode: validString,
    city: validString,
    ...creatableValidation,
    isTemplate: optionalBoolean,
    version: validNumber,
    shelfConditionsVersion: validVersion?.map(optional(mkVersion(0))) || null,
    client: [optional(emptyAddressInfo)(validateAddressInfo)],
    billing: [optional(emptyAddressInfo)(validateAddressInfo)],
    planner: [optional(emptyAddressInfo)(validateAddressInfo)],
    owner: [nullable(validateAddressInfo)],
    ownersRepresentative: [nullable(validateAddressInfo)],
    clientsRepresentative: [nullable(validateAddressInfo)],
    specialistPlanner: [nullable(validateAddressInfo)],
    specialistPlanner2: [nullable(validateAddressInfo)],
    specialistPlanner3: [nullable(validateAddressInfo)],
    specialistPlanner4: [nullable(validateAddressInfo)],
    specialistPlanner5: [nullable(validateAddressInfo)],
    constructionManagement: [nullable(validateAddressInfo)],
    projectType: [validateMemberOf<Domain.ProjectType>(["new", "renovation", "restoration"])],
    other: [nullable(validateAddressInfo)],
    contractorIds: optionalIds
}

export const projectDetailsServerValidation: ValidationMap<Domain.ProjectDetails> = {
    projectId: validId,
    number: validString,
    abbreviation: validString,
    name: validString,
    street: validString,
    postalCode: validString,
    city: validString,
    ...creatableValidation,
    isTemplate: optionalBoolean,
    version: validNumber,
    client: [optional(emptyAddressInfo)(validateAddressInfoServer)],
    billing: [optional(emptyAddressInfo)(validateAddressInfoServer)],
    planner: [optional(emptyAddressInfo)(validateAddressInfoServer)],
    owner: [nullable(validateAddressInfoServer)],
    ownersRepresentative: [nullable(validateAddressInfoServer)],
    clientsRepresentative: [nullable(validateAddressInfoServer)],
    specialistPlanner: [nullable(validateAddressInfoServer)],
    specialistPlanner2: [nullable(validateAddressInfoServer)],
    specialistPlanner3: [nullable(validateAddressInfoServer)],
    specialistPlanner4: [nullable(validateAddressInfoServer)],
    specialistPlanner5: [nullable(validateAddressInfoServer)],
    constructionManagement: [nullable(validateAddressInfoServer)],
    projectType: [validateMemberOf<Domain.ProjectType>(["new", "renovation", "restoration"])],
    other: [nullable(validateAddressInfoServer)],
    contractorIds: optionalIds
}

export const validateProjectDetails = mkMapValidator(projectDetailsValidation)
export const validateProjectDetailsServer = mkMapValidator(projectDetailsServerValidation)
