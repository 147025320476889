import * as React from "react"
import styled from "styled-components"
import {
    Center,
    FlexItem,
    FlexRow,
    FlexColumn,
    HorizontalSpace,
    styleFromProp,
    styleIfProp
} from "@smartdevis/ui/src/utils/common"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { IconButton } from "@smartdevis/ui/src/Button"
import { Tag } from "@smartdevis/ui/src/Tag"
import { css } from "styled-components"
import { _noop } from "@smartdevis/utils/src/misc"
import { F0 } from "@smartdevis/utils/src/types"
import { Popconfirm } from "@smartdevis/ui/src/Popconfirm"

const SelectIndicator = styled.span<{ isActive?: boolean }>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    padding: ${styleIfProp("isActive", "3px", "6px")};

    transition: border 0.2s ease-in, padding 0.2s ease-in;

    border: ${styleIfProp("isActive", "5px", "2px")} solid ${p => themeColor(p.isActive ? "primary" : "borderGrey")(p)};
`

const CollapsableRow = styled(FlexRow)<{ isActive?: boolean }>`
    cursor: pointer;

    &:hover ${SelectIndicator} {
        ${p => styleFromProp(!p.isActive, `border: 2px solid ${themeColor("primary")(p)}`)};
    }
`

export const CatalogFormContainer = styled(FlexItem)`
    border-left: 1px solid ${themeColor("grey50")};
    border-right: 1px solid ${themeColor("grey50")};
    margin: 0 -8px;
    padding: 0 6px 0 11px;
`

const CatalogPreviewContainer = styled(FlexColumn)<{ selected: boolean; clickable: boolean }>`
    background-color: ${p => (p.selected ? themeColor("grey40") : "transparent")};
    position: relative;
    padding: 8px 26px 8px 4px;
    ${styleIfProp("clickable", "cursor: pointer;")}
    ${p =>
        p.clickable
            ? css`
                  &:hover {
                      background-color: ${!p.selected ? themeColor("grey35") : themeColor("grey40")};
                  }
              `
            : ""}
`
const ButtonContainer = styled(Center)`
    position: absolute;
    width: auto;
    right: 2px;
    top: 0;
    bottom: 0;
`

export const CATALOG_SELECTED_ITEM_ID = "selected-catalog-item"
export const CatalogItemPreview: React.FC<{
    selected: boolean
    clickable: boolean
    confirmText: string
    onButtonClick: F0
    onClick?: F0
    tag?: string
}> = p => {
    const showButton = p.selected
    return (
        <CatalogPreviewContainer
            style={{ width: "100%" }}
            clickable={p.clickable}
            id={p.selected ? CATALOG_SELECTED_ITEM_ID : undefined}
            onClick={p.clickable ? p.onClick : _noop}
            selected={p.selected}>
            {p.tag && <Tag type="removed">{p.tag}</Tag>}
            {p.children}
            {showButton && (
                <ButtonContainer>
                    <Popconfirm direction="left" onConfirm={p.onButtonClick} title={p.confirmText}>
                        <IconButton icon="Delete" />
                    </Popconfirm>
                </ButtonContainer>
            )}
        </CatalogPreviewContainer>
    )
}

export const CatalogCollapsableItem: React.FC<{ isActive?: boolean; onClick: F0 }> = p => (
    <CollapsableRow noShrink onClick={p.onClick} alignCenter isActive={p.isActive}>
        <SelectIndicator isActive={p.isActive} />
        <HorizontalSpace base="8px" />
        {p.children}
    </CollapsableRow>
)
