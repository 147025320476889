import * as React from "react"
import styled from "styled-components"
import { Link } from "../../components"
import { AdminContent } from "./Dashboard"
import { i18n } from "../../services/translations"
import { asyncConnect } from "../../resolvers"
import { CatalogMeta, Shelf } from "@smartdevis/server/src/domainCatalog"
import { adminPaths } from "../../paths"
import { v4 } from "uuid"
import { HorizontalSpace, sticky } from "@smartdevis/ui/src/utils/common"
import { usePublishCatalogAction } from "../../hooks/usePublishCatalogAction"
import { Button } from "@smartdevis/ui/src/Button"
import { Input, SearchInput } from "@smartdevis/ui/src/Inputs"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { ConfirmationModal } from "@smartdevis/ui/src/Modal"
import { Select } from "@smartdevis/ui/src/Selects"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { H4, P } from "@smartdevis/ui/src/Typography"
import { formatDateTime } from "@smartdevis/utils/src/date"
import { values } from "@smartdevis/utils/src/map"
import { range } from "@smartdevis/utils/src/misc"
import { F1, F0 } from "@smartdevis/utils/src/types"
import { idLite } from "@smartdevis/utils/src/id"

type ShelfViewProps = {
    currentShelf?: Shelf
}
export const ShelfVersionView = asyncConnect({
    stateResolvers: ["currentShelf"]
})(p => <ShelfView {...p} />)

export const ShelfView = asyncConnect({
    stateResolvers: ["publishResults", "latestShelf", "shelfType"],
    actions: ["publishCatalog", "navigate"]
})<ShelfViewProps>(p => {
    const name = p.shelfType === "conditions" ? i18n("Conditions") : i18n("Positions")
    const shelf = p.currentShelf || p.latestShelf

    const [searchText, setSearchText] = React.useState("")
    const [catalogName, setCatalogName] = React.useState("")
    const [visible, setVisible] = React.useState(false)
    const { publish, actionState } = usePublishCatalogAction(p.publishCatalog, p.publishResults)

    const createNewCatalog = () =>
        publish({
            blob: new Blob([JSON.stringify({ title: catalogName, type: "catalog", key: v4() })], {
                type: "application/json"
            }),
            catalogId: idLite(),
            shelfType: p.shelfType
        })

    return (
        <>
            <AdminContent
                subTitle={`${i18n("Catalog list")} - ${name}`}
                actionButtons={[
                    <Button
                        key="create-catalog"
                        btnType="primary"
                        onClick={() => setVisible(true)}
                        disabled={actionState.type === "Processing"}>
                        {i18n("Create Catalog")}
                    </Button>,
                    <VersionPickerWrapper key="select-version">
                        <P>{i18n("Version:")}</P>
                        <HorizontalSpace base="4px" />
                        <Select
                            mode="select"
                            value={shelf.meta.version}
                            options={range(1, p.latestShelf.meta.version)
                                .sort((a, b) => b - a)
                                .map(v =>
                                    mkDropdownOption(`${v}`, v, () =>
                                        p.navigate(adminPaths.shelfVersion, {
                                            shelfVersion: v.toString(),
                                            shelfType: p.shelfType
                                        })
                                    )
                                )}
                        />
                    </VersionPickerWrapper>
                ]}>
                {actionState.type === "Processing" ? (
                    <Spinner />
                ) : (
                    <>
                        <SearchWrapper>
                            <SearchInput
                                placeholder="Search..."
                                onChange={v => setSearchText(v.target.value.toLocaleLowerCase())}
                            />
                        </SearchWrapper>
                        {values(shelf.catalogs)
                            .filter(c => (!!searchText ? c.name.toLocaleLowerCase().includes(searchText) : true))
                            .map(c => (
                                <Link
                                    key={c.id}
                                    to={adminPaths.catalog}
                                    params={{
                                        catalogId: c.id,
                                        shelfVersion: shelf.meta.version as any,
                                        shelfType: p.shelfType
                                    }}>
                                    <CatalogRow catalog={c} />
                                </Link>
                            ))}
                    </>
                )}
            </AdminContent>
            <CreateCatalogModal
                show={visible}
                setShow={setVisible}
                onConfirm={createNewCatalog}
                name={catalogName}
                setName={setCatalogName}
            />
        </>
    )
})

export const VersionPickerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const SearchWrapper = styled.div`
    ${sticky}
    display: flex;
    left: 0px;

    background-color: white;
    width: 100%;
    padding: 24px 240px;
    input {
        box-sizing: content-box;
    }
`

const CatalogRow: React.FC<{ catalog: CatalogMeta }> = ({ catalog }) => (
    <SectionWrapper>
        <FieldsWrapper>
            <Field>
                <H4>{catalog.name}</H4>
            </Field>
            <Field>
                <Label>{i18n("Author")}</Label>
                <P>{catalog.author}</P>
            </Field>
            <Field>
                <Label>{i18n("Version")}</Label>
                <P>{catalog.version}</P>
            </Field>
            <Field>
                <Label>{i18n("ID")}</Label>
                <P>{catalog.id}</P>
            </Field>
            <Field>
                <Label>{i18n("Timestamp")}</Label>
                <P>{formatDateTime(new Date(catalog.ts))}</P>
            </Field>
        </FieldsWrapper>
    </SectionWrapper>
)
const SectionWrapper = styled.div`
    padding: 20px;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`

const FieldsWrapper = styled.div`
    display: flex;
`
const Label = styled.div`
    color: #bbb;
    font-size: 12px;
`
const Field = styled.div`
    flex: 1;
`

export const CreateCatalogModal: React.FC<{
    show: boolean
    setShow: F1<boolean>
    onConfirm: F0
    name: string
    setName: F1<string>
}> = p => (
    <ConfirmationModal
        header={i18n("Are you sure you want to create new Catalog?")}
        onSubmit={() => {
            p.setShow(false)
            p.onConfirm()
        }}
        cancelText={i18n("Cancel")}
        submitText={i18n("Create")}
        submitButtonProps={{ disabled: !p.name.length }}
        onClose={() => p.setShow(false)}
        visible={p.show}>
        <Input
            onChange={e => p.setName(e.target.value)}
            placeholder={i18n("New Project Name")}
            data-cy="projects-card-duplicate-name"
        />
    </ConfirmationModal>
)
