import { v4 } from "uuid"
import { range } from "@smartdevis/utils/src/misc"
import { CNodeType, CNode, Catalog } from "@smartdevis/server/src/domainCatalog"

const n =
    (parentKey?: string) =>
    (type: CNodeType, title: string, mkChildren: (pk: string) => CNode[], key?: string): CNode => {
        const k = key || v4()
        return {
            type,
            title,
            parentKey,
            children: mkChildren(k),
            key: k
        }
    }
export const emptyCatalog = n()("catalog", "empty", () => []) as Catalog
export const mockedCatalog = n()("catalog", "catalog title", cid =>
    range(2).map(d =>
        n(cid)("division", `division 0${d}`, did =>
            range(2).map(p =>
                n(did)("position", `position 0${d}.0${p}`, pid =>
                    range(2).map(wt =>
                        n(pid)("workTitle", `0${d}.0${p} workTitle ${wt}`, wtid =>
                            range(2).map(a =>
                                n(wtid)("attribute", `0${d}.0${p}.${wt} attribute ${a}`, aid =>
                                    range(3).map(v =>
                                        n(aid)("value", `0${d}.0${p}.${wt}:${a} value ${v}`, vid =>
                                            range(3).map(a2 =>
                                                n(vid)(
                                                    "attribute",
                                                    `0${d}.0${p}.${wt} :${a}.${v} nested attr ${a2}`,
                                                    naid =>
                                                        range(3).map(v2 =>
                                                            n(naid)(
                                                                "value",
                                                                `0${d}.0${p}.${wt} :${a}.${v}.${a2} nested value ${v2}`,
                                                                () => []
                                                            )
                                                        )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
) as Catalog
