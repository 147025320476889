import { defaultTheme } from "@smartdevis/ui/src/utils/theme"
import React from "react";
import { FormState } from "..";

export type Prediction = google.maps.places.AutocompletePrediction

export type FocusedIndex = {
    index: number;
    searchBy: string;
}

const API_KEY = "AIzaSyCv9ip-BXCk3IJRgTrpgq9-wUbJOl2HDSk"
const DEBOUNCE_TIME = 600
const MAPS_URI = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`
const EU_COUNTRIES: string[] = ["fr", "it", "ch"]
const COLORS = defaultTheme.colors

const useGoogleMapsAutocomplete = (setSearch: string) => {
    const [predictions, setPredictions] = React.useState<Prediction[]>([])

    React.useEffect(() => {
        if (!setSearch) {
            setPredictions([])
            return
        }

        const timer = setTimeout(() => {
            const script = document.createElement("script")
            script.src = MAPS_URI
            script.async = true
            script.onload = () => {
                const autocompleteService = new window.google.maps.places.AutocompleteService()
                const placesService = new window.google.maps.places.PlacesService(document.createElement("div"))
                const sessionToken = new window.google.maps.places.AutocompleteSessionToken()

                const getAddressPredictions = (getSearch: string) => {
                    autocompleteService.getPlacePredictions(
                        { input: getSearch, componentRestrictions: { country: EU_COUNTRIES }, sessionToken },
                        (predictions: Prediction[], status: string) => {
                            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                const promises = predictions.map(prediction => {
                                    return new Promise((resolve, reject) => {
                                        setPredictions([])
                                        placesService.getDetails({ placeId: prediction.place_id },(place: Prediction[], status: string) => {
                                                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                                    resolve({
                                                        ...prediction,
                                                        postal_code: extractAddressComponent(place, "postal_code"),
                                                        city: extractAddressComponent(place, "locality"),
                                                        street: extractAddressComponent(place, "route"),
                                                        street_num: extractAddressComponent(place, "street_number")
                                                    })
                                                } else {
                                                    resolve(null)
                                                }
                                            }
                                        )
                                    })
                                })

                                Promise.all(promises).then(result => {
                                    const filteredPredictions = result.filter(prediction => prediction !== null)
                                    setPredictions(filteredPredictions)
                                })
                            } else {
                                setPredictions([])
                            }
                        }
                    )
                }

                getAddressPredictions(setSearch)
            }

            document.head.appendChild(script)

            return () => {
                document.head.removeChild(script)
            }
        }, DEBOUNCE_TIME)

        return () => clearTimeout(timer)
    }, [setSearch])

    const extractAddressComponent = (place: google.maps.places.PlaceResult, type: string): string => {
        const addressComponent = place.address_components.find(component => component.types.includes(type))
        return addressComponent ? addressComponent.long_name : ""
    }

    return { predictions, setPredictions }
}

export const useGenerateAddresses = <T extends any>(state:FormState<T>,title?:string) => {
    const CompanynameID = "text-input-Company Name-" + title
    const StreetnameID = "text-input-Street *-" + title

    const [input, setInput] = React.useState<string>("")
    const [focusedIndex, setfocusedIndex] = React.useState({ index: -1, searchBy: "" })
    
    const { predictions, setPredictions } = useGoogleMapsAutocomplete(input)


    const handleInputChange = (setSearch: string) => {
        if (setSearch === null) {
            setInput("");
            const elements = document.querySelectorAll("ul#address-container");
            if (elements.length > 0) {
                elements.forEach(element => element.remove());
                return
            }
        } else {
            setInput(setSearch)
        }
    }


    React.useEffect(() => {
        if ((state as any).street && (state as any).street.active) {
            handleInputChange((state as any).street.value)
            document.querySelectorAll('input[type="text"]').forEach((input, index) => {
                if (input.id === StreetnameID) setfocusedIndex({ index: index - 1, searchBy: "STREET_NAME" })
            })
        }
        if ((state as any).companyName && (state as any).companyName.active) {
            handleInputChange((state as any).companyName.value)
            document.querySelectorAll('input[type="text"]').forEach((input, index) => {
                if (input.id === CompanynameID) setfocusedIndex({ index: index - 1, searchBy: "COMPANY_NAME" })
            })
        }
    }, [state])

    return {
        predictions,setPredictions,focusedIndex
    }
}

export const createDropDown = <T extends any>(
    Predictions: Prediction[],
    focusedIndex: FocusedIndex,
    setPredictions: React.Dispatch<React.SetStateAction<google.maps.places.AutocompletePrediction[]>>,
    setState: React.Dispatch<React.SetStateAction<FormState<T>>>
) => {
    const OptionContainer = document.createElement("ul");
    OptionContainer.id = "address-container";
    OptionContainer.style.cssText = `
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        max-height: 200px;
        list-style: none;
        padding: 0px 0px 0px 5px;
        position: absolute;
        background: white;
        z-index: 2;
        overflow-y: auto;
    `;

    const closeDropDown = () => {
        setPredictions([]);
        const elements = document.querySelectorAll("ul#address-container");
        elements.forEach(element => element.remove());
    };

    Predictions.forEach((prediction: Prediction) => {
        const ButtonOption = document.createElement("li");
        ButtonOption.style.cssText = `
            height: 1.5rem;
            width: inherit;
            cursor: pointer;
        `;
        ButtonOption.textContent = `${prediction.description}`;
        ButtonOption.addEventListener("click", () => {
            closeDropDown();
            if (focusedIndex.searchBy === "COMPANY_NAME") {
                setState(val => ({
                    ...val,
                    companyName: { ...(val as any).companyName, value: prediction.structured_formatting.main_text },
                    postalCode: { ...(val as any).postalCode, value: prediction.postal_code },
                    street: { ...(val as any).street, value: prediction.street },
                    city: { ...(val as any).city, value: prediction.city },
                    streetNumber: { ...(val as any).streetNumber, value: prediction.street_num }
                }));
            }
            if (focusedIndex.searchBy === "STREET_NAME") {
                setState(val => ({
                    ...val,
                    ...((val as any).companyName && { companyName: { ...(val as any).companyName, value: ((val as any).companyName!.value ? (val as any).companyName.value : '') } }),
                    postalCode: { ...(val as any).postalCode, value: prediction.postal_code },
                    street: { ...(val as any).street, value: prediction.street },
                    city: { ...(val as any).city, value: prediction.city },
                    streetNumber: { ...(val as any).streetNumber, value: prediction.street_num }
                }));
            }
        });
        ButtonOption.addEventListener("mouseover", () => {
            ButtonOption.style.color = COLORS.primary;
        });
        ButtonOption.addEventListener("mouseout", () => {
            ButtonOption.style.color = COLORS.default;
        });
        OptionContainer.appendChild(ButtonOption);
    });

    const inputs = document.querySelectorAll('input[type="text"]');
    if (focusedIndex.index >= 0 && focusedIndex.index < inputs.length - 1) {
        const input = inputs[focusedIndex.index + 1];
        input.parentNode!.insertBefore(OptionContainer, input.nextSibling);
    } else {
        document.body.appendChild(OptionContainer);
    }

    document.addEventListener("click", (event) => {
        const target = event.target as HTMLElement;
        if (!target.closest("ul#address-container") && !target.closest('input[type="text"]')) {
            closeDropDown();
        }
    });
};