import {
    validString,
    validNumber,
    validBoolean,
    mkMapValidator,
    optional,
    optionalBoolean,
    optionalNumber,
    optionalString,
    ValidationMap,
    validId,
    validIds,
    patternMatchValidator,
    optionalMap,
    validateMemberOf
} from "@smartdevis/utils/src/validators"
import { omitObject, pickObject } from "@smartdevis/utils/src/map"
import { creatableValidation } from "./common"
import { Domain } from "../domain"
import { validatePosition, validateSection } from "./position"
import { validateAttachment, validateAttachmentData } from "./attachment"
import { mkVersion, validVersion } from "../domainCatalog"

const devisPositionsFormatValidator = patternMatchValidator<"type", Domain.PositionsFormat>("type", {
    "file-based": mkMapValidator({ fileAttachment: [validateAttachmentData] }),
    "positions-based": mkMapValidator({}),
    "not-selected": mkMapValidator({})
})

export const devisValidation: ValidationMap<Required<Domain.Devis>> = {
    ...creatableValidation,
    devisId: validId,
    projectId: validId,
    shelfVersion: validVersion?.map(optional(mkVersion(0))) || null,
    shelfConditionsVersion: validVersion?.map(optional(mkVersion(0))) || null,
    positionsFormat: [optional<Domain.PositionsFormat>({ type: "positions-based" })(devisPositionsFormatValidator)], // default to positions based for devis with no positionsFormat - backwards compatibility
    number: validString,
    workCategory: validString,
    entryTs: validNumber,
    workStartTs: validNumber,
    workEndTs: validNumber,
    costEstimateOriginal: optionalNumber,
    costEstimateRevised: optionalNumber,
    includeVat: optionalBoolean,
    remarks: optionalString,
    contractorIds: validIds
}

export const devisDuplicationMapValidation: ValidationMap<Domain.DevisDuplicationMap> = {
    additionalInformation: validBoolean,
    conditions: validBoolean,
    deductions: validBoolean,
    generalInformation: validBoolean,
    positions: validBoolean
}

export const devisTemplateValidation: ValidationMap<Domain.DevisTemplate> = {
    ...creatableValidation,
    createdTs: optionalNumber, // TODO shouldnt be optional
    ...pickObject(devisValidation, ["positionsFormat", "workCategory"]),
    templateId: validId,
    positions: optionalMap([validatePosition]),
    sections: optionalMap([validateSection]),
    attachments: optionalMap([validateAttachment])
}

export const workDescriptionTemplateValidation: ValidationMap<Domain.WorkDescriptionTemplate> = {
    architectId: validNumber,
    id: validNumber,
    positionsFormat: [optional<Domain.PositionsFormat>({ type: "positions-based" })(devisPositionsFormatValidator)],
    type: [validateMemberOf<Domain.WorkDescriptionTemplate["type"]>(["PREDEFINED", "USER_BASED"])],
    workCategory: validString
}

export const validateDevis = mkMapValidator(devisValidation)
export const validateDevisTemplate = mkMapValidator(devisTemplateValidation)
export const validateWorkDescriptionTemplate = mkMapValidator(workDescriptionTemplateValidation)
export const validateDevisTemplateCollections = mkMapValidator(
    pickObject(devisTemplateValidation, ["positions", "sections", "attachments"])
)
export const validateDevisWithoutId = mkMapValidator(omitObject(devisValidation, ["devisId"]))
export const validateDevisDuplicationMap = mkMapValidator(devisDuplicationMapValidation)
