import { Cmd, CmdType } from "redux-loop"
import { Domain } from "@smartdevis/server/src/domain"
import { mkFetching, mkFetchError } from "@smartdevis/utils/src/async"
import { clientActions } from "."
import { requestData } from "../services/server"
import { validateAppChangelog } from "@smartdevis/server/src/models/changelog"
import { SMap } from "@smartdevis/utils/src/map"
import { filterMapByValidation } from "@smartdevis/utils/src/validators"
import { validateContractorOfferClient, validatePublicOffer } from "@smartdevis/server/src/models/offers"
import { isProd } from "../utils/envHelpers"
import { validateUser } from "@smartdevis/server/src/models/user"
import { validateDevisCollections, validateProjectPredefinedCollections } from "@smartdevis/server/src/models/project"
import { validateContractor } from "@smartdevis/server/src/models/contractor"
import { validateProjectDetails } from "@smartdevis/server/src/models/projectDetails"
import { validateAttachment } from "@smartdevis/server/src/models/attachment"
import {
    validateDevis,
    validateDevisTemplate,
    validateWorkDescriptionTemplate,
    validateDevisTemplateCollections
} from "@smartdevis/server/src/models/devis"
import { validateDevisOverview } from "@smartdevis/server/src/models/admin"
import { EmptyObject } from "@smartdevis/utils/src/types"
import { Authentication } from "./authState"
import { validatePartner } from "@smartdevis/server/src/models/partner"

export {
    addContractor,
    deleteContractor,
    editContractor,
    fetchContractorsDirectory
} from "./dataQueries/contractorsDirectory"

// eslint-disable-next-line no-console
const reportInvalidData = (data: any) => !isProd() && console.log("Invalid data", data)

export type FetchOptions<T = EmptyObject> = { skipPreAction?: boolean; params?: T }
export const mkFetchCmd = (
    cmds: { pre?: CmdType<any>; core: CmdType<any>; post?: CmdType<any> },
    options: FetchOptions = {}
) => {
    const commands: CmdType<any>[] = []
    if (cmds.pre && !options.skipPreAction) commands.push(cmds.pre)
    commands.push(cmds.core)
    if (cmds.post) commands.push(cmds.post)
    return Cmd.list(commands, { sequence: true })
}

// v2 Java BE

// auth
export const setupSignupRole = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            core: Cmd.run(() =>
                requestData<{}>("authorized", {
                    path: "/signup-role-setup",
                    auth,
                    validators: [],
                    isV2: true
                })
            )
        },
        options
    )

// offers
export const fetchContractorOffers = (auth: Authentication, options: FetchOptions<{ uploadedIds: string[] }> = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("contractorOffers", mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<SMap<Domain.ContractorOffer>>("authorized", {
                        path: "/offers",
                        auth,
                        validators: [filterMapByValidation([validateContractorOfferClient], reportInvalidData)],
                        isV2: true
                    }),
                {
                    successActionCreator: res => clientActions._setApp("contractorOffers", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("contractorOffers", mkFetchError(error.message))
                }
            ),
            post: options.params?.uploadedIds
                ? Cmd.action(clientActions.endUploading(options.params?.uploadedIds))
                : undefined
        },
        options
    )

// projects
export const fetchProjectAttachments = (
    auth: Authentication,
    projectId: string,
    options: FetchOptions<{ uploadedIds: string[] }> = {}
) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setProjectAttachments(projectId, mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<SMap<Domain.Attachment>>("authorized", {
                        path: "/projects/:projectId/attachments",
                        auth,
                        urlParams: { projectId },
                        validators: [filterMapByValidation([validateAttachment], reportInvalidData)],
                        isV2: true
                    }),
                {
                    successActionCreator: res => clientActions._setProjectAttachments(projectId, res),
                    failActionCreator: (error: Error) =>
                        clientActions._setProjectAttachments(projectId, mkFetchError(error.message))
                }
            ),
            post: options.params?.uploadedIds
                ? Cmd.action(clientActions.endUploading(options.params?.uploadedIds))
                : undefined
        },
        options
    )

export const fetchProjectPredefinedCollections = (
    auth: Authentication,
    projectId: string,
    options: FetchOptions = {}
) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setProjectPredefinedCollections(projectId, mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<Domain.ProjectPredefinedCollections>("authorized", {
                        path: "/projects/:projectId/collections",
                        auth,
                        urlParams: { projectId },
                        validators: [validateProjectPredefinedCollections],
                        isV2: true
                    }),
                {
                    successActionCreator: res => clientActions._setProjectPredefinedCollections(projectId, res),
                    failActionCreator: (error: Error) =>
                        clientActions._setProjectPredefinedCollections(projectId, mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchProjectsDetails = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("projectsDetails", mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<SMap<Domain.ProjectDetails>>("authorized", {
                        path: "/projects-details",
                        auth,
                        validators: [filterMapByValidation([validateProjectDetails], reportInvalidData)],
                        isV2: true
                    }),
                {
                    successActionCreator: res => clientActions._setApp("projectsDetails", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("projectsDetails", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchTempProjectsDetails = (auth: Authentication,options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("tempProjectsDetails", mkFetching())),
            core: Cmd.run(
                () => {
                    const items: { [key: string]: string } = {};
                    for (let i = 0; i < localStorage.length; i++) {
                        const key = localStorage.key(i);
                        if (key) {
                            const value = localStorage.getItem(key);
                            if (value) {
                                items[key] = value;
                            }
                        }
                    }
                    return items;
                }     
                    ,
                {
                    successActionCreator: res => clientActions._setApp("tempProjectsDetails", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("tempProjectsDetails", mkFetchError(error.message))
                }
            )
        },
        options
    )


// tenders
export const fetchAllDevis = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("devis", mkFetching())),
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.Devis>>("authorized", {
                        path: "/devis",
                        auth,
                        validators: [filterMapByValidation([validateDevis], reportInvalidData)],
                        isV2: true
                    }),
                {
                    successActionCreator: res => clientActions._setApp("devis", res),
                    failActionCreator: (error: Error) => clientActions._setApp("devis", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchPredefinedWorkDescriptionTemplates = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("predefinedWorkDescriptionTemplates", mkFetching())),
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.WorkDescriptionTemplate>>("authorized", {
                        path: "/wd-templates/fetch-predefined-templates",
                        auth,
                        validators: [filterMapByValidation([validateWorkDescriptionTemplate], reportInvalidData)],
                        isV2: true
                    }),
                {
                    successActionCreator: res => clientActions._setApp("predefinedWorkDescriptionTemplates", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("predefinedWorkDescriptionTemplates", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchDummyUserBasedWorkDescriptionTemplates = () =>
    Cmd.action(clientActions._setApp("userBasedWorkDescriptionTemplates", { type: "Fetched", value: {} }))

export const fetchUserBasedWorkDescriptionTemplates = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("userBasedWorkDescriptionTemplates", mkFetching())),
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.WorkDescriptionTemplate>>("authorized", {
                        path: "/wd-templates/fetch-user-based-templates",
                        auth,
                        validators: [filterMapByValidation([validateWorkDescriptionTemplate], reportInvalidData)],
                        isV2: true
                    }),
                {
                    successActionCreator: res => clientActions._setApp("userBasedWorkDescriptionTemplates", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("userBasedWorkDescriptionTemplates", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchUserDevisOverview = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("userDevisOverview", mkFetching())),
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.DevisOverview>>("authorized", {
                        path: "/recent-tenders",
                        auth,
                        validators: [filterMapByValidation([validateDevisOverview], reportInvalidData)],
                        isV2: true
                    }),
                {
                    successActionCreator: res => clientActions._setApp("userDevisOverview", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("userDevisOverview", mkFetchError(error.message))
                }
            )
        },
        options
    )

// v1 Node BE
export const fetchPublicOffer = (offerId: string, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("contractorPublicOffer", mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<Domain.PublicOffer>("public", {
                        path: "/public/offer/:offerId",
                        urlParams: { offerId },
                        validators: [validatePublicOffer]
                    }),
                {
                    successActionCreator: res => clientActions._setApp("contractorPublicOffer", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("contractorPublicOffer", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchAppChangelog = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setMeta({ changelog: mkFetching() })),
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.Changelog>>("authorized", {
                        path: "/changelog",
                        auth,
                        validators: [validateAppChangelog]
                    }),
                {
                    successActionCreator: changelog => clientActions._setMeta({ changelog }),
                    failActionCreator: (error: Error) =>
                        clientActions._setMeta({ changelog: mkFetchError(error.message) })
                }
            )
        },
        options
    )

export const fetchUserData = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("user", mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<Domain.User>("authorized", {
                        path: "/user",
                        auth,
                        validators: [validateUser]
                    }),
                {
                    successActionCreator: res => clientActions._setApp("user", res),
                    failActionCreator: (error: Error) => clientActions._setApp("user", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchDevisCollections = (
    auth: Authentication,
    projectId: string,
    devisId: string,
    options: FetchOptions = {}
) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setDevisCollections(devisId, mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<Domain.DevisCollections>("authorized", {
                        path: "/projects/:projectId/devis/:devisId/collections",
                        urlParams: { projectId, devisId },
                        auth,
                        validators: [validateDevisCollections]
                    }),
                {
                    successActionCreator: res => clientActions._setDevisCollections(devisId, res),
                    failActionCreator: (error: Error) =>
                        clientActions._setDevisCollections(devisId, mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchContractors = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("contractors", mkFetching())),
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.Contractor>>("authorized", {
                        path: "/contractors",
                        auth,
                        validators: [filterMapByValidation([validateContractor], reportInvalidData)]
                    }),
                {
                    successActionCreator: res => clientActions._setApp("contractors", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("contractors", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchPartners = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("partners", mkFetching())),
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.Partner>>("authorized", {
                        path: "/partners",
                        auth,
                        validators: [filterMapByValidation([validatePartner], reportInvalidData)]
                    }),
                {
                    successActionCreator: res => clientActions._setApp("partners", res),
                    failActionCreator: (error: Error) => clientActions._setApp("partners", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchDevisTemplates = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("devisTemplates", mkFetching())),
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.DevisTemplate>>("authorized", {
                        path: "/devis-templates",
                        auth,
                        validators: [filterMapByValidation([validateDevisTemplate], reportInvalidData)]
                    }),
                {
                    successActionCreator: res => clientActions._setApp("devisTemplates", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("devisTemplates", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const fetchDevisTemplateCollections = (
    auth: Authentication,
    templateId: string,
    options?: FetchOptions<{ uploadedIds: string[] }>
) =>
    mkFetchCmd(
        {
            core: Cmd.run(
                async () =>
                    requestData<Domain.DevisTemplateCollections>("authorized", {
                        path: "/devis-templates/:templateId",
                        auth,
                        urlParams: { templateId },
                        validators: [validateDevisTemplateCollections]
                    }),
                {
                    successActionCreator: res => clientActions._setDevisTemplateCollections(templateId, res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("devisTemplates", mkFetchError(error.message))
                }
            ),
            post: options?.params?.uploadedIds
                ? Cmd.action(clientActions.endUploading(options?.params?.uploadedIds))
                : undefined
        },
        options
    )

export const fetchAdminDevisOverview = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.DevisOverview>>("authorized", {
                        path: "/devis-overview",
                        auth,
                        validators: [filterMapByValidation([validateDevisOverview], reportInvalidData)]
                    }),
                {
                    successActionCreator: res => clientActions._setApp("adminDevisOverview", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("adminDevisOverview", mkFetchError(error.message))
                }
            )
        },
        options
    )
