import { SMap, omitObject, toMap } from "@smartdevis/utils/src/map"
import { Brand, Optionalize, TypedOmit, F1 } from "@smartdevis/utils/src/types"
import { optional, validateMemberOf, Validators, validNumber } from "@smartdevis/utils/src/validators"
import { identity } from "@smartdevis/utils/src/misc"
import { IdLite, SD_ZERO_HOUR } from "@smartdevis/utils/src/id"

export type EncodedKey = Brand<string, "encodedKey">
export type DecodedKey = Optionalize<Pick<CNode, "key" | "title">, "key">

export type CNodeType = "catalog" | "division" | "position" | "workTitle" | "attribute" | "value"

type _CNode<T1 extends CNodeType, T2, TPayload = never> = {
    type: T1
    key: string
    title: string
    parentKey?: string
    children?: Array<T2>
    payload?: TPayload
}

// export type AttributePayload = { attributeType: AttributeType; isLE?: boolean }
export interface CNode<T1 extends CNodeType = CNodeType, T2 = any, TPayload = never> extends _CNode<T1, T2, TPayload> {}
export type TreeNode = TypedOmit<CNode, "children"> & { order: number }
export type Catalog = CNode<"catalog", CDivision>
export type CDivision = CNode<"division", CPosition>
export type CPosition = CNode<"position", CWorkTitle>
export type CWorkTitle = CNode<"workTitle", CAttribute>
export type CAttribute = CNode<"attribute", CValue>
export type CValue = CNode<"value", CAttribute>

export type CatalogMatch = {
    catalogId: IdLite
    catalogKey: string
    title: string
    positions: SMap<CWorkTitle>
}

export type ShelfType = "positions" | "conditions"

export const validShelfType = [
    optional<ShelfType>("positions")(validateMemberOf<ShelfType>(["positions", "conditions"]))
]

export type Shelf = {
    meta: {
        version: Version
        ts: number
    }
    catalogs: SMap<CatalogMeta>
}

export type CatalogMeta = {
    id: IdLite
    version: Version
    name: string
    ts: number
    author: string
}

export type IndexMeta = SMap<TypedOmit<CNode, "children"> & { catalogId: IdLite }>

export type Version = Brand<number, "version">
export const mkVersion = (num: number) => num as Version
export const validVersion: Validators<Version> = validNumber as any
export const mkNewer = (version: Version) => mkVersion(version + 1)

export const mkEmptyShelf = (): Shelf => ({
    meta: {
        version: mkVersion(0),
        ts: SD_ZERO_HOUR
    },
    catalogs: {}
})

export const mkTree = (nodes: TreeNode[]): Catalog | null => {
    let catalog: Catalog | null = null
    const childrenMap: SMap<TreeNode[]> = {}

    nodes.forEach(n => {
        if (n.type === "catalog" && !catalog) catalog = omitObject(n, ["order"]) as Catalog
        if (!n.parentKey) return
        if (!childrenMap[n.parentKey]) childrenMap[n.parentKey] = []
        childrenMap[n.parentKey].push(n)
    })

    if (!catalog) return null

    const attach = (n: CNode) => {
        n.children = (childrenMap[n.key] || []).sort((l, r) => l.order - r.order).map(v => omitObject(v, ["order"]))
        n.children.forEach(attach)
    }
    attach(catalog)
    return catalog
}

export const mkNodes = (nodes: CNode[]): TreeNode[] => {
    const res: TreeNode[] = []
    const travers = (ns: CNode[], parentKey?: string) =>
        ns.forEach((n, order) => {
            res.push({ ...omitObject(n, ["children"]), order, parentKey })
            travers(n.children || [], n.key)
        })
    travers(nodes)
    return res
}

export const replaceNodeKeys = <T extends CNode<any, CNode>>(node: T, genKey: F1<string, string>): T => {
    const key = genKey(node.key)
    const newNode = { ...node, key }
    if (node.children)
        newNode.children = node.children.map(child => replaceNodeKeys({ ...child, parentKey: key }, genKey))
    return newNode
}

export const merge2 = (catalog: Catalog, node: CNode) => {
    const t = mkNodes([catalog])
    const tMap = toMap(t, v => v.key, identity)

    if (!tMap[node.key]) return { catalog, cnt: t.length }
    const { parentKey } = tMap[node.key]

    const t2 = mkNodes([node])
    const t2Map = toMap(t2, v => v.key, identity)

    t2Map[node.key].order = tMap[node.key].order

    const updated = t2.filter(n => tMap[n.key]).map(n => (n.parentKey ? n : { ...n, parentKey }))
    const created = t2.filter(n => !tMap[n.key])

    const isUnchanged = (n: TreeNode) => !t2Map[n.key]
    const isRemoved = (n: TreeNode) => !t2Map[n.key] && n.parentKey && n.parentKey === node.key && t2Map[n.parentKey]

    const unchanged = t.filter(n => isUnchanged(n) && !isRemoved(n))
    const res = [...unchanged, ...created, ...updated].sort((l, r) => l.key.localeCompare(r.key))

    return { catalog: mkTree(res) || catalog, cnt: t.length }
}
export const merge = (catalog: Catalog, node: CNode) => merge2(catalog, node).catalog

export const mkCNode = (node: TreeNode, children: TreeNode[]) => ({
    ...omitObject(node, ["order"]),
    children: children.sort((l, r) => l.order - r.order).map(v => omitObject(v, ["order"]))
})

type ReorderOptions = { dropId: string; dragId: string; dropPosition: number }
export const reorderNodes = (vs: CNode[], options: ReorderOptions): CNode[] => {
    if (!vs.length) return []
    const dragged = vs.find(v => `${v.key}` === options.dragId)
    const dropOn = vs.find(v => `${v.key}` === options.dropId)
    if (!dragged || !dropOn) return vs
    const i = vs.indexOf(dropOn) + (options.dropPosition === -1 ? 0 : 1)
    const left = vs.slice(0, i)
    const left2 = left.filter(v => v.key !== dragged.key)
    const right = vs.slice(i)
    const right2 = right.filter(v => v.key !== dragged.key)
    return left2.concat([dragged]).concat(right2)
}

export const reorderNode = (n: CNode, options: ReorderOptions) => ({
    ...n,
    children: reorderNodes(n.children || [], options)
})
