import * as React from "react"
import { Button, IconButton } from "@smartdevis/ui/src/Button"
import { i18n } from "@smartdevis/client/src/services/translations"
import { useCloudAction } from "../../hooks/useCloudAction"
import { SubmitButton } from "../../components"
import { RouteParams, appPaths, projectPaths } from "../../paths"
import { ArchitectContent } from "../../components/layouts/Content"
import { FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { asyncConnect } from "../../resolvers"
import { StyledForm } from "../../components/forms"
import { getProjectContentProps } from "./Project"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import {
    getStyledAddressInfoSchema,
    getStyledProjectSchema,
    getProjectPersonaTranslation,
    mkProjectSchema,
    ProjectFormProps,
    ProjectPayload,
    sortFormsByCreatedTs,
    useProjectSubforms,
    createProjectPayload,
    getUnstructuredProjectDetails
} from "./ProjectEdit.helpers"
import styled from "styled-components"
import { Asset } from "@smartdevis/ui/src/Asset"
import { themeColor, themeConfig, themeMedia } from "@smartdevis/ui/src/utils/theme"
import { keys } from "@smartdevis/utils/src/map"
import { H4 } from "@smartdevis/ui/src/Typography"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { ProjectSubformRemoveModal } from "./ProjectsModals"
import { Domain } from "@smartdevis/server/src/domain"
import { FormState, useFormHook } from "@smartdevis/forms/src"
import { StyledFormViewProps } from "@smartdevis/forms/src/components/StyledFormView"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { matchesPath } from "@smartdevis/client/src/utils/router"
import { TemplateForm } from "./TemplateEdit"
import { isOk } from "@smartdevis/utils/src/result"
import { localStorageProjectId } from "./Dashboard"
import { StepsWrapper } from "./Devis"

const PROJECT_VERSION = 1

const Form = styled.form`
    max-width: 800px;
`

const FormActions = styled.div`
    width: 300px;
    & > * {
        margin-bottom: 10px;
    }
    position: fixed;
    right: 0px;
    top: 100px;

    ${themeMedia("max", "xxl")} {
        width: 220px;
    }
`

const ActiveHeaderWrapper = styled(FlexRow)`
    margin-bottom: 1.375rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${themeColor("grey50")};
`

const AdditionalForm = styled.div<{ active?: boolean }>`
    padding: 16px;
    background-color: ${p => (p.active ? themeColor("grey35") : "white")};
    margin-bottom: 16px;
`
const InfoWrapper = styled(StepsWrapper)`
    padding-top: 10px;
    padding-bottom: 10px;
`

const scrollToFirstError = () => {
    const errors = Array.from(document.getElementsByClassName("error")).filter(v => v.textContent)
    if (errors && errors.length) errors[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
}

const ClientFormContext = React.createContext<FormState<Domain.AddressInfo> | undefined>(undefined)

const ActiveHeader: React.FC<StyledFormViewProps<Domain.AddressInfo, any> & { value: string }> = p2 => {
    const state = React.useContext(ClientFormContext)
    const copyState = () => {
        if (!state) return
        p2.setState(state)
    }
    return (
        <ActiveHeaderWrapper spaceBetween alignCenter>
            <H4>{p2.value}</H4>
            <IconButton icon="CopyWhite" onClick={copyState} type="button" style={{ zIndex: 100 }}>
                {i18n("Copy from client")}
            </IconButton>
        </ActiveHeaderWrapper>
    )
}

const ActiveHeaderMemo = React.memo(ActiveHeader, () => true)

const ProjectForm: React.FC<ProjectFormProps> = p => {
    const [closeFormKey, setCloseFormKey] = React.useState<null | keyof Domain.ProjectDetailsOptionalAddressInfoFields>(
        null
    )
    const {
        forms,
        resultType: subformsResultType,
        value,
        closeForm,
        openForm,
        optionalSubformState,
        handleSubmitSubforms
    } = useProjectSubforms(p.projectDetails, false, p.user)

    const [isLocalDataAvailable, setLocalDataAvaialable] = React.useState(false)

    const { actionState, onSubmit } = useCloudAction<ProjectPayload>(
        (actionId, res) => {
            p.onSubmit({ ...res, ...value, actionId })
        },
        p.results,
        r =>
            isOk(r)
                ? p.onDone(
                      p.projectDetails?.projectId
                          ? p.projectDetails.projectId === localStorageProjectId
                              ? null
                              : p.projectDetails.projectId
                          : r.value
                  )
                : null //return null when project is created from localstorage as no projectId is
    )

    const { formViewProps, handleSubmit, result } = useFormHook({
        schema: mkProjectSchema(),
        onSubmit: v => {
            if ([subformsResultType, result.type].every(formResult => formResult === "Ok")) onSubmit(v)
        },
        initialValue: p.projectDetails ? p.projectDetails : { projectType: "new", version: PROJECT_VERSION }
    })

    const submitForm = (v: React.FormEvent<HTMLFormElement>) => {
        ;[handleSubmit, handleSubmitSubforms].forEach(submit => submit(v))
        holdingStateInTempStorage(v)
        if ([subformsResultType, result.type].some(formResult => formResult === "Err"))
            setTimeout(scrollToFirstError, 100)
    }

    const holdingStateInTempStorage = (v: React.FormEvent<HTMLFormElement>) => {
        setLocalDataAvaialable(true);
        localStorage.setItem(p.user.userId, JSON.stringify(getUnstructuredProjectDetails({...formViewProps.state,...value})))
    }

    const clientForm = forms.find(f => f.key === "client")
    const state = clientForm?.form.state

    return (
        <>
            {isLocalDataAvailable && (
                <InfoWrapper>
                    <FlexRow justifyCenter>
                        {i18n(`Data has been stored in the browser storage.  `)}
                        {i18n(`Feel free to move out now and continue later.   `)}
                        {/* <Asset
                        name="Download"
                        size="icon"
                        color="grey70"
                        style={{ marginLeft: "12px", cursor: "pointer" }}
                    /> */}
                    </FlexRow>
                </InfoWrapper>
            )}

            <FlexRow justifyCenter>
                <Form onSubmit={submitForm}>
                    <AdditionalForm>
                        <StyledForm {...formViewProps} styledSchema={getStyledProjectSchema()} />
                    </AdditionalForm>
                    <ClientFormContext.Provider value={state}>
                        {forms.sort(sortFormsByCreatedTs).map(f => (
                            <AdditionalForm key={f.key} active={f.optional} id={f.key}>
                                {f.optional && (
                                    <FlexRow justifyEnd style={{ width: "600px", position: "absolute" }}>
                                        <Asset
                                            name="Close"
                                            size="mid-icon"
                                            onClick={() => setCloseFormKey(f.key as any)}
                                        />
                                    </FlexRow>
                                )}
                                <StyledForm
                                    {...f.form}
                                    title={f.key}
                                    styledSchema={getStyledAddressInfoSchema(
                                        getProjectPersonaTranslation(f.key),
                                        f.key === "billing"
                                    )}
                                    styledInputsRenderMap={{ Custom: ActiveHeaderMemo }}
                                />
                            </AdditionalForm>
                        ))}
                    </ClientFormContext.Provider>
                    <FlexRow alignCenter justifyEnd>
                        <Button type="button" btnType="action" onClick={p.onCancel} data-cy="projects-cancel">
                            {i18n("Cancel")}
                        </Button>

                        <HorizontalSpace base="16px" />
                        <SubmitButton
                            data-cy="projects-submit"
                            loading={actionState.type === "Processing"}
                            disabled={actionState.type === "Processing"}
                            type="submit">
                            {i18n(p.submitText)}
                        </SubmitButton>
                    </FlexRow>
                </Form>
                <FormActions>
                    <H4>{i18n("Additional fileds")}</H4>
                    {keys(optionalSubformState)
                        .filter(k => !optionalSubformState[k])
                        .map(k => (
                            <IconButton
                                icon="CrossWhite"
                                onClick={() => {
                                    openForm(k)
                                    setTimeout(() => {
                                        const form = document.getElementById(k)
                                        if (form) form.scrollIntoView({ behavior: "smooth", block: "center" })
                                    }, 100)
                                }}
                                key={k}>
                                {getProjectPersonaTranslation(k)}
                            </IconButton>
                        ))}
                </FormActions>
            </FlexRow>
            <ProjectSubformRemoveModal
                show={!!closeFormKey}
                setShow={setCloseFormKey}
                onConfirm={() => closeFormKey && closeForm(closeFormKey)}
            />
        </>
    )
}

export const EditProjectView = asyncConnect({
    stateResolvers: ["projectsDetails", "results", "projectTemplatesDetails", "user"],
    actions: ["navigate", "mutate"]
})<RouteParams>(p => {
    const { projectId } = p.match.params
    const isPathTemplate = matchesPath(projectPaths.newTemplate.path, p.location.pathname, true)
    const { create, update } = prepareMutation("projects", p.mutate)
    const projectDetails = projectId
        ? projectId !== localStorageProjectId
            ? p.projectsDetails[projectId] || p.projectTemplatesDetails[projectId]
            : JSON.parse(localStorage.getItem(p.user.userId) || "")
        : undefined
    const shouldRenderForm = projectId ? Boolean(projectDetails) : true

    const FormComponent = isPathTemplate ? TemplateForm : ProjectForm

    return (
        <ArchitectContent
            {...(projectDetails ? getProjectContentProps(projectDetails) : {})}
            title={
                projectId
                    ? projectDetails?.isTemplate
                        ? i18n("Edit") + ` ${projectDetails?.name}`
                        : i18n("Edit project")
                    : isPathTemplate
                    ? i18n("Create template")
                    : i18n("Create project")
            }
            subTitle={projectDetails?.isTemplate ? "" : projectDetails?.name}>
            {shouldRenderForm ? (
                <>
                    <FormComponent
                        onSubmit={payload =>
                            projectId
                                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  update(projectDetails!, payload.actionId)(createProjectPayload(payload))
                                : create(
                                      {
                                          ...createProjectPayload(payload),
                                          createdTs: getTs(),
                                          projectId: genTemporaryId(),
                                          isTemplate: isPathTemplate
                                      },
                                      payload.actionId
                                  )
                        }
                        onDone={id => {localStorage.removeItem(p.user.userId);p.navigate(projectPaths.overview, { projectId: id })}}
                        onCancel={() => p.navigate(appPaths.home)}
                        results={p.results}
                        submitText={
                            projectId
                                ? i18n("Save changes")
                                : isPathTemplate
                                ? i18n("Create template {submit button}")
                                : i18n("Create project {submit button}")
                        }
                        projectDetails={projectDetails}
                        user={p.user}
                    />
                    <VerticalSpace h={32} />
                </>
            ) : (
                <Spinner />
            )}
        </ArchitectContent>
    )
})
