import { Domain } from "@smartdevis/server/src/domain"
import {
    mkFormSchema,
    mkTextSchema,
    mkSelectSchema,
    mkHiddenSchema,
    mkEmailSchema
} from "../../components/forms/formSchemas"
import { addressInfoValidation, projectDetailsValidation } from "@smartdevis/server/src/models/projectDetails"
import { getProjectTypeTranslation, i18n } from "@smartdevis/client/src/services/translations"
import { useMapState } from "../../hooks/useMapState"
import {
    FormHookResult,
    FormSchema,
    FormState,
    FormViewProps,
    StyledFormSchema,
    useFormHook
} from "@smartdevis/forms/src"
import { SMap, mapObject, TMap, keys, omitObject, pickObject, values, filterObject } from "@smartdevis/utils/src/map"
import { OkType, ErrType } from "@smartdevis/utils/src/result"
import { TypedOmit, F2, F0, F1 } from "@smartdevis/utils/src/types"
import { EventActionMeta, EventResult } from "@smartdevis/utils/src/actions"
import { getTs } from "@smartdevis/utils/src/id"
import { localStorageProjectId } from "./Dashboard"

export type ProjectPayload = TypedOmit<Domain.ProjectDetails, "projectId" | "createdTs" | "updatedTs">

export const getProjectPersonaTranslation = (k: keyof Domain.ProjectDetailsAddressInfoFields) => {
    switch (k) {
        case "client":
            return i18n("Client")
        case "planner":
            return i18n("Planner / Architect")
        case "billing":
            return i18n("Billing address")
        case "owner":
            return i18n("Owner")
        case "ownersRepresentative":
            return i18n("Owner’s representative")
        case "clientsRepresentative":
            return i18n("Client’s representative")
        case "specialistPlanner":
            return i18n("Specialist planner")
        case "specialistPlanner2":
            return i18n("Specialist planner #2")
        case "specialistPlanner3":
            return i18n("Specialist planner #3")
        case "specialistPlanner4":
            return i18n("Specialist planner #4")
        case "specialistPlanner5":
            return i18n("Specialist planner #5")
        case "constructionManagement":
            return i18n("Construction management")
        case "other":
            return i18n("Other")
    }
}

export const mkMandatory = (s: string) => `${s} *`
export const markMandatoryFields = <T extends SMap<string>>(obj: T, ks: (keyof T)[]) =>
    mapObject(obj, (k, v) => (ks.includes(k) ? mkMandatory(v) : v))

type ProjectFromFields = TypedOmit<ProjectPayload, keyof Domain.ProjectDetailsAddressInfoFields>

const getProjectFieldsTranslations = (): TMap<keyof ProjectFromFields, string> => ({
    number: i18n("Project Number"),
    name: i18n("Project Name"),
    street: i18n("Street"),
    streetNumber: i18n("Street Number"),
    postalCode: i18n("Postal code"),
    city: i18n("City"),
    abbreviation: i18n("Abbreviation"),
    projectType: i18n("Project Type"),
    isTemplate: "",
    version: "",
    contractorIds: ""
})

export const mkProjectSchemaCreator = (t: TMap<keyof ProjectFromFields, string>) =>
    mkFormSchema<ProjectFromFields>(projectDetailsValidation, {
        number: mkTextSchema(t["number"]),
        name: mkTextSchema(t["name"]),
        street: mkTextSchema(t["street"]),
        streetNumber: mkTextSchema(t["streetNumber"]),
        postalCode: mkTextSchema(t["postalCode"]),
        city: mkTextSchema(t["city"]),
        abbreviation: mkTextSchema(t["abbreviation"]),
        projectType: mkSelectSchema<Domain.ProjectType>(
            i18n("Project Type"),
            [
                [getProjectTypeTranslation("new"), "new"],
                [getProjectTypeTranslation("renovation"), "renovation"],
                [getProjectTypeTranslation("restoration"), "restoration"]
            ],
            { name: t["projectType"] }
        ),
        isTemplate: mkHiddenSchema("Is Template"),
        version: mkHiddenSchema("version")
    })

export const mkProjectSchema = () =>
    mkProjectSchemaCreator(
        markMandatoryFields(
            getProjectFieldsTranslations(),
            keys(omitObject(getProjectFieldsTranslations(), ["streetNumber"]))
        )
    )

export const mkProjectStaticSchema = () => mkProjectSchemaCreator(getProjectFieldsTranslations())

const getAddressInfoFieldsTranslations = (): TMap<keyof Domain.AddressInfo, string> => ({
    companyName: i18n("Company name"),
    companyName2: "",
    name: i18n("First name"),
    surname: i18n("Last name"),
    email: i18n("Email"),
    phoneNumber: i18n("Phone number"),
    street: i18n("Street"),
    streetNumber: i18n("Street Number"),
    postalCode: i18n("Postal code"),
    city: i18n("City"),
    createdTs: "",
    updatedTs: ""
})

export const mkAddressInfoSchemaCreator = (t: TMap<keyof Domain.AddressInfo, string>) =>
    mkFormSchema<Domain.AddressInfo>(addressInfoValidation, {
        companyName: mkTextSchema(t["companyName"]),
        companyName2: mkTextSchema(t["companyName2"], { placeholder: i18n("Company name line 2") }),
        name: mkTextSchema(t["name"]),
        surname: mkTextSchema(t["surname"]),
        email: mkEmailSchema(t["email"]),
        phoneNumber: mkTextSchema(t["phoneNumber"]),
        street: mkTextSchema(t["street"]),
        streetNumber: mkTextSchema(t["streetNumber"]),
        postalCode: mkTextSchema(t["postalCode"]),
        city: mkTextSchema(t["city"]),
        createdTs: mkHiddenSchema("")
    })

export const mkAddressInfoSchema = () =>
    mkAddressInfoSchemaCreator(
        markMandatoryFields(
            getAddressInfoFieldsTranslations(),
            keys(omitObject(getAddressInfoFieldsTranslations(), ["companyName", "companyName2", "streetNumber"]))
        )
    )

export const mkAddressInfoStaticSchema = () => mkAddressInfoSchemaCreator(getAddressInfoFieldsTranslations())

export const getStyledAddressInfoSchema: F2<string, boolean, StyledFormSchema<Domain.AddressInfo>> = (
    title,
    customTitle
) => [
    ...(customTitle ? [{ type: "Custom", value: title } as const] : [{ type: "Title", value: title } as const]),
    "companyName",
    "companyName2",
    { type: "Row", value: ["name", "surname"] },
    { type: "Row", value: ["phoneNumber", "email"] },
    { type: "Row", value: ["street", "streetNumber"] },
    { type: "Row", value: ["postalCode", "city"] }
]

export const getStyledProjectSchema: F0<StyledFormSchema<ProjectPayload>> = () => [
    { type: "Title", value: i18n("Project") },
    { type: "Row", value: ["number", "abbreviation"] },
    { type: "Row", value: ["name"] },
    { type: "Row", value: ["projectType"] },
    { type: "Row", value: ["street", "streetNumber"] },
    { type: "Row", value: ["postalCode", "city"] }
]

export const getStyledDisplayProjectSchema: F0<StyledFormSchema<ProjectPayload>> = () => [
    { type: "Row", value: ["number", "abbreviation"] },
    { type: "Row", value: ["projectType"] },
    { type: "Row", value: ["street", "streetNumber"] },
    { type: "Row", value: ["postalCode", "city"] }
]

export type ProjectFormProps = {
    results: SMap<EventResult>
    projectDetails?: Domain.ProjectDetails
    user: Domain.User
    submitText: string
    onSubmit: F1<EventActionMeta & ProjectPayload>
    onDone: F1<string>
    onCancel: F0
}

export type OptionalFormsMap = TMap<keyof Domain.ProjectDetailsOptionalAddressInfoFields, boolean>

type NamedForm<T> = {
    form: FormViewProps<any>
    key: T
    optional: boolean
}

export const useAddressInfoForm = (
    schema: FormSchema<Domain.AddressInfo>,
    key: keyof Domain.ProjectDetailsAddressInfoFields,
    pd?: ProjectPayload
) =>
    useFormHook({
        schema,
        initialValue: (pd && pd[key]) || {},
        title: key
    })

export const mkRequiredForm = (
    key: keyof Domain.ProjectDetailsRequiredAddressInfoFields,
    form: FormViewProps<any>
): NamedForm<keyof Domain.ProjectDetailsRequiredAddressInfoFields> => ({
    key,
    form,
    optional: false
})

export const mkOptionalForm = (
    key: keyof Domain.ProjectDetailsOptionalAddressInfoFields,
    form: FormViewProps<any>
): NamedForm<keyof Domain.ProjectDetailsOptionalAddressInfoFields> => ({
    form,
    key,
    optional: true
})

const optionalFormKeys: (keyof Domain.ProjectDetailsOptionalAddressInfoFields)[] = [
    "owner",
    "ownersRepresentative",
    "clientsRepresentative",
    "specialistPlanner",
    "specialistPlanner2",
    "specialistPlanner3",
    "specialistPlanner4",
    "specialistPlanner5",
    "constructionManagement",
    "other"
]

export const useProjectSubforms = (
    pd?: ProjectPayload,
    staticView?: boolean,
    user?: Domain.User
): {
    forms: NamedForm<keyof Domain.ProjectDetailsAddressInfoFields>[]
    resultType: OkType | ErrType
    value: Domain.ProjectDetailsAddressInfoFields
    closeForm: F1<keyof Domain.ProjectDetailsOptionalAddressInfoFields>
    openForm: F1<keyof Domain.ProjectDetailsOptionalAddressInfoFields>
    optionalSubformState: OptionalFormsMap
    handleSubmitSubforms: F0
} => {
    const initialOptionalSubformState = Object.fromEntries(
        optionalFormKeys.map(k => [k, !!pd && !!pd[k]])
    ) as OptionalFormsMap

    const userAddress: Partial<Domain.AddressInfo> = user
        ? pickObject(user, [
              "name",
              "surname",
              "email",
              "phoneNumber",
              "postalCode",
              "city",
              "street",
              "streetNumber",
              "companyName"
          ])
        : {}

    const [optionalSubformState, , setSubform] = useMapState<OptionalFormsMap>(initialOptionalSubformState)
    const schema = staticView ? mkAddressInfoStaticSchema() : mkAddressInfoSchema()
    const requiredForms: TMap<
        keyof Domain.ProjectDetailsRequiredAddressInfoFields,
        FormHookResult<Domain.AddressInfo>
    > = {
        planner: useAddressInfoForm(schema, "planner", pd || ({ planner: userAddress } as ProjectPayload)),
        client: useAddressInfoForm(schema, "client", pd),
        billing: useAddressInfoForm(schema, "billing", pd)
    }

    const optionalForms: TMap<
        keyof Domain.ProjectDetailsOptionalAddressInfoFields,
        FormHookResult<Domain.AddressInfo>
    > = {
        owner: useAddressInfoForm(schema, "owner", pd),
        ownersRepresentative: useAddressInfoForm(schema, "ownersRepresentative", pd),
        clientsRepresentative: useAddressInfoForm(schema, "clientsRepresentative", pd),
        specialistPlanner: useAddressInfoForm(schema, "specialistPlanner", pd),
        specialistPlanner2: useAddressInfoForm(schema, "specialistPlanner2", pd),
        specialistPlanner3: useAddressInfoForm(schema, "specialistPlanner3", pd),
        specialistPlanner4: useAddressInfoForm(schema, "specialistPlanner4", pd),
        specialistPlanner5: useAddressInfoForm(schema, "specialistPlanner5", pd),
        constructionManagement: useAddressInfoForm(schema, "constructionManagement", pd),
        other: useAddressInfoForm(schema, "other", pd)
    }

    const isOk = [
        ...values(requiredForms).map(f => f.result.type),
        ...keys(optionalForms).map(k => (optionalSubformState[k] ? optionalForms[k].result.type : "Ok"))
    ].every(s => s === "Ok")

    const optionalFormsList = optionalFormKeys.map(k =>
        optionalSubformState[k] ? [k, optionalForms[k].result.value] : [k, null]
    )

    const optionalFormsListForTemp = optionalFormKeys.map(k =>
        optionalSubformState[k] ? [k, optionalForms[k].formViewProps.state] : [k, null]
    )

    const closeForm = (k: keyof Domain.ProjectDetailsOptionalAddressInfoFields) => setSubform({ [k]: false })
    const openForm = (k: keyof Domain.ProjectDetailsOptionalAddressInfoFields) => {
        setSubform({ [k]: true })
        optionalForms[k].formViewProps.setState({
            ...optionalForms[k].formViewProps.state,
            createdTs: { value: getTs(), active: false, visited: true }
        })
    }

    const handleSubmitSubforms = () =>
        [
            ...values(requiredForms),
            ...values(
                filterObject(
                    optionalForms,
                    k => optionalSubformState[k as keyof Domain.ProjectDetailsOptionalAddressInfoFields]
                )
            )
        ].forEach(f => f.handleSubmit())

    return {
        forms: [
            ...keys(requiredForms).map(k => mkRequiredForm(k, requiredForms[k].formViewProps)),
            ...keys(optionalForms)
                .map(k => mkOptionalForm(k, optionalForms[k].formViewProps))
                .filter(f => optionalSubformState[f.key])
        ],
        resultType: isOk ? "Ok" : "Err",
        value: isOk
            ? {
                  ...mapObject(requiredForms, (_k, v) => v.result.value),
                  ...Object.fromEntries(optionalFormsList)
              }
            : {
                ...mapObject(requiredForms, (_k, v) => v.formViewProps.state),
                ...Object.fromEntries(optionalFormsListForTemp)
            },
        optionalSubformState,
        handleSubmitSubforms,
        closeForm,
        openForm
    }
}

export const sortFormsByCreatedTs = (
    a: NamedForm<keyof Domain.ProjectDetailsAddressInfoFields>,
    b: NamedForm<keyof Domain.ProjectDetailsAddressInfoFields>
) => {
    const ts1 = (a.form.state as FormState<Domain.AddressInfo>).createdTs.value || 0
    const ts2 = (b.form.state as FormState<Domain.AddressInfo>).createdTs.value || 0
    return ts1 - ts2
}

export const createProjectPayload = (pd: ProjectPayload): ProjectPayload => {
    let retPd = { ...pd }

    retPd.street = `${pd.street}${pd.streetNumber?.length && pd.streetNumber !== "-" ? " " + pd.streetNumber : ""}`
    if (pd.billing)
        retPd.billing = {
            ...pd.billing,
            street: `${pd.billing.street}${
                pd.billing.streetNumber?.length && pd.billing.streetNumber !== "-" ? " " + pd.billing.streetNumber : ""
            }`
        }
    if (pd.client)
        retPd.client = {
            ...pd.client,
            street: `${pd.client.street}${
                pd.client.streetNumber?.length && pd.client.streetNumber !== "-" ? " " + pd.client.streetNumber : ""
            }`
        }
    if (pd.clientsRepresentative)
        retPd.clientsRepresentative = {
            ...pd.clientsRepresentative,
            street: `${pd.clientsRepresentative.street}${
                pd.clientsRepresentative.streetNumber?.length && pd.clientsRepresentative.streetNumber !== "-"
                    ? " " + pd.clientsRepresentative.streetNumber
                    : ""
            }`
        }
    if (pd.constructionManagement)
        retPd.constructionManagement = {
            ...pd.constructionManagement,
            street: `${pd.constructionManagement.street}${
                pd.constructionManagement.streetNumber?.length && pd.constructionManagement.streetNumber !== "-"
                    ? " " + pd.constructionManagement.streetNumber
                    : ""
            }`
        }
    if (pd.other)
        retPd.other = {
            ...pd.other,
            street: `${pd.other.street}${
                pd.other.streetNumber?.length && pd.other.streetNumber !== "-" ? " " + pd.other.streetNumber : ""
            }`
        }
    if (pd.owner)
        retPd.owner = {
            ...pd.owner,
            street: `${pd.owner.street}${
                pd.owner.streetNumber?.length && pd.owner.streetNumber !== "-" ? " " + pd.owner.streetNumber : ""
            }`
        }
    if (pd.ownersRepresentative)
        retPd.ownersRepresentative = {
            ...pd.ownersRepresentative,
            street: `${pd.ownersRepresentative.street}${
                pd.ownersRepresentative.streetNumber?.length && pd.ownersRepresentative.streetNumber !== "-"
                    ? " " + pd.ownersRepresentative.streetNumber
                    : ""
            }`
        }
    if (pd.planner)
        retPd.planner = {
            ...pd.planner,
            street: `${pd.planner.street}${
                pd.planner.streetNumber?.length && pd.planner.streetNumber !== "-" ? " " + pd.planner.streetNumber : ""
            }`
        }
    if (pd.specialistPlanner)
        retPd.specialistPlanner = {
            ...pd.specialistPlanner,
            street: `${pd.specialistPlanner.street}${
                pd.specialistPlanner.streetNumber?.length && pd.specialistPlanner.streetNumber !== "-"
                    ? " " + pd.specialistPlanner.streetNumber
                    : ""
            }`
        }
    if (pd.specialistPlanner2)
        retPd.specialistPlanner2 = {
            ...pd.specialistPlanner2,
            street: `${pd.specialistPlanner2.street}${
                pd.specialistPlanner2.streetNumber?.length && pd.specialistPlanner2.streetNumber !== "-"
                    ? " " + pd.specialistPlanner2.streetNumber
                    : ""
            }`
        }
    if (pd.specialistPlanner3)
        retPd.specialistPlanner3 = {
            ...pd.specialistPlanner3,
            street: `${pd.specialistPlanner3.street}${
                pd.specialistPlanner3.streetNumber?.length && pd.specialistPlanner3.streetNumber !== "-"
                    ? " " + pd.specialistPlanner3.streetNumber
                    : ""
            }`
        }
    if (pd.specialistPlanner4)
        retPd.specialistPlanner4 = {
            ...pd.specialistPlanner4,
            street: `${pd.specialistPlanner4.street}${
                pd.specialistPlanner4.streetNumber?.length && pd.specialistPlanner4.streetNumber !== "-"
                    ? " " + pd.specialistPlanner4.streetNumber
                    : ""
            }`
        }
    if (pd.specialistPlanner5)
        retPd.specialistPlanner5 = {
            ...pd.specialistPlanner5,
            street: `${pd.specialistPlanner5.street}${
                pd.specialistPlanner5.streetNumber?.length && pd.specialistPlanner5.streetNumber !== "-"
                    ? " " + pd.specialistPlanner5.streetNumber
                    : ""
            }`
        }

    return retPd
}

export const getUnstructuredProjectDetails = (subFormDetails: any) => {
    const output: Domain.ProjectDetails = {
        planner: {
            companyName: subFormDetails.planner.companyName.value,
            companyName2: subFormDetails.planner.companyName2.value,
            name: subFormDetails.planner.name.value,
            surname: subFormDetails.planner.surname.value,
            email: subFormDetails.planner.email?.value ?? null,
            phoneNumber: subFormDetails.planner.phoneNumber.value,
            street: subFormDetails.planner.street.value,
            streetNumber: subFormDetails.planner.streetNumber.value,
            postalCode: subFormDetails.planner.postalCode.value,
            city: subFormDetails.planner.city.value,
            createdTs: subFormDetails.planner.createdTs.value
        },
        client: {
            companyName: subFormDetails.client.companyName.value,
            companyName2: subFormDetails.client.companyName2.value,
            name: subFormDetails.client.name?.value ?? null,
            surname: subFormDetails.client.surname.value,
            email: subFormDetails.client.email.value,
            phoneNumber: subFormDetails.client.phoneNumber.value,
            street: subFormDetails.client.street.value,
            streetNumber: subFormDetails.client.streetNumber.value,
            postalCode: subFormDetails.client.postalCode.value,
            city: subFormDetails.client.city.value,
            createdTs: subFormDetails.client.createdTs.value
        },
        billing: {
            companyName: subFormDetails.billing.companyName.value,
            companyName2: subFormDetails.billing.companyName2.value,
            name: subFormDetails.billing.name.value,
            surname: subFormDetails.billing.surname.value,
            email: subFormDetails.billing.email.value,
            phoneNumber: subFormDetails.billing.phoneNumber.value,
            street: subFormDetails.billing.street.value,
            streetNumber: subFormDetails.billing.streetNumber.value,
            postalCode: subFormDetails.billing.postalCode.value,
            city: subFormDetails.billing.city.value,
            createdTs: subFormDetails.billing.createdTs.value
        },
        owner: subFormDetails.owner
            ? {
                  companyName: subFormDetails.owner?.companyName?.value,
                  companyName2: subFormDetails.owner?.companyName2?.value,
                  name: subFormDetails.owner?.name?.value,
                  surname: subFormDetails.owner?.surname?.value,
                  email: subFormDetails.owner?.email?.value,
                  phoneNumber: subFormDetails.owner?.phoneNumber?.value,
                  street: subFormDetails.owner?.street?.value,
                  streetNumber: subFormDetails.owner?.streetNumber?.value,
                  postalCode: subFormDetails.owner?.postalCode?.value,
                  city: subFormDetails.owner?.city?.value,
                  createdTs: subFormDetails.owner?.createdTs?.value
              }
            : null,
        ownersRepresentative: subFormDetails.ownersRepresentative
            ? {
                  companyName: subFormDetails.ownersRepresentative?.companyName?.value,
                  companyName2: subFormDetails.ownersRepresentative?.companyName2?.value,
                  name: subFormDetails.ownersRepresentative.name.value,
                  surname: subFormDetails.ownersRepresentative.surname.value,
                  email: subFormDetails.ownersRepresentative.email.value,
                  phoneNumber: subFormDetails.ownersRepresentative.phoneNumber.value,
                  street: subFormDetails.ownersRepresentative.street.value,
                  streetNumber: subFormDetails.ownersRepresentative?.streetNumber?.value,
                  postalCode: subFormDetails.ownersRepresentative.postalCode.value,
                  city: subFormDetails.ownersRepresentative.city.value,
                  createdTs: subFormDetails.ownersRepresentative?.createdTs?.value
              }
            : null,
        clientsRepresentative: subFormDetails.clientsRepresentative
            ? {
                  companyName: subFormDetails.clientsRepresentative?.companyName?.value,
                  companyName2: subFormDetails.clientsRepresentative?.companyName2?.value,
                  name: subFormDetails.clientsRepresentative.name.value,
                  surname: subFormDetails.clientsRepresentative.surname.value,
                  email: subFormDetails.clientsRepresentative.email.value,
                  phoneNumber: subFormDetails.clientsRepresentative.phoneNumber.value,
                  street: subFormDetails.clientsRepresentative.street.value,
                  streetNumber: subFormDetails.clientsRepresentative?.streetNumber?.value,
                  postalCode: subFormDetails.clientsRepresentative.postalCode.value,
                  city: subFormDetails.clientsRepresentative.city.value,
                  createdTs: subFormDetails.clientsRepresentative?.createdTs?.value
              }
            : null,
        specialistPlanner: subFormDetails.specialistPlanner
            ? {
                  companyName: subFormDetails.specialistPlanner?.companyName?.value,
                  companyName2: subFormDetails.specialistPlanner?.companyName2?.value,
                  name: subFormDetails.specialistPlanner.name.value,
                  surname: subFormDetails.specialistPlanner.surname.value,
                  email: subFormDetails.specialistPlanner.email.value,
                  phoneNumber: subFormDetails.specialistPlanner.phoneNumber.value,
                  street: subFormDetails.specialistPlanner.street.value,
                  streetNumber: subFormDetails.specialistPlanner?.streetNumber?.value,
                  postalCode: subFormDetails.specialistPlanner.postalCode.value,
                  city: subFormDetails.specialistPlanner.city.value,
                  createdTs: subFormDetails.specialistPlanner?.createdTs?.value
              }
            : null,
        specialistPlanner2: subFormDetails.specialistPlanner2
            ? {
                  companyName: subFormDetails.specialistPlanner2?.companyName?.value,
                  companyName2: subFormDetails.specialistPlanner2?.companyName2?.value,
                  name: subFormDetails.specialistPlanner2.name.value,
                  surname: subFormDetails.specialistPlanner2.surname.value,
                  email: subFormDetails.specialistPlanner2.email.value,
                  phoneNumber: subFormDetails.specialistPlanner2.phoneNumber.value,
                  street: subFormDetails.specialistPlanner2.street.value,
                  streetNumber: subFormDetails.specialistPlanner2?.streetNumber?.value,
                  postalCode: subFormDetails.specialistPlanner2.postalCode.value,
                  city: subFormDetails.specialistPlanner2.city.value,
                  createdTs: subFormDetails.specialistPlanner2?.createdTs?.value
              }
            : null,
        specialistPlanner3: subFormDetails.specialistPlanner3
            ? {
                  companyName: subFormDetails.specialistPlanner3?.companyName?.value,
                  companyName2: subFormDetails.specialistPlanner3?.companyName2?.value,
                  name: subFormDetails.specialistPlanner3.name.value,
                  surname: subFormDetails.specialistPlanner3.surname.value,
                  email: subFormDetails.specialistPlanner3.email.value,
                  phoneNumber: subFormDetails.specialistPlanner3.phoneNumber.value,
                  street: subFormDetails.specialistPlanner3.street.value,
                  streetNumber: subFormDetails.specialistPlanner3?.streetNumber?.value,
                  postalCode: subFormDetails.specialistPlanner3.postalCode.value,
                  city: subFormDetails.specialistPlanner3.city.value,
                  createdTs: subFormDetails.specialistPlanner3?.createdTs?.value
              }
            : null,
        specialistPlanner4: subFormDetails.specialistPlanner4
            ? {
                  companyName: subFormDetails.specialistPlanner4?.companyName?.value,
                  companyName2: subFormDetails.specialistPlanner4?.companyName2?.value,
                  name: subFormDetails.specialistPlanner4.name.value,
                  surname: subFormDetails.specialistPlanner4.surname.value,
                  email: subFormDetails.specialistPlanner4.email.value,
                  phoneNumber: subFormDetails.specialistPlanner4.phoneNumber.value,
                  street: subFormDetails.specialistPlanner4.street.value,
                  streetNumber: subFormDetails.specialistPlanner4?.streetNumber?.value,
                  postalCode: subFormDetails.specialistPlanner4.postalCode.value,
                  city: subFormDetails.specialistPlanner4.city.value,
                  createdTs: subFormDetails.specialistPlanner4?.createdTs?.value
              }
            : null,
        specialistPlanner5: subFormDetails.specialistPlanner5
            ? {
                  companyName: subFormDetails.specialistPlanner5?.companyName?.value,
                  companyName2: subFormDetails.specialistPlanner5?.companyName2?.value,
                  name: subFormDetails.specialistPlanner5.name.value,
                  surname: subFormDetails.specialistPlanner5.surname.value,
                  email: subFormDetails.specialistPlanner5.email.value,
                  phoneNumber: subFormDetails.specialistPlanner5.phoneNumber.value,
                  street: subFormDetails.specialistPlanner5.street.value,
                  streetNumber: subFormDetails.specialistPlanner5?.streetNumber?.value,
                  postalCode: subFormDetails.specialistPlanner5.postalCode.value,
                  city: subFormDetails.specialistPlanner5.city.value,
                  createdTs: subFormDetails.specialistPlanner5?.createdTs?.value
              }
            : null,
        constructionManagement: subFormDetails.constructionManagement
            ? {
                  companyName: subFormDetails.constructionManagement?.companyName?.value,
                  companyName2: subFormDetails.constructionManagement?.companyName2?.value,
                  name: subFormDetails.constructionManagement.name.value,
                  surname: subFormDetails.constructionManagement.surname.value,
                  email: subFormDetails.constructionManagement.email.value,
                  phoneNumber: subFormDetails.constructionManagement.phoneNumber.value,
                  street: subFormDetails.constructionManagement.street.value,
                  streetNumber: subFormDetails.constructionManagement?.streetNumber?.value,
                  postalCode: subFormDetails.constructionManagement.postalCode.value,
                  city: subFormDetails.constructionManagement.city.value,
                  createdTs: subFormDetails.constructionManagement?.createdTs?.value
              }
            : null,
        other: subFormDetails.other
            ? {
                  companyName: subFormDetails.other?.companyName?.value,
                  companyName2: subFormDetails.other?.companyName2?.value,
                  name: subFormDetails.other.name.value,
                  surname: subFormDetails.other.surname.value,
                  email: subFormDetails.other.email.value,
                  phoneNumber: subFormDetails.other.phoneNumber.value,
                  street: subFormDetails.other.street.value,
                  streetNumber: subFormDetails.other?.streetNumber?.value,
                  postalCode: subFormDetails.other.postalCode.value,
                  city: subFormDetails.other.city.value,
                  createdTs: subFormDetails.other?.createdTs?.value
              }
            : null,
        number: subFormDetails.number.value,
        city: subFormDetails.city.value,
        postalCode: subFormDetails.postalCode.value,
        street: subFormDetails.street.value,
        name: subFormDetails.name.value,
        version: subFormDetails.version.value,
        projectType: subFormDetails.projectType.value,
        abbreviation: subFormDetails.abbreviation.value,
        projectId: localStorageProjectId,
        updatedTs: new Date().getTime(),
        createdTs: new Date().getTime() 
    }
    return output
}