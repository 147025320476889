import * as React from "react"
import { v4 } from "uuid"
import styled from "styled-components"

import { CatalogRef } from "@smartdevis/server/src/domain"
import {
    Catalog,
    CNode,
    CNodeType,
    merge2,
    mkNodes,
    mkCNode,
    reorderNode,
    CWorkTitle,
    replaceNodeKeys
} from "@smartdevis/server/src/domainCatalog"

import { renderMaps } from "../../components/forms/formRenderers"
import { mockedCatalog, emptyCatalog } from "./mocked"
import { FileButton } from "../../components/FileButton"
import * as catalogService from "./CatalogService"
import { themeConfig } from "@smartdevis/ui/src/utils/theme"
import { CatalogItemForm } from "../architect/devis-catalogs/devis-catalogs-details"
import { VerticalSpace, StickyContainer } from "@smartdevis/ui/src/utils/common"
import { mkCatalogRef } from "../../utils/catalogs"
import { asyncConnect } from "../../resolvers"
import { RouteParams } from "../../paths"
import { mkFormSchema, mkTextareaSchema, mkStyledCustom } from "../../components/forms/formSchemas"
import { i18n } from "../../services/translations"
import { usePublishCatalogAction } from "../../hooks/usePublishCatalogAction"
import { AppHeader, PageHeader } from "../../components/layouts/Header"
import { Button } from "@smartdevis/ui/src/Button"
import { Modal } from "@smartdevis/ui/src/Modal"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { H4 } from "@smartdevis/ui/src/Typography"
import { CatalogFormAttributeType } from "../architect/devis-catalogs/devis-catalogs"
import { FormSchema, useFormHook, StyledFormSchema } from "@smartdevis/forms/src"
import { FormView } from "@smartdevis/forms/src/components/FormView"
import { TMap } from "@smartdevis/utils/src/map"
import { _noop, identity } from "@smartdevis/utils/src/misc"
import { labelize } from "@smartdevis/utils/src/text"
import { F1 } from "@smartdevis/utils/src/types"
import { validString } from "@smartdevis/utils/src/validators"
import { IdLite } from "@smartdevis/utils/src/id"
import { Popconfirm } from "@smartdevis/ui/src/Popconfirm"
import Tree from "rc-tree"
import "rc-tree/assets/index.less"
import { NodeDragEventParams } from "rc-tree/lib/contextTypes"

export const search = (v: Catalog, key: string): CNode | null => {
    if (key === v.key) return v
    let res: CNode | null = null
    const _search = (data: CNode[]) =>
        data.forEach(n => {
            if (n.key === key) res = n
            if (res) return
            _search(n.children || [])
        })

    _search(v.children || [])
    return res
}

const childrenType: TMap<CNodeType, CNodeType> = {
    catalog: "division",
    division: "position",
    position: "workTitle",
    workTitle: "attribute",
    attribute: "value",
    value: "attribute"
}

const nodeSchemaChild: FormSchema<CNode> = {
    title: { name: "title", type: "textarea", validators: validString },
    key: { type: "hidden" },
    type: { type: "hidden" },
    payload: { type: "hidden" },
    parentKey: { type: "hidden" }
}

// TODO: formless -> { type: "object", name: "my object", fields: { b2: { type: "text", name: "b2 name" } } }

const useSchema = (initialValue: CNode) => {
    const { type } = initialValue
    const childType = childrenType[type]
    const childName = labelize(childType).toLocaleLowerCase()

    const schema: FormSchema<CNode> = {
        ...nodeSchemaChild,

        children: {
            type: "collection",
            mutate: {
                addFirstLabel: `Add ${childName}`,
                addNextLabel: `Add next ${childName}`,
                createValue: () => ({ title: "", key: v4(), type: childType })
            },
            sectionTitle: `${labelize(childType)}s`,
            fields: nodeSchemaChild
        }
    }

    const formHook = useFormHook({ schema, initialValue })
    return { ...formHook, title: labelize(type) }
}

export const NodeForm: React.FC<{ node: CNode; onChange: F1<CNode> }> = p => {
    const { formViewProps, resetState, result, title } = useSchema(p.node)
    React.useEffect(() => resetState(), [p.node])
    React.useEffect(() => {
        if (result.type === "Ok") p.onChange(result.value)
    }, [result])
    return (
        <>
            <H4>{title}</H4>
            <FormView {...formViewProps} {...renderMaps} />
        </>
    )
}

const CatalogWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${themeConfig("headerHeight")};
    width: 100%;
    height: calc(100vh - ${themeConfig("headerHeight")});
    background: white;
`
const FormPanel = styled.div`
    border-right: 1px solid rgba(0, 0, 0, 0.07);
    padding: 5px 0;
    height: 100%;
    flex-shrink: 0;
    overflow-y: scroll;
    width: 420px;
    & > div {
        margin: 10px 20px;
        margin-left: 10px;
        margin-bottom: 32px;
    }
`

const TreePanel = styled.div`
    background: white;
    position: relative;
    width: 100%;
    height: calc(100vh - ${themeConfig("headerHeight")});
    overflow: scroll;
    padding: 10px 0 ${themeConfig("contentPadding")};
`

export const CatalogView: React.FC<RouteParams> = asyncConnect({
    stateResolvers: ["catalogFull", "publishResults", "catalogMeta", "shelfType"],
    actions: ["publishCatalog", "navigate", "navigateToNextCatalog"]
})(p => {
    const { catalogId, shelfVersion } = p.catalogMeta
    const initailCatalog = p.catalogFull as Catalog
    const initialKey = initailCatalog.key

    const [catalog, setCatalogRaw] = React.useState(initailCatalog)

    const [key, setKeyRaw] = React.useState<string>(initialKey)
    const [subTree, setSubTree] = React.useState(search(initailCatalog, initialKey))
    const [expandedKeys, setExpandedKeys] = React.useState([initialKey])
    const [workTitlePreview, setWorkTitlePreview] = React.useState<CWorkTitle | null>(null)

    const setKey = (k: string) => {
        setKeyRaw(k)
        setSubTree(search(catalog, k))
        setExpandedKeys([...expandedKeys, k])
    }

    const setCatalog = (v: Catalog) => {
        catalogService.set(v)
        setCatalogRaw(v)
    }

    const update = (v: Catalog, resetKey?: true) => {
        setCatalog(v)
        if (resetKey) setKeyRaw(v.key)
        const k = resetKey ? v.key : key
        setSubTree(k ? search(v, k) : null)
    }

    const onChange = (n: CNode) => {
        const res = merge2(catalog, n)
        setCatalog(res.catalog)
    }

    const nodeForm = React.useMemo(() => (!key || !subTree ? null : <NodeForm node={subTree} onChange={onChange} />), [
        key,
        subTree
    ])

    const onDrop = (e: NodeDragEventParams & { dropPosition: number; dragNode: { key: string } }) => {
        console.log({ e })
        const dropPos = e.node.pos.split("-")
        const index = Number(dropPos[dropPos.length - 1])
        const dropPosition = e.dropPosition - index
        const dragKey = e.dragNode.key || ""
        const ft = mkNodes([catalog])
        const draggedNode = ft.find(n => n.key === dragKey)
        if (!draggedNode) return
        const node = ft.find(n => n.key === draggedNode.parentKey)
        if (!node) return
        const vs = ft.filter(n => n.parentKey === draggedNode.parentKey)
        const options = { dropId: e.node.key || "", dragId: dragKey, dropPosition }
        const v = mkCNode(node, vs)
        if (!v) return
        const result = reorderNode(v, options as any)
        const res = merge2(catalog, result)
        update(res.catalog)
    }

    const onLoadDemo = () => update(mockedCatalog, true)

    const { publish, actionState } = usePublishCatalogAction(p.publishCatalog, p.publishResults)
    const onPublish = () => {
        const blob = new Blob([JSON.stringify(catalog)], { type: "application/json" })
        publish({ blob, catalogId, shelfType: p.shelfType })
    }
    if (actionState.type === "Done") p.navigateToNextCatalog(catalogId, shelfVersion, p.shelfType)

    const onReset = () => update(emptyCatalog, true)

    const [exportState, setExportState] = React.useState<catalogService.ExportState>({ download: "", href: "" })
    const onExport = () => setExportState(catalogService.toJSON(catalog))
    const onLoad = (f: File) =>
        catalogService.fromJSON(f).then(newCatalog =>
            update(
                replaceNodeKeys(newCatalog, () => v4()),
                true
            )
        )

    return (
        <>
            <AppHeader backTo="admin" title="Admin" subTitle={i18n("Catalog editor")} path={p.location.pathname} />
            <CatalogWrapper>
                <FormPanel>
                    <div>
                        {nodeForm}
                        <VerticalSpace base="16px" />
                        {subTree?.type === "workTitle" && (
                            <Button onClick={() => setWorkTitlePreview(subTree as CWorkTitle)}>
                                {i18n("Show me how it looks for user")}
                            </Button>
                        )}
                    </div>
                </FormPanel>
                <TreePanel>
                    <StickyContainer top={0}>
                        <PageHeader
                            noBottomMargin
                            title=" "
                            actionButtons={[
                                <Popconfirm
                                    key="publish"
                                    direction="bottom"
                                    title="Are you sure you want to publish catalog?"
                                    onConfirm={onPublish}
                                    disabled={actionState.type === "Processing"}>
                                    <Button btnType="primary" disabled={actionState.type === "Processing"}>
                                        {actionState.type !== "Processing" ? i18n("Publish") : i18n("Processing")}
                                    </Button>
                                </Popconfirm>,
                                <Popconfirm
                                    key="demo"
                                    direction="bottom"
                                    title="Are you sure you want to load demo?"
                                    onConfirm={onLoadDemo}>
                                    <Button btnType="action">{i18n("Load demo")}</Button>
                                </Popconfirm>,
                                <Popconfirm
                                    key="reset"
                                    direction="bottom"
                                    title="Are you sure you want to reset?"
                                    onConfirm={onReset}>
                                    <Button btnType="primary">{i18n("Reset")}</Button>
                                </Popconfirm>,

                                <Button onClick={onExport} key="2" btnType="action">
                                    <a {...exportState}>{i18n("Export")}</a>
                                </Button>,
                                <FileButton key="3" onChange={onLoad} accept="application/JSON">
                                    {i18n("Load")}
                                </FileButton>
                            ]}
                        />
                    </StickyContainer>
                    {actionState.type === "Processing" ? (
                        <Spinner />
                    ) : (
                        <div>
                            <Tree
                                selectedKeys={[key]}
                                onSelect={v => setKey(v[0] as string)}
                                onExpand={k => setExpandedKeys(k as string[])}
                                expandedKeys={expandedKeys}
                                draggable
                                onDrop={onDrop as any}
                                showLine
                                treeData={[catalog]}
                                showIcon
                            />
                        </div>
                    )}
                </TreePanel>
                <Modal
                    size="m"
                    height="80vh"
                    header="Work Title preview"
                    visible={Boolean(workTitlePreview)}
                    onClose={() => setWorkTitlePreview(null)}>
                    {workTitlePreview && (
                        <CatalogItemForm
                            previewTitle=""
                            shelfType={p.shelfType}
                            onCopy={_noop}
                            getItemId={i => i.name}
                            onAddToList={_ => setWorkTitlePreview(null)}
                            onUpdate={_ => setWorkTitlePreview(null)}
                            item={mkCatalogPreview({} as any, "" as any, workTitlePreview)}
                            mkNewItem={i => i}
                            mkCopyItem={i => i}
                            workTitle={workTitlePreview}
                            mkInitial={identity}
                            attachCatalog={mkCatalogPreview}
                            mkSchema={mkPreviewSchema}
                            itemsToPreview={{}}
                            renderHeader={() => <React.Fragment />}
                            renderItem={() => <React.Fragment />}
                            mkStyledSchema={mkPreviewStyledSchema}
                            previewMode
                            isEditing
                        />
                    )}
                </Modal>
            </CatalogWrapper>
        </>
    )
})

type PreviewModel = { name: string; catalogRef?: CatalogRef | null }
type PreviewModelPayload = Pick<PreviewModel, "name">
const mkPreviewSchema = () => mkFormSchema<PreviewModelPayload>({} as any, { name: mkTextareaSchema(i18n("Name")) })

const mkPreviewStyledSchema = (): StyledFormSchema<PreviewModelPayload, CatalogFormAttributeType> => [
    { type: "Title", value: i18n("Details") },
    "name",
    mkStyledCustom("preset" as const), // This tells formless to use custom renderer (customRenderer func.)
    mkStyledCustom("custom" as const) // This tells formless to use custom renderer (customRenderer func.)
]

export const mkPreview = (): PreviewModel => ({ name: "" })

export const mkCatalogPreview = (m: PreviewModel, catalogId: IdLite, workTitle: CWorkTitle): PreviewModel => ({
    ...m,
    name: workTitle.title,
    catalogRef: mkCatalogRef(catalogId, workTitle)
})
