import { i18n } from "@smartdevis/client/src/services/translations"
import { matchesPath } from "@smartdevis/client/src/utils/router"
import { DA_MESSAGES } from "@smartdevis/server/src/dataAccess/utils"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { matchPattern, _noop } from "@smartdevis/utils/src/misc"
import { mkErr } from "@smartdevis/utils/src/result"
import { F0, F1 } from "@smartdevis/utils/src/types"

import { mkValidator, optional, validateMemberOf, validEmail } from "@smartdevis/utils/src/validators"
import Keycloak from "keycloak-js"
import * as React from "react"
import { AppLayout } from "../components/layouts/AppLayout"
import { EmptyContent } from "../components/layouts/Content"
import { contractorPaths } from "../paths"
import { asyncConnect } from "../resolvers"
import { KeycloakUser } from "../store/authState"
import { getProcessEnv } from "../utils/envHelpers"
import { ErrorView } from "./Error"
import { RegisterView } from "./Register"

const validateKeycloakUser = mkValidator<KeycloakUser>({
    email: validEmail,
    type: [
        optional<KeycloakUser["type"]>("ARCHITECT")(
            validateMemberOf([
                "ARCHITECT",
                "CONTRACTOR",
                "ADMIN",
                "WD_MODERATOR",
                "CD_MODERATOR",
                "architect",
                "contractor",
                "admin"
            ])
        )
    ]
})

type AuthContext = {
    logout: F0
    manageCredentials: F0
    register: F1<{ email?: string }>
    getAdminUrl: F0<string>
}
export const AuthContext = React.createContext<AuthContext>({
    logout: _noop,
    register: _noop,
    manageCredentials: _noop,
    getAdminUrl: _noop
})
export const useAuth = () => {
    return React.useContext(AuthContext)
}

export const AuthProvider = asyncConnect({
    stateResolvers: ["auth", "asyncUser"],
    actions: ["navigate", "fetchUserData", "register", "_setAuth", "_flushState", "fetchAppChangelog", "updateUser"]
})(p => {
    const keycloakReference = React.useRef(
        Keycloak({
            clientId: getProcessEnv().keycloak_client,
            realm: getProcessEnv().keycloak_realm,
            url: getProcessEnv().keycloak_auth_url
        })
    )
    const block = React.useRef(false)

    React.useEffect(() => {
        keycloakReference.current
            .init({ onLoad: "check-sso" })
            .then(authenticated => {
                if (authenticated)
                    keycloakReference.current.loadUserInfo().then(userInfo => {
                        const userData = validateKeycloakUser(userInfo)
                        if (userData.type === "Ok") {
                            console.warn('userData',userData.value);
                            p._setAuth({
                                type: "Authenticated",
                                value: { ...userData.value,type:'ADMIN', token: keycloakReference.current.token ?? "" }
                            })
                            p.fetchUserData()
                            p.fetchAppChangelog()
                        } else p._setAuth(mkErr(userData.value.toString()))
                    })
                else {
                    p._setAuth({ type: "NotAuthenticated" })
                    if (!matchesPath(contractorPaths.contractorOffer.path, window.location.pathname, true))
                        keycloakReference.current.login({
                            scope: "offline_access"
                        })
                }
            })
            .catch(e => {
                // eslint-disable-next-line no-console
                console.error(e)
                p._setAuth(mkErr("No connection to identity provider"))
            })
    }, [])

    React.useEffect(() => {
        if (!block.current && p.auth.type === "Authenticated") {
            const userData = p.auth.value
            keycloakReference.current.token = userData.token
            if ((keycloakReference.current.tokenParsed?.exp ?? 0) * 1000 < new Date().getTime() + 4999) {
                // eslint-disable-next-line no-console
                console.log("blocking & updating token")
                block.current = true

                keycloakReference.current
                    .updateToken(5000)
                    .then(updated => {
                        if (updated)
                            p._setAuth({
                                type: "Authenticated",
                                value: { ...userData, token: keycloakReference.current.token ?? "" }
                            })
                        else
                            keycloakReference.current.login({
                                scope: "offline_access"
                            })
                    })
                    .catch(e => {
                        // eslint-disable-next-line no-console
                        console.error("err", e)
                        setTimeout(() => location.reload(), 1000)
                    })
                    .finally(() => {
                        block.current = false
                    })
            }
        }
    })
    return (
        <AuthContext.Provider
            value={{
                manageCredentials: () => {
                    keycloakReference.current
                        .accountManagement()
                        .then(keycloakReference.current.loadUserInfo)
                        .then(userInfo => {
                            const userData = validateKeycloakUser(userInfo)
                            if (userData.type !== "Ok") return p._setAuth(mkErr("Authentication error"))
                            else if (p.auth.type === "Authenticated" && p.auth.value.email !== userData.value.email) {
                                p._setAuth({
                                    type: "Authenticated",
                                    value: { ...userData.value, token: keycloakReference.current.token ?? "" }
                                })
                                if (p.asyncUser.type === "Fetched")
                                    p.updateUser({
                                        ...p.asyncUser.value,
                                        email: userData.value.email,
                                        actionId: genTemporaryId()
                                    })
                            }
                        })
                },
                register: ({ email }) => {
                  //important those localStorage values are used on keycloak-template!
                  var scope = "offline_access";
                  if(matchesPath(contractorPaths.contractorOffer.path, window.location.pathname, true)) {
                    localStorage.setItem("sd-role", "CONTRACTOR" );
                    scope = "offline_access contractor";
                  }
                  keycloakReference.current.register({
                    loginHint: email,
                    scope: scope
                  });
                },
                logout: () =>
                    keycloakReference.current.logout({ redirectUri: window.location.origin }).then(() => {
                        p._flushState()
                        p._setAuth({ type: "NotAuthenticated" })
                    }),
                getAdminUrl: () =>
                    `${getProcessEnv().keycloak_auth_url}/admin/${getProcessEnv().keycloak_realm}/console/#/realms/${
                        getProcessEnv().keycloak_realm
                    }/users`
            }}>
            {matchPattern(p.auth.type)({
                NotAuthenticated: () => {
                    if (matchesPath(contractorPaths.contractorOffer.path, window.location.pathname, true))
                        return p.children
                    return <Spinner fullscreen />
                },
                Processing: () => <Spinner fullscreen />,
                Err: () => (
                    <AppLayout>
                        <ErrorView text={i18n("Authentication failed, please try again")} />
                    </AppLayout>
                ),
                Authenticated: () => {
                    switch (p.asyncUser.type) {
                        case "Fetched":
                            return <>{p.children}</>
                        case "FetchError":
                            if (p.asyncUser.value === DA_MESSAGES.USER_NOT_FOUND)
                                return (
                                    <EmptyContent>
                                        <RegisterView />
                                    </EmptyContent>
                                )
                            else return <ErrorView type="total" />
                        default:
                            return <Spinner fullscreen />
                    }
                }
            })}
        </AuthContext.Provider>
    )
})
