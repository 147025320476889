import {
    resolveFromMap,
    mkResolverWithProps,
    mkResolver,
    combineResolversWithProps,
    resolvePathParam
} from "./resolverUtils"
import { clientActions } from "../store"
import { CatalogRef } from "@smartdevis/server/src/domain"
import { mkVersion, ShelfType, validShelfType, Version } from "@smartdevis/server/src/domainCatalog"

import { getParamsFromPathname, PathParams } from "../paths"
import { RouterState } from "connected-react-router"

import { mkFetched, mkFetchError, mkFetching, isNotFetched } from "@smartdevis/utils/src/async"
import { pickObject, getFromBrandedMap } from "@smartdevis/utils/src/map"
import { isOk } from "@smartdevis/utils/src/result"
import { runValidatorsRaw } from "@smartdevis/utils/src/validators"
import { IdLite } from "@smartdevis/utils/src/id"
import { getSpecificCatalogVersion, getSpecificShelfVersion, stateForShelfVersion } from "../store/catalogState"
import { defaultizeAsyncShelf } from "../services/catalog"

type ShelfProps = Partial<Pick<PathParams, "shelfVersion" | "shelfType">>

export const resolveShelfTypeFromUrl = mkResolverWithProps<ShelfProps>()((s, op) => {
    const value = op.shelfType || getParamsFromPathname((s.router as RouterState).location.pathname).shelfType || ""

    const validated = runValidatorsRaw(validShelfType, value)
    return isOk(validated) ? mkFetched(validated.value) : mkFetchError(`Invalid shelf type`)
})

export const resolveCatalogWorkTitle = mkResolverWithProps<{
    catalogRef: CatalogRef
    shelfVersion: Version
    shelfType: ShelfType
}>()(
    (s, op) => {
        if (!op.catalogRef) return mkFetchError("Incorrect reference")
        const versionWorkTitles = stateForShelfVersion(s.catalog.workTitles, op.shelfVersion)
        return resolveFromMap(versionWorkTitles, op.catalogRef.workTitleKey, mkFetching)
    },
    (s, op) => {
        const versionWorkTitles = stateForShelfVersion(s.catalog.workTitles, op.shelfVersion)
        return op.catalogRef && !versionWorkTitles[op.catalogRef.workTitleKey]
            ? [
                  clientActions.fetchWorkTitle({
                      shelfVersion: op.shelfVersion,
                      shelfType: op.shelfType,
                      ...pickObject(op.catalogRef, ["catalogId", "workTitleKey"])
                  })
              ]
            : []
    }
)

export const resolveCatalog = mkResolverWithProps<{ catalogId: IdLite; shelfVersion: Version; shelfType: ShelfType }>()(
    (s, op) => resolveFromMap(stateForShelfVersion(s.catalog.liteCatalogs, op.shelfVersion), op.catalogId, mkFetching),
    (s, op) =>
        isNotFetched(getFromBrandedMap(stateForShelfVersion(s.catalog.liteCatalogs, op.shelfVersion), op.catalogId))
            ? [
                  clientActions.fetchCatalog({
                      type: "catalogShelf",
                      ...pickObject(op, ["catalogId", "shelfVersion", "shelfType"])
                  })
              ]
            : []
)

export const resolveLatestShelf = combineResolversWithProps<ShelfProps>()(resolveShelfTypeFromUrl, shelfType =>
    mkResolver(
        s =>
            defaultizeAsyncShelf(
                shelfType === "conditions" ? s.catalog.latestConditionsShelf : s.catalog.latestPositionsShelf
            ),
        s => {
            const shelf = shelfType === "conditions" ? s.catalog.latestConditionsShelf : s.catalog.latestPositionsShelf
            return isNotFetched(shelf) ? [clientActions.fetchShelf({ shelfType })] : []
        }
    )
)

export const resolveLatestPositionsShelf = mkResolver(
    s => defaultizeAsyncShelf(s.catalog.latestPositionsShelf),
    s => (isNotFetched(s.catalog.latestPositionsShelf) ? [clientActions.fetchShelf({ shelfType: "positions" })] : [])
)

export const resolveLatestConditionsShelf = mkResolver(
    s => defaultizeAsyncShelf(s.catalog.latestConditionsShelf),
    s => (isNotFetched(s.catalog.latestConditionsShelf) ? [clientActions.fetchShelf({ shelfType: "conditions" })] : [])
)

type CatalogProps = Partial<Pick<PathParams, "catalogId" | "shelfVersion" | "shelfType">>

export const resolveCatalogFull = combineResolversWithProps<CatalogProps>()(
    resolvePathParam("catalogId"),
    resolvePathParam("shelfVersion"),
    resolveShelfTypeFromUrl,
    (catalogId, shelfVersion, shelfType) => {
        return mkResolver(
            s => getSpecificCatalogVersion(s.catalog, catalogId as IdLite, mkVersion(parseInt(shelfVersion))),
            s =>
                isNotFetched(
                    getSpecificCatalogVersion(s.catalog, catalogId as IdLite, mkVersion(parseInt(shelfVersion)))
                )
                    ? [
                          clientActions.fetchFullCatalog({
                              type: "catalogShelf",
                              shelfType,
                              shelfVersion: mkVersion(parseInt(shelfVersion)),
                              catalogId: catalogId as IdLite
                          })
                      ]
                    : []
        )
    }
)

export const resolveCatalogViewMeta = combineResolversWithProps<CatalogProps>()(
    resolvePathParam("catalogId"),
    resolvePathParam("shelfVersion"),
    (catalogId, shelfVersion) =>
        mkResolver(() => mkFetched({ catalogId: catalogId as IdLite, shelfVersion: mkVersion(parseInt(shelfVersion)) }))
)

export const resolveCurrentShelf = combineResolversWithProps<ShelfProps>()(
    resolvePathParam("shelfVersion"),
    resolveShelfTypeFromUrl,
    (shelfVersionParam, shelfType) => {
        const shelfVersion = mkVersion(parseInt(shelfVersionParam))
        return mkResolverWithProps<ShelfProps>()(
            (s, op) => {
                const latestShelf =
                    op.shelfType === "conditions" ? s.catalog.latestConditionsShelf : s.catalog.latestPositionsShelf
                if (latestShelf.type === "Fetched" && latestShelf.value.meta.version === shelfVersion)
                    return latestShelf
                return getSpecificShelfVersion(s.catalog, shelfType, shelfVersion)
            },
            s =>
                isNotFetched(getSpecificShelfVersion(s.catalog, shelfType, shelfVersion))
                    ? [clientActions.fetchShelf({ shelfType, shelfVersion })]
                    : []
        )
    }
)
