import * as React from "react"
import { materializePath, projectPaths } from "../../paths"
import { ArchitectContent } from "../../components/layouts/Content"
import { i18n } from "@smartdevis/client/src/services/translations"
import {
    ProjectDeleteModal,
    ProjectDuplicateConfirmModal,
    ProjectTemplateModal,
    useProjectActions
} from "./ProjectsModals"
import { asyncConnect } from "../../resolvers"
import { useCloudAction } from "../../hooks/useCloudAction"
import { mkDropdownOption, mkSubmenuDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { mkTile, TileOption, Tiles } from "@smartdevis/ui/src/Tiles"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { sortCreatable } from "@smartdevis/utils/src/comparable"
import { values } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { Flex, FlexRow, HorizontalSpace, LeftCol, Margin, RightCol, Spacer } from "@smartdevis/ui/src/utils/common"
import { H2 } from "@smartdevis/ui/src/Typography"
import styled, { css } from "styled-components"
import { themeColor, themeMedia } from "@smartdevis/ui/src/utils/theme"
import { Button } from "@smartdevis/ui/src/Button"
import { RecentTenders } from "../../components/RecentTenders"
import { Domain } from "@smartdevis/server/src/domain"

export const localStorageProjectId : string = 'temp01';
export const LinkButton = styled(Button)<{ isActive?: boolean }>`
    padding: 0;
    margin: 0;
    height: auto;
    font-size: 14px;
    color: ${({ isActive }) => themeColor(isActive ? "action" : "black")};
    :hover {
        color: ${themeColor("action")};
    }
`

export const DashboardView = asyncConnect({
    stateResolvers: [
        "projectsDetails",
        "results",
        "projectTemplatesDetails",
        "allDevis",
        "devisTemplates",
        "contractors",
        "tempProjectsDetails",
        "user"
    ],
    actions: ["navigate", "duplicateProject", "saveAsTemplate", "mutate"]
})(p => {
    const [tempProjectDetails, setTempProjectDetails] = React.useState<Domain.ProjectDetails| null>(null);


    const { state, setState, setDelta, newId, systemTemplates, isSystemTemplate } = useProjectActions()
    const templates = values(p.projectTemplatesDetails)
    const { remove } = prepareMutation("projects", p.mutate)
    const onEdit = (pr: Domain.ProjectDetails) =>
        pr && p.navigate(projectPaths.editProject, { projectId: pr.projectId })

    const { onSubmit: duplicateProject, actionState: duplicateActionState } = useCloudAction<string, string>(
        (actionId, name, projectId) =>
            p.duplicateProject({
                actionId,
                name,
                projectId,
                newProjectId: newId,
                systemTemplate: systemTemplates.findIndex(v => v.projectId === projectId) !== -1
            }),
        p.results,
        () => setState("duplicate")(false)
    )

    const { onSubmit: saveAsTemplate } = useCloudAction<{ name: string; projectId: string; clearValues: boolean }>(
        (actionId, { name, projectId, clearValues }) =>
            p.saveAsTemplate({ actionId, name, projectId, newProjectId: genTemporaryId(), clearValues }),
        p.results
    )

    const displayFields = (...fields: (string | undefined)[]) => fields.filter(isDefined).join(" ")
    const projectsTiles = values(p.projectsDetails)
        .sort(sortCreatable("desc"))
        .map(ds => {
            return mkTile(ds.projectId, ds.name, {
                description: `${displayFields(
                    `${ds.street} ${ds.streetNumber}, ${ds.postalCode}`,
                    ds.city
                )}\n${displayFields(ds.number, ds.abbreviation)}`,
                status: {
                    type: "text",
                    value: i18n("$1 devis", values(p.allDevis).filter(d => d.projectId === ds.projectId).length)
                },
                linkTo: materializePath("overview", { projectId: ds.projectId }),
                options: [
                    mkDropdownOption(i18n("Edit"), "edit", () => onEdit(ds)),
                    mkDropdownOption(i18n("Duplicate"), "duplicate", () => setDelta({ project: ds, duplicate: true })),
                    mkDropdownOption(i18n("Save as template"), "template", () =>
                        setDelta({ project: ds, template: true })
                    ),
                    mkDropdownOption(i18n("Remove"), "remove", () => setDelta({ project: ds, delete: true }))
                ]
            })
        })

    const templatesTiles = templates.sort(sortCreatable("desc")).map(ds =>
        mkTile(ds.projectId, ds.name, {
            description: `${displayFields(
                `${ds.street} ${ds.streetNumber}, ${ds.postalCode}`,
                ds.city
            )}\n${displayFields(ds.number, ds.abbreviation)}`,
            status: { type: "text", value: i18n("Template") },
            linkTo: materializePath("overview", { projectId: ds.projectId }),
            options: [
                mkDropdownOption(i18n("Edit"), "edit", () => onEdit(ds)),
                mkDropdownOption(i18n("Remove"), "remove", () => setDelta({ project: ds, delete: true }))
            ]
        })
    )

    type AvailableTabs = "projects" | "templates"
    const [currentTab, setCurrentTab] = React.useState<AvailableTabs>("projects")


    const [optionList,setOptionList] = React.useState<TileOption[]>([
        mkDropdownOption(i18n("Create new"), "new", () => p.navigate(projectPaths.newProject)),
        mkSubmenuDropdownOption(
            i18n("Create from template"),
            "system template",
            systemTemplates
                .filter(isDefined)
                .map(t => mkDropdownOption(t.name || "", t.projectId, () => setDelta({ project: t, duplicate: true }))),
            { disabled: !systemTemplates.filter(isDefined).length }
        ),
        mkSubmenuDropdownOption(
            i18n("Create from custom template"),
            "template",
            templates
                .filter(isDefined)
                .map(t => mkDropdownOption(t.name || "", t.projectId, () => setDelta({ project: t, duplicate: true }))),
            { disabled: !templates.filter(isDefined).length }
        )
    ])

    React.useEffect(() => {
        //localStorage.setItem("581", JSON.stringify(localStrogeProjectDetailsObj))
        if (tempProjectDetails === null) {
                const userId = p.user.userId
                for (let key in p.tempProjectsDetails) {
                    if (Object.prototype.hasOwnProperty.call(p.tempProjectsDetails, key) && key === userId) {
                        const tempProjectDetailsString = p.tempProjectsDetails[key] as unknown as string;
                        const tempProjectDetailsObject = JSON.parse(tempProjectDetailsString) as Domain.ProjectDetails;
                        setTempProjectDetails(tempProjectDetailsObject);
                    }
                }
            
        } else {

            setOptionList(prevOptions => [
                ...prevOptions,
                mkDropdownOption(i18n(`Continue filling Project form from ${new Date(tempProjectDetails.createdTs).toLocaleDateString()}`),"edit",() => onEdit(tempProjectDetails))
            ])
        }
    }, [tempProjectDetails])

    return (
        <>
            <ArchitectContent>
                <Margin values="24px 0 0 0">
                    <Flex>
                        <StyledLeftCol>
                            <Margin values="0 0 4px 0">
                                <FlexRow alignCenter spaceBetween>
                                    <H2>{i18n(currentTab === "projects" ? "Projects" : "Templates")}</H2>
                                    <FlexRow>
                                        <LinkButton
                                            btnType="link"
                                            onClick={() => setCurrentTab("projects")}
                                            isActive={currentTab === "projects"}>
                                            {i18n("All Projects")}
                                        </LinkButton>
                                        <HorizontalSpace base="16px" />
                                        <Spacer color="grey50" type="vertical" />
                                        <HorizontalSpace base="16px" />
                                        <LinkButton
                                            btnType="link"
                                            onClick={() => setCurrentTab("templates")}
                                            isActive={currentTab === "templates"}>
                                            {i18n("Templates")}
                                        </LinkButton>
                                    </FlexRow>
                                </FlexRow>
                            </Margin>
                            <Tiles
                                createProps={
                                    currentTab === "projects"
                                        ? {
                                              withDropdown: true,
                                              title: i18n("Create"),
                                              loading: duplicateActionState.type === "Processing",
                                              options: optionList
                                          }
                                        : {
                                              withDropdown: false,
                                              title: i18n("Create template"),
                                              loading: duplicateActionState.type === "Processing",
                                              onClick: () => p.navigate(projectPaths.newTemplate)
                                          }
                                }
                                items={currentTab === "projects" ? projectsTiles : templatesTiles}
                            />
                        </StyledLeftCol>
                        <StyledRightCol>
                            <RecentTenders />
                        </StyledRightCol>
                    </Flex>
                </Margin>
            </ArchitectContent>
            <ProjectDuplicateConfirmModal
                show={state.duplicate}
                setShow={setState("duplicate")}
                onConfirm={() => duplicateProject(state.projectName, state.project?.projectId || "")}
                name={state.projectName}
                setName={setState("projectName")}
                processing={duplicateActionState.type === "Processing"}
                systemTemplate={isSystemTemplate}
            />
            <ProjectTemplateModal
                show={state.template}
                setShow={setState("template")}
                onConfirm={() =>
                    saveAsTemplate({
                        name: state.projectName,
                        projectId: state.project?.projectId || "",
                        clearValues: state.clearValuesOption
                    })
                }
                name={state.projectName}
                setName={setState("projectName")}
                check={{ checked: state.clearValuesOption, setChecked: setState("clearValuesOption") }}
            />
            <ProjectDeleteModal
                isTemplate={state.project?.isTemplate}
                setShow={setState("delete")}
                onConfirm={remove(state.project as any)}
                show={state.delete}
            />
        </>
    )
})

const columnStyles = css`
    overflow: auto;
    height: auto;
    margin-top: 0;
`

const StyledLeftCol = styled(LeftCol)`
    ${columnStyles}
    flex: 1.5;
    ${themeMedia("min", "xl")} {
        flex: 3;
    }
`

const StyledRightCol = styled(RightCol)`
    ${columnStyles}
    flex: 2
`
