import * as React from "react"
import { F1 } from "@smartdevis/utils/src/types"
import styled from "styled-components"

import { Button } from "@smartdevis/ui/src/Button"

const InputFile = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`
const Label = styled.label<{ active: boolean }>`
    cursor: pointer;
    line-height: 38px;
    display: inline-block;
    padding: 0 20px;
    border: 1px solid ${p => (p.active ? "error" : "transparent")};
`

const FileButtonWrapper = styled(Button)`
    padding: 0;
`

type Props = {
    name?: string
    onChange: F1<File>
    accept: string
    typeMatch?: RegExp
}

export const FileButton: React.FC<Props> = p => {
    const [active, setActive] = React.useState(false)
    const [file, setFile] = React.useState<File | null>(null)
    const onChange = (e: any) => {
        if (!e.dataTransfer && !e.target) return
        setActive(true)
        const { files } = e.dataTransfer ? e.dataTransfer : e.target
        setFile(files[0])
        p.onChange(files[0])
        setActive(false)
    }
    const name = p.name || "file"
    return (
        <FileButtonWrapper>
            <InputFile name={name} id={name} type="file" accept={p.accept} onChange={onChange} />
            <Label
                active={active}
                htmlFor={name}
                onDragEnter={() => setActive(true)}
                onDragLeave={() => setActive(false)}
                onDragOver={e => e.preventDefault()}
                onDrop={e => {
                    e.preventDefault()
                    onChange(e)
                }}>
                {p.children}
            </Label>
            {file ? <p>{file.name}</p> : null}
        </FileButtonWrapper>
    )
}
