import { EventResult } from "./actions"
import _isEmpty from "lodash/isEmpty"
import { iterateMap, keys, copyDefinedFields, SMap, filterObject } from "./map"
import { fromSafeNumber } from "./numbers"
import { Err, Errors, ErrType, ExtErrors, isErr, isOk, mkErr, mkOk, OkType, Result } from "./result"
import { format } from "./text"
import { ValueState, State, F1, StateType, StateValue } from "./types"

export type Validator<T, E = ExtErrors<T>> = (o: any, msg?: string) => Result<T, E>
export type Validators<T = any, T2 = ExtErrors<T>> = Array<Validator<T, T2>> | null
export type ValidationMap<T, T2 = any> = { [key in keyof T]: Validators<T[key], T2> }
export type ValidatedCollection<T, E = ExtErrors<T>> = { valid: SMap<T>; invalid: SMap<Err<E>> }
export type IsType = (v: any, msg?: string) => null | string
export type Value<T> = T extends ValueState<string, infer T2> ? T2 : never
export type Type<T> = T extends State<infer T2> ? T2 : never
export type Constructor<T> = (payload: Value<T>) => T

export const isString = (v: any): v is string => typeof v === "string"
// eslint-disable-next-line @typescript-eslint/ban-types
export const isObject = (v: any): v is Object => typeof v === "object"
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (f: any): f is Function => "function" === typeof f
export const isArray = <T>(ts: T[] | any): ts is T[] => ts && typeof ts === "object" && ts.constructor.name === "Array"
export const isValid = (pred: boolean | (() => boolean)) => (isFunction(pred) ? pred() : pred)
export const isNumber = (v: any): v is number => typeof v === "number"
export const isBoolean = (v: any): v is boolean => typeof v === "boolean"
export const isMemberOf =
    <T>(vs: T[] = []) =>
    (v: any): v is T =>
        vs.includes(v)

export const isEmpty = (v: any) => v === undefined || v === null || v === "" || (typeof v === "object" && _isEmpty(v))

export const isEmail = (email: any): email is string => isString(email) && /^.+@.+\..+$/.test(email)

export const errors = {
    notFetched: "Not fetched",
    notFound: "Not found",
    notStringType: "Not a string type",
    notObjectType: "Not an object type",
    notNumberType: "Not a number type",
    notArrayType: "Not an array type",
    notBooleanType: "Not an boolean type",
    cannotBeEmpty: "Cannot be empty",
    shouldBeEmpty: "Should be empty",
    notAllowed: "Value not allowed",
    notValidDecimal: "Invalid decimal number",
    invalidDocument: "Invalid document",
    invalidObject: "Invalid object",
    duplicated: "Duplicated",
    notValidEmail: "Not a valid email",
    minLenString: "Value is too short",
    maxLenString: "Value is too long",
    notMatchingValues: "Values are not the same",
    noUppercasePresent: "No uppercase character present",
    noLowercasePresent: "No lowercase character present",
    noDigitPresent: "No digit character present",
    digitPresent: "Digit character present",
    notHexColor: "Invalid hex color",
    notEncryptionKey: "Invalid encryption key type",
    invalidLength: "Invalid length"
}
const parametrizedErrors = {
    notBigEnough: "Value must be equal or greater than $1",
    notLowEnough: "Value must be equal or less than $1"
}
export const parametrizeError = (key: keyof typeof parametrizedErrors, ...args: any[]) =>
    format(parametrizedErrors[key], ...args)

export const validateString = (v: any, msg?: string): Result<string, string> =>
    isString(v) ? mkOk(v) : mkErr(msg || errors.notStringType, v)

export const validateAZ = (v: any, msg?: string): Result<string, string> =>
    /^[a-zA-Z-_]*$/.test(v) ? mkOk(v) : mkErr(msg || errors.notAllowed, v)

export const validateNoSpace: Validator<string, string> = (v, msg) =>
    `${v}`.includes(" ") ? mkErr(msg || errors.notValidEmail) : mkOk(v)

export const validateUppercasePresent = (v: any, msg?: string): Result<string, string> =>
    /^(?=.*[A-Z]).*/.test(v) ? mkOk(v) : mkErr(msg || errors.noUppercasePresent)

export const validateLowercasePresent = (v: any, msg?: string): Result<string, string> =>
    /^(?=.*[a-z]).*/.test(v) ? mkOk(v) : mkErr(msg || errors.noLowercasePresent)

export const validateNumberPresent = (v: any, msg?: string): Result<string, string> =>
    /^(?=.*\d).*/.test(v) ? mkOk(v) : mkErr(msg || errors.noDigitPresent)

export const validateNumberNotPresent = (v: any, msg?: string): Result<string, string> =>
    /^(?=.*\d).*/.test(v) ? mkErr(msg || errors.digitPresent) : mkOk(v)

export const validateMinLength =
    (l: number) =>
    (v: string, msg?: string): Result<string, string> =>
        v.length >= l ? mkOk(v) : mkErr(msg || errors.minLenString + ` (${l})`, v)

export const validateMaxLength =
    (l: number) =>
    (v: string | null, msg?: string): Result<any, string> =>
        v === null || v.length <= l ? mkOk(v) : mkErr(msg || errors.maxLenString + ` (${l})`, v)

export const validateNull: Validator<null> = (v, msg) => (v === null ? mkOk(null) : mkErr(msg || errors.notAllowed, v))

export const validateEmpty = (v: any, msg?: string): Result<any, string> =>
    isEmpty(v) ? mkOk(v) : mkErr(msg || errors.shouldBeEmpty, v)

export const validateNotEmpty = <T>(v: T, msg?: string): Result<T, string> =>
    !isEmpty(v) ? mkOk(v) : mkErr(msg || errors.cannotBeEmpty, v)

export const validateMemberOf =
    <T>(vs: T[]) =>
    (v: any, msg?: string): Result<any, string> =>
        vs.includes(v) ? mkOk(v) : mkErr(msg || errors.notAllowed, v)

export const validateNotMemberOf =
    (vs: string[], msg: string): Validator<string> =>
    v =>
        vs.includes(v) ? mkErr(msg || errors.notAllowed) : mkOk(v)

export const validateNumber = (v: any, msg?: string): Result<number, string> =>
    isNumber(v) ? mkOk(v) : mkErr(msg || errors.notNumberType, v)

export const validateEmail = (v: any, msg?: string): Result<string, string> =>
    isEmail(v) ? mkOk(v) : mkErr(msg || errors.notValidEmail, v)

export const validateBoolean = (v: any, msg?: string): Result<boolean, string> =>
    isBoolean(v) ? mkOk(v) : mkErr(msg || errors.notBooleanType, v)

export const validateArrayLength =
    (l: number) =>
    (v: any, msg?: string): Result<any[], string> => {
        if (!isArray(v) || !v) return mkErr(msg || errors.notArrayType, v)
        return v.length === l ? mkOk(v) : mkErr(msg || errors.invalidLength + ` (${l})`, v)
    }

export const validateHexColor = (v: any, msg?: string): Result<boolean, string> =>
    /^(#[0-9a-f]{3}|#[0-9a-f]{6})$/i.test(v) ? mkOk(v) : mkErr(msg || errors.notHexColor, v)

// const idRegExp = new RegExp("^[0-9]{10}[0-9A-Za-z]{22}", "g")
// export const validateId: Validator<string, string> = (v, msg) =>
//     `${v}`.match(idRegExp) !== null ? mkOk(v) : mkErr(msg || "Invalid id", v)

const taxIdRegExp = new RegExp("^CHE-[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}$", "g")
export const validateTaxId: Validator<string, string> = (v, msg) =>
    `${v}`.match(taxIdRegExp) !== null ? mkOk(v) : mkErr(msg || "Invalid Tax ID - CHE-123.123.123 format required", v)

export const runValidatorsRaw = <T = any, T2 = ExtErrors<T>>(
    validators: Validators<T, T2>,
    value: any
): Result<T, T2> =>
    (validators || []).reduce(
        (acc, validator) => (acc.type === "Err" ? acc : validator(acc.value)),
        mkOk(value) as Result<T, T2>
    )
/**
 * This should not exists. Its a workaround for current none-auth state situation. WIP
 * @param validators 
 * @param value 
 * @returns 
 */
export const runValidatorsRaw1 = <T = any, T2 = ExtErrors<T>>(
        validators: Validators<T, T2>,
        value: any
    ): Result<T, T2> =>
        (validators || []).reduce(
            (acc, validator) => (acc.type !== "Err" ? acc : validator(acc.value)),
            mkOk(value) as Result<T, T2>
)

export const runValidators = <T = any>(validators: Validators<T>, value: any, cb: (err: string) => void) => {
    const res = runValidatorsRaw<T>(validators, value)
    if (res.type === "Err") {
        cb(res.value as string)
        return value
    }
    return res.value
}

export const mkValidator =
    <T>(
        validationMap: ValidationMap<Required<T>>,
        constructor?: F1<any, T>,
        delta?: (o: T) => Partial<T>
    ): Validator<T> =>
    (o: any) => {
        if (!o) return mkErr(errors.invalidObject, o)
        const maybe: T = o
        const errorsMap: Errors<T> = {}
        keys(validationMap).forEach(
            f => (maybe[f] = runValidators(validationMap[f], maybe[f], err => (errorsMap[f] = err)))
        )
        const defaultConstractor = () => copyDefinedFields(validationMap, maybe, delta ? delta(maybe) : {})
        return Object.keys(errorsMap).length ? mkErr(errorsMap, o) : mkOk((constructor || defaultConstractor)(maybe))
    }

export const mkOrValidator =
    <T>(validators: Array<Validator<T, any>>): Validator<T, any> =>
    (v, msg) => {
        let res: Result<T> = mkErr("No Data")
        validators.forEach(validator => {
            if (res.type === "Ok") return
            res = validator(typeof v === "object" ? JSON.parse(JSON.stringify(v)) : v, msg)
        })
        return res
    }

export function mkMapValidator<T>(vmap: ValidationMap<T>): Validator<T, any> {
    return v => mkValidator(vmap as any)(v) as any
}

export const mkPartialMapValidator =
    <T>(vmap: ValidationMap<Required<T>>): Validator<Partial<T>> =>
    (o: any) => {
        if (!o) return mkErr(errors.invalidObject, o)
        const maybe: T = o
        const result: Partial<T> = {}
        const errorsMap: Errors<T> = {}
        keys(maybe).forEach(f => {
            if (vmap[f] !== undefined) result[f] = runValidators(vmap[f], maybe[f], err => (errorsMap[f] = err))
        })
        return keys(errorsMap).length ? mkErr(errorsMap, o) : mkOk(result)
    }

export function mkValueStateValidator<T extends ValueState<any, any>>(
    values: StateType<T>[],
    vmap: ValidationMap<StateValue<T>>
): Validator<T> {
    return mkMapValidator<T>({
        type: [validateMemberOf<StateType<T>>(values)],
        value: [mkMapValidator<StateValue<T>>(vmap)]
    } as ValidationMap<T>)
}

export const validateMap =
    <T>(validators: Validators<T>) =>
    (v: any, msg?: string): Result<SMap<T>> => {
        if (!isObject(v) || !v) return mkErr(msg || errors.notObjectType, v)
        const err: Errors<SMap<T>> = {}
        iterateMap(v, (k, kv) => runValidators(validators, kv, resultError => (err[k] = resultError)))
        return isEmpty(err) ? mkOk(v) : mkErr(err, v)
    }

export const filterMapByValidation =
    <T>(validators: Validators<T>, onInvalid?: F1<T>) =>
    (v: any, msg?: string): Result<SMap<T>> => {
        if (!isObject(v) || !v) return mkErr(msg || errors.notObjectType, v)
        return mkOk(
            filterObject(v as SMap<T>, (_, value) => {
                if (isOk(runValidatorsRaw(validators, value))) return true
                onInvalid?.(value)
                return false
            })
        )
    }

export const validateArray =
    <T>(validators: Validators<T>) =>
    (v: any, msg?: string): Result<T[], string> => {
        if (!isArray(v) || !v) return mkErr(msg || errors.notArrayType, v)
        const errMessages: string[] = []
        const maybe: T[] = v as T[]
        maybe.forEach((kv, i) =>
            runValidators(validators, kv, e => (errMessages[i] = `${kv} on index ${i} with error: ${e}`))
        )
        return errMessages.length ? mkErr(errMessages.join("; ")) : mkOk(maybe)
    }

export const optional =
    <T>(defaultValue: T) =>
    (validator: Validator<T, any>): Validator<T, string> =>
    (v: any, msg?: string) => {
        if (isEmpty(v)) return mkOk(defaultValue)
        const result = validator(v, msg)
        return result.type === "Ok" ? mkOk(result.value) : { ...result, value: msg || result.value }
    }

export const optionalIfFalsy =
    <T>(defaultValue: T) =>
    (validator: Validator<T, any>): Validator<T, string> =>
    (v: any, msg?: string) => {
        if (!v) return mkOk(defaultValue)
        const result = validator(v, msg)
        return result.type === "Ok" ? mkOk(result.value) : { ...result, value: msg || result.value }
    }

export const nullable =
    <T>(validator: Validator<T, any>): Validator<T | null, string> =>
    (v: any, msg?: string) => {
        if (isEmpty(v) || (isNumber(v) && isNaN(v))) return mkOk(null)
        const result = validator(v, msg)
        return result.type === "Ok"
            ? mkOk(result.value)
            : mkErr(msg ? msg : result.value || errors.notAllowed, result.obj)
    }

export const ensureString: Validator<string> = (v: any, msg?: string) => {
    return isString(v) ? mkOk(v) : validateString(v.toString(10), msg)
}
export const validateCollection = <T, S = T>(collection: SMap<T>, validator: Validator<S>): ValidatedCollection<S> => {
    const valid: SMap<S> = {}
    const invalid: SMap<Err<ExtErrors<S>>> = {}

    iterateMap(collection, (k, val) => {
        const result = validator(val)
        if (result.type === "Err") invalid[k] = result
        else valid[k] = result.value
    })

    return { valid, invalid }
}

export type ValidateTupleType = {
    <T1>(validators: [Validators<T1>]): (v: any, msg?: string) => Result<[T1]>
    <T1, T2>(validators: [Validators<T1>, Validators<T2>]): (v: any, msg?: string) => Result<[T1, T2]>
    <T1, T2, T3>(validators: [Validators<T1>, Validators<T2>, Validators<T3>]): (
        v: any,
        msg?: string
    ) => Result<[T1, T2, T3]>
    <T1, T2, T3, T4>(validators: [Validators<T1>, Validators<T2>, Validators<T3>, Validators<T4>]): (
        v: any,
        msg?: string
    ) => Result<[T1, T2, T3, T4]>
    <T1, T2, T3, T4, T5>(
        validators: [Validators<T1>, Validators<T2>, Validators<T3>, Validators<T4>, Validators<T5>]
    ): (v: any, msg?: string) => Result<[T1, T2, T3, T4, T5]>
    <T1, T2, T3, T4, T5, T6>(
        validators: [Validators<T1>, Validators<T2>, Validators<T3>, Validators<T4>, Validators<T5>, Validators<T6>]
    ): (v: any, msg?: string) => Result<[T1, T2, T3, T4, T5, T6]>
    <T1, T2, T3, T4, T5, T6, T7>(
        validators: [
            Validators<T1>,
            Validators<T2>,
            Validators<T3>,
            Validators<T4>,
            Validators<T5>,
            Validators<T6>,
            Validators<T7>
        ]
    ): (v: any, msg?: string) => Result<[T1, T2, T3, T4, T5, T6, T7]>
    <T1, T2, T3, T4, T5, T6, T7, T8>(
        validators: [
            Validators<T1>,
            Validators<T2>,
            Validators<T3>,
            Validators<T4>,
            Validators<T5>,
            Validators<T6>,
            Validators<T7>,
            Validators<T8>
        ]
    ): (v: any, msg?: string) => Result<[T1, T2, T3, T4, T5, T6, T7, T8]>
}

export const validateTuple: ValidateTupleType =
    (validators: Validators<any>[]) =>
    (v: any, msg?: string): Result<any> => {
        if (!isArray(v) || !v) return mkErr(msg || errors.notArrayType, v)
        if (validators.length !== v.length) return mkErr(errors.invalidLength, v)
        const errIndices: SMap<string> = {}
        v.forEach((kv, i) => runValidators(validators[i], kv, e => (errIndices[i] = e)))
        return keys(errIndices).length ? mkErr(errIndices, v) : mkOk(v)
    }

export const validNumber = [validateNumber, validateNotEmpty]
export const validString = [validateString, validateNotEmpty]
export const validStringWithoutNumber = [validateNotEmpty, validateString, validateNumberNotPresent]
export const validStringOrNull = mkOrValidator([validateString, validateNull])
export const validEmail = [validateEmail, validateNoSpace]
export const validBoolean = [validateBoolean]
export const validArrayString = [validateArray(validString)]
export const validPassword = [...validString, validateMinLength(6)]
export const validId = [...validString]
export const validIds = [validateArray<string>(validId)]
export const validTaxId = [validateTaxId]

export const optionalId = [optional("")(validateString)]
export const optionalTaxId = [optional("")(validateTaxId)]

export const optionalNumber = [optional(0)(validateNumber)]
export const nullableNumber = [nullable(validateNumber)]

export const optionalString = [optional("")(validateString)]
export const optionalBoolean = [optional(false)(validateBoolean)]
export const optionalEmail = [optional("")(validateEmail)]

export const optionalArray = <T>(validators: Validators<T>) => [optional<T[]>([])(validateArray(validators))]
export const optionalMap = <T>(validators: Validators<T>) => [optional({})(validateMap<T>(validators))]
export const optionalStringArray = optionalArray<string>(validString)
export const optionalIds = optionalArray<string>(validId)

const validateFloat: Validator<number, string> = (v, msg) =>
    /^-?[0-9]\d*(\.\d{1,4})?$/i.test(v) ? mkOk(v) : mkErr(msg || errors.notValidDecimal, v)

const validateInt: Validator<number, string> = (v, msg) =>
    /^[-+]?\d*$/i.test(v) ? mkOk(v) : mkErr(msg || errors.notNumberType, v)

export const validateUnsigned =
    (min: number): Validator<number, string> =>
    (v, msg) =>
        `${v}` !== `${NaN}` && v >= min ? mkOk(v) : mkErr(msg || parametrizeError("notBigEnough", min), v)

export const validateSafeUpTo =
    (max: number): Validator<number, string> =>
    (v, msg) =>
        !isNaN(v) && fromSafeNumber(v) <= max ? mkOk(v) : mkErr(msg || parametrizeError("notLowEnough", max), v)

export const validSafePercent = [validateFloat, validateUnsigned(0), validateSafeUpTo(100)]
export const nullableSafePercent = validSafePercent.map(nullable)

export const validUInt = [validateInt, validateUnsigned(0)]
export const validUFloat = [validateFloat, validateUnsigned(0)]
export const nullableUFloat = validUFloat.map(nullable)

export const validResultType = [validateMemberOf<ErrType | OkType>(["Ok", "Err"])]
export const resultValidator = mkValidator<EventResult>({
    type: validResultType,
    value: null,
    obj: null,
    meta: null
})

export const patternMatchValidator =
    <I extends string, T extends { [k in I]: string }>(
        caseIdentifier: I,
        caseValidators: { [_ in T[I]]: Validator<Omit<T, I>> },
        defaultValidator?: Validator<Omit<T, I>>
    ): Validator<T> =>
    (v: any, msg?: string): Result<T> => {
        if (!v[caseIdentifier])
            return defaultValidator ? (defaultValidator(v, msg) as Result<T>) : mkErr(msg || errors.invalidObject, v)
        return keys(caseValidators).reduce<Result<T>>((acc, key) => {
            if (key === v[caseIdentifier]) {
                const result = (caseValidators[key] as Validator<T>)(v, msg)
                if (isErr(result)) return result
                return mkOk({ ...result.value, [caseIdentifier]: key })
            } else return acc
        }, mkErr<T>({ [caseIdentifier]: msg || errors.notAllowed } as T, v))
    }

export const validCompanyName = [(v: any) => validateNotEmpty(v, "Please enter a Name"), validateString]

export const validStreet = [
    (v: any) => validateNotEmpty(v, "Please enter a Street"),
    validateString,
    //validateNumberNotPresent // as it is interrupting with the street dropdown
]
