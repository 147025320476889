import { Cmd } from "redux-loop"
import { Catalog, CatalogMatch, CWorkTitle, Shelf, ShelfType, Version } from "@smartdevis/server/src/domainCatalog"
import { getCatalog, searchShelf, getFullCatalog, getWorkTitle, getShelf, publishCatalog } from "../services/catalog"
import { mkReducer } from "./utils"
import { extRun } from "../utils/commands"
import { navigateToNextCatalog } from "./catalogCmds"
import { extend, getFromBrandedMap, MapType, pickObject, SMap, TMap } from "@smartdevis/utils/src/map"
import { mkErr, mkOk, Result } from "@smartdevis/utils/src/result"
import { Async, isFetchError, mkFetched, mkFetching, mkNotFetched } from "@smartdevis/utils/src/async"
import { BrandedValue, TypedOmit, ValueOf } from "@smartdevis/utils/src/types"
import { createAction } from "@smartdevis/utils/src/actions"
import { IdLite } from "@smartdevis/utils/src/id"
import { CatalogCloudActionName, CatalogCloudEventPayload, CatalogShelfPayload } from "../../../catalog-service/src/catalogCloudActions"

type CatalogMap = TMap<BrandedValue<IdLite>, Async<Catalog>>
type CatalogVersionMap = TMap<BrandedValue<Version>, CatalogMap>

export type CatalogsState = {
    latestPositionsShelf: Async<Shelf>
    latestConditionsShelf: Async<Shelf>
    matches: Async<SMap<CatalogMatch>>
    workTitles: TMap<Version, SMap<Async<CWorkTitle>>>
    liteCatalogs: CatalogVersionMap
    fullCatalogs: CatalogVersionMap
    positionsShelves: TMap<string, Async<Shelf>>
    conditionsShelves: TMap<string, Async<Shelf>>
    publishResults: SMap<Result<string>>
}

export const initialState: CatalogsState = {
    latestPositionsShelf: mkNotFetched(),
    latestConditionsShelf: mkNotFetched(),
    matches: mkFetched({}),
    workTitles: {},
    liteCatalogs: {},
    fullCatalogs: {},
    positionsShelves: {},
    conditionsShelves: {},
    publishResults: {}
}

export type CatalogApiPayload<T extends CatalogCloudActionName> = CatalogCloudEventPayload<T>

export type PublishCatalogPayload = { blob: Blob; actionId: string } & TypedOmit<
    CatalogApiPayload<"updateCatalog">,
    "blobName"
>
const apiActions = {
    searchInCatalogs: (p: CatalogApiPayload<"shelfQueryText">) => createAction("searchInCatalogs", p),
    fetchWorkTitle: (p: CatalogApiPayload<"workTitle">) => createAction("fetchWorkTitle", p),
    fetchCatalog: (p: CatalogApiPayload<"catalogLite">) => createAction("fetchCatalog", p),
    fetchShelf: (p: CatalogApiPayload<"shelf">) => createAction("fetchShelf", p),
    fetchFullCatalog: (p: CatalogApiPayload<"catalog">) => createAction("fetchFullCatalog", p),
    publishCatalog: (p: PublishCatalogPayload) => createAction("publishCatalog", p)
}

export const actions = {
    _setCatalogsState: (p: Partial<CatalogsState>) => createAction("_setCatalogsState", p),
    _setLiteCatalog: (shelfVersion: Version, catalogId: IdLite, catalog: Async<Catalog>) =>
        createAction("_setCatalog", { catalogId, catalog, shelfVersion }),
    _setFullCatalog: (shelfVersion: Version, catalogId: IdLite, catalog: Async<Catalog>) =>
        createAction("_setFullCatalog", { catalogId, catalog, shelfVersion }),
    _setWorkTitle: (shelfVersion: Version, workTitleId: string, workTitle: Async<CWorkTitle>) =>
        createAction("_setWorkTitle", { shelfVersion, workTitleId, workTitle }),
    _setSpecificShelf: (shelfVersion: Version, shelf: Async<Shelf>, shelfType: ShelfType = "positions") =>
        createAction("_setSpecificShelf", { shelfVersion, shelf, shelfType }),
    _clearLatestShelf: () => createAction("_clearLatestShelf"),
    _addPublishResult: (actionId: string, result: Result<string>) =>
        createAction("_addPublishResult", { actionId, result }),

    resetCatalogMatches: () => createAction("resetCatalogMatches"),

    navigateToNextCatalog: (catalogId: IdLite, shelfVersion: Version, shelfType: ShelfType) =>
        createAction("navigateToNextCatalog", { catalogId, shelfVersion, shelfType }),
    ...apiActions
}

type CatalogAction = ReturnType<typeof actions[keyof typeof actions]>

export const reducer = mkReducer<CatalogsState, CatalogAction>((state, action, _context) => {
    const setWorkTitle = setByShelfVersion(state.workTitles)

    switch (action.type) {
        case "searchInCatalogs":
            return [{ matches: mkFetching() }, getCatalogMatchesCmd(action.payload)]

        case "resetCatalogMatches":
            return pickObject(initialState, ["matches"])

        case "fetchShelf": {
            const { shelfVersion, shelfType = "positions" } = action.payload
            const delta = shelfVersion
                ? setStateForShelves(state.positionsShelves, shelfVersion, mkFetching(), shelfType)
                : shelfType === "positions"
                ? { latestPositionsShelf: mkFetching() }
                : { latestConditionsShelf: mkFetching() }
            return [delta, getShelfCmd(action.payload)]
        }
        case "fetchWorkTitle": {
            const { shelfVersion, workTitleKey } = action.payload
            return [
                { workTitles: setWorkTitle(shelfVersion, workTitleKey, mkFetching()) },
                getWorkTitleCmd(action.payload)
            ]
        }
        case "fetchCatalog": {
            if (action.payload.type === "catalogVersion") return {}
            const { catalogId, shelfVersion } = action.payload
            return [
                setStateForCatalogs("liteCatalogs", state, catalogId, shelfVersion, mkFetching()),
                getCatalogCmd(action.payload)
            ]
        }
        case "fetchFullCatalog": {
            if (action.payload.type === "catalogVersion") return {}
            const { catalogId, shelfVersion } = action.payload
            return [
                setStateForCatalogs("fullCatalogs", state, catalogId, shelfVersion, mkFetching()),
                getFullCatalogCmd(action.payload)
            ]
        }
        case "publishCatalog":
            return publishCatalogCmd(action.payload)

        case "_setCatalog": {
            const { catalogId, shelfVersion, catalog } = action.payload
            return setStateForCatalogs("liteCatalogs", state, catalogId, shelfVersion, catalog)
        }
        case "_setFullCatalog": {
            const { catalogId, shelfVersion, catalog } = action.payload
            return setStateForCatalogs("fullCatalogs", state, catalogId, shelfVersion, catalog)
        }
        case "_setCatalogsState":
            return action.payload
        case "_setWorkTitle": {
            const { shelfVersion, workTitle, workTitleId } = action.payload
            return { workTitles: setWorkTitle(shelfVersion, workTitleId, workTitle) }
        }
        case "_setSpecificShelf": {
            const { shelfVersion, shelf, shelfType } = action.payload
            return setStateForShelves(state.positionsShelves, shelfVersion, shelf, shelfType)
        }
        case "_clearLatestShelf":
            return { latestPositionsShelf: mkNotFetched() }
        case "_addPublishResult": {
            const { actionId, result } = action.payload
            return { publishResults: { ...state.publishResults, [actionId]: result } }
        }
        case "navigateToNextCatalog": {
            const { catalogId, shelfType, shelfVersion } = action.payload
            return navigateToNextCatalog(catalogId, shelfVersion, shelfType)
        }
    }
})

const setByShelfVersion = <S extends CatalogsState["liteCatalogs" | "fullCatalogs" | "workTitles"]>(state: S) => {
    const extState = extend(state)
    return (shelfVersion: Version, key: string, value: ValueOf<MapType<S>>) => {
        const subDelta = { [key]: value } as Partial<MapType<S>>
        const delta = { [shelfVersion]: extend(stateForShelfVersion(state, shelfVersion))(subDelta) }
        return extState(delta as TMap<Version, MapType<S>>)
    }
}
export const stateForShelfVersion = <S extends CatalogsState["liteCatalogs" | "fullCatalogs" | "workTitles"]>(
    state: S,
    shelfVersion: Version
): MapType<S> => (state as TMap<number, any>)[shelfVersion] || {}

export const setStateForShelves = (
    state: CatalogsState["positionsShelves"] | CatalogsState["conditionsShelves"],
    shelfVersion: Version,
    shelf: Async<Shelf>,
    shelfType: ShelfType
) => {
    const extState = extend(state)
    const delta = { [shelfVersion]: shelf }
    return { [shelfType === "positions" ? "positionsShelves" : "conditionsShelves"]: extState(delta) }
}

export const setStateForCatalogs = (
    key: keyof Pick<CatalogsState, "fullCatalogs" | "liteCatalogs">,
    state: CatalogsState,
    catalogId: IdLite,
    shelfVersion: Version,
    catalog: Async<Catalog>
) => {
    const extState = extend(state[key])
    const subDelta = { [catalogId]: catalog }
    const delta = { [shelfVersion]: extend(getFromBrandedMap(state[key], shelfVersion))(subDelta) }
    return { [key]: extState(delta) }
}

export const getSpecificCatalogVersion = (state: CatalogsState, catalogId: IdLite, shelfVersion: Version) => {
    const catalogs = getFromBrandedMap(state.fullCatalogs, shelfVersion)
    if (!catalogs) return mkNotFetched()
    if (!getFromBrandedMap(catalogs, catalogId)) return mkNotFetched()
    return getFromBrandedMap(catalogs, catalogId)
}

export const getSpecificShelfVersion = (state: CatalogsState, shelfType: ShelfType, shelfVersion: Version) => {
    const shelf =
        shelfType === "conditions" ? state.conditionsShelves[shelfVersion] : state.positionsShelves[shelfVersion]
    if (!shelf) return mkNotFetched()
    return shelf
}

export const getCatalogMatchesCmd = (p: CatalogApiPayload<"shelfQueryText">) =>
    Cmd.run(() => searchShelf(p), {
        successActionCreator: matches => actions._setCatalogsState({ matches })
    })

export const getWorkTitleCmd = (p: CatalogApiPayload<"workTitle">) =>
    Cmd.run(() => getWorkTitle(p), {
        successActionCreator: wt => actions._setWorkTitle(p.shelfVersion, p.workTitleKey, wt)
    })

export const getCatalogCmd = (p: CatalogShelfPayload) =>
    Cmd.run(() => getCatalog(p), {
        successActionCreator: c => actions._setLiteCatalog(p.shelfVersion, p.catalogId, c)
    })

export const getFullCatalogCmd = (p: CatalogShelfPayload) =>
    Cmd.run(() => getFullCatalog(p), {
        successActionCreator: c => actions._setFullCatalog(p.shelfVersion, p.catalogId, c)
    })

export const getShelfCmd = (p: CatalogApiPayload<"shelf">) =>
    Cmd.run(() => getShelf(p), {
        successActionCreator: shelf =>
            p.shelfVersion
                ? actions._setSpecificShelf(p.shelfVersion, shelf, p.shelfType)
                : actions._setCatalogsState(
                      p.shelfType === "conditions" ? { latestConditionsShelf: shelf } : { latestPositionsShelf: shelf }
                  )
    })

export const publishCatalogCmd = (p: PublishCatalogPayload) =>
    extRun(
        async () => {
            const actionResult = await publishCatalog(p)
            return isFetchError(actionResult) ? mkErr(actionResult.value, p.catalogId) : mkOk(p.catalogId)
        },
        {
            successActionCreator: result => [
                actions._addPublishResult(p.actionId, result),
                actions.fetchShelf({ shelfType: p.shelfType })
            ],
            failActionCreator: () => []
        }
    )
