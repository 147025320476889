import axios, { AxiosRequestConfig } from "axios"
import { FinishedAsync, mkFetched, mkFetchError } from "@smartdevis/utils/src/async"
import { remap, SMap } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { isErr, isOk } from "@smartdevis/utils/src/result"
import { Validators, runValidatorsRaw, isArray } from "@smartdevis/utils/src/validators"
import { getProcessEnv } from "../utils/envHelpers"
import { materializeWithOptional } from "../utils/router"
import { clientEnv } from "../env"

export const getHttpHeaders = (token?: string, delta: SMap<string> = {}) => {
    const headers: SMap<string> = {
        "Access-Control-Allow-Origin": "*"
    }
    if (token) headers.Authorization = `Bearer ${token}`
    return { ...headers, ...delta }
}

export const makeUrl = (config: Pick<RequestConfig<any>, "basePath" | "path" | "urlParams" | "isV2">) => {
    const materializedPath = materializeWithOptional(config.path, config.urlParams || {})
    const baseUrl = getProcessEnv()[config.basePath]
    return `${baseUrl}/${config.isV2 ? "sd/v2" : "v1"}${materializedPath}`
}

type RequestConfig<T> = {
    basePath: keyof Pick<clientEnv, "api_url" | "catalog_api_url">
    path: string
    method: "GET" | "POST" | "PUT" | "DELETE"
    urlParams?: SMap<string>
    queryParams?: SMap<string | string[] | undefined>
    token?: string
    withCredentials?: boolean
    validators: Validators<T>
    isV2?: boolean
    body?: any
}
export const mkRequest = async <T>(
    config: RequestConfig<T>,
    axiosConfigDelta: AxiosRequestConfig = {}
): Promise<FinishedAsync<T>> => {
    const { headers, ...axiosConfig } = axiosConfigDelta
    try {
        const res = await axios({
            headers: getHttpHeaders(config.token, headers),
            params: remap(config.queryParams || {}, identity, vs => (isArray(vs) ? vs.join(",") : vs)),
            url: makeUrl(config),
            method: config.method,
            withCredentials: config.withCredentials,
            data: config.body,
            ...axiosConfig
        })

        const validatedResult = runValidatorsRaw(config.validators as any, res.data)
        // eslint-disable-next-line no-console
        if (isErr(validatedResult)) console.log(validatedResult)
        return isOk(validatedResult) ? mkFetched(validatedResult.value) : mkFetchError("Invalid data")
    } catch (error: any) {
        return mkFetchError(error?.response?.data?.message || error?.message || "Unknown error")
    }
}
